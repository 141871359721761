import { useState, useEffect } from 'react';
import axios from 'axios';

const FetchLaserProfile = (myUser, isLocal) => {
    const [myLaserProfile, setMyLaserProfile] = useState(null)
    const [laserProfileIsFetching, setlaserProfileIsFetching] = useState(true)
  
    useEffect(() => {
        setlaserProfileIsFetching(true)
        axios({
          method: 'post',
          url: (() => {
            if (isLocal === true) {
              return 'http://localhost:8000/fetchLaserProfile';
            } else {
              return 'api/fetchLaserProfile';
            }
          })(),
          // data: { myUser }
          withCredentials: true
        })
          .then((res) => {
            setMyLaserProfile(res.data);
          })
          .catch((err) => {
            console.error("Error in network request:", err);
          })
          .finally(()=> {
            setlaserProfileIsFetching(false)
          })
      }, []);
    
    return ({ myLaserProfile, laserProfileIsFetching })
}

export default FetchLaserProfile