import { Typography } from 'antd'
import React from 'react'

const { Text } = Typography

const CEHandler = (props) => {
    const { hec, condition, unitToReturn } = props

    const value = hec?.[`condition${condition}`]?.details?.collectedEmission?.[`${unitToReturn}`] || 0
    const pretty = hec?.[`condition${condition}`]?.details?.collectedEmission?.[`${unitToReturn}Pretty`] || '-'
    return (
        <Text
            strong
        >
            {(() => {
                if (value !== null && value !== undefined) {
                    if (value === 0) {
                        return '-'
                    } else {
                        return pretty
                    }
                } else {
                    return '-'
                }
            })()}
        </Text>
    )
}

export default CEHandler