import React from 'react';
import {Typography} from 'antd'
import "./link.css"

function Label() {
    return(
    <>  
    <Typography.Text class="textAnimate">
        <strong>
            <a href="https://www.lasermet.com/laser-safety-products/laser-classification-label/" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
            Buy Laser Classification Labels here!
            </a>
        </strong>
    </Typography.Text>    
    </>
    )
}

function TestingCenter() {
    return (
        <Typography.Text style={{textAlign:"center"}}>
           
            <strong style={{display:"flex"}}> Do you want your laser product to be accredited?  
            </strong>
            <strong>
                <a href="https://www.lasermet.com/laser-safety-services/product-testing-laser-led/" target="_blank" rel="noreferrer"  class="textAnimate" style={{ textDecoration: "none" }}>
                  Visit our accredited testing center! 
                </a>
           </strong>
        </Typography.Text>
    )
}

function Eyewear() {
    return (
        <Typography.Text class="textAnimate" style={{textAlign: "center"}}>
            <strong>
                <a href="https://www.lasermet.com/product-category/laser-protective-eyewear/" target="_blank" rel="noreferrer">
                    Stay protected. Buy your Laser Protective Eyewear here!
                </a>
            </strong>
        </Typography.Text>
    )
}
export { Label, TestingCenter, Eyewear }