// Watermark.js

import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../Logo.png"

const Watermark = ({ text }) => (
  <View style={styles.container}>
    <Text style={styles.watermark}>{text}</Text>
    <Image src={logo}></Image>
  </View>
);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 1,
    width: '80%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.1 , // Adjust opacity for transparency
  },
  watermark: {
    fontSize: 50,
    fontWeight: 'bold',
    color: 'gray',
    transform: 'rotate(-45deg)', // Rotate watermark text
  },
});

export default Watermark;
