import { Col, Flex, Row, Layout, Typography} from 'antd'
import {XOutlined, InstagramOutlined, FacebookOutlined, YoutubeOutlined, LinkedinOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'
import React from 'react'
import { NavLink } from 'react-router-dom'
import "../../pages/home.css"

export function FooterHandler(){
    let year = new Date().getFullYear()
    const { Footer } = Layout
    return(
        <Footer style={{
            backgroundColor: "#1d1f8c",
            height: "auto",
            position: "sticky",
            width: '100%',
            top: "100vh",
            bottom: 0,
            display:"flex",
            justifyContent:"center",
          }}>
         <Row style={{ width: "95%"}}>
          <Col lg={8} md={24} sm={24} xs={24} className='responsive-footer-center' style={{alignContent:"center"}}>
            <Flex vertical>
              <p style={{ color: "#ffcc01"}}>RealTime Laser Safety Software</p>
              <p style={{ color: "white"}}>© {year} Lasermet</p>
            </Flex>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className='responsive-footer-center' style={{display:"flex"}}>
            <Flex vertical justify='center' gap={"large"} style={{ color: "white", fontSize:"1.5rem"}}>
                <Flex horizontal gap={"large"} justify="center" style={{flexWrap:"wrap"}}>
                    <Flex vertical>
                        <Typography className='foot'><PhoneOutlined /> +44 1202 770 740   </Typography>
                        <Typography className='foot'> <MailOutlined /> <a className='foot' href="mailto:office@lasermet.com">office@lasermet.com</a> </Typography>
                    </Flex>
                    <Flex  vertical >
                        <Typography className='foot'>  <PhoneOutlined />+49 211 424 715 588  </Typography>
                        <Typography className='foot'> <MailOutlined /> <a className='foot' href="mailto:europe@lasermet.com">europe@lasermet.com </a></Typography>
                    </Flex>
                    <Flex  vertical >
                        <Typography className='foot'><PhoneOutlined />+1 847 466 1475  </Typography>
                        <Typography className='foot'>  <MailOutlined /> <a className='foot' href="mailto:usa@lasermet.com">usa@lasermet.com </a> </Typography>
                    </Flex>
                </Flex>
                <Flex justify="space-evenly" gap={"large"}>
                    <a href="https://www.linkedin.com/company/lasermetsafetysolutions/mycompany/" target="_blank" rel="noreferrer">
                        <LinkedinOutlined className='foot'/>
                    </a>
                    <a href="https://www.facebook.com/lasermetsafetysolutions" target="_blank" rel="noreferrer">
                        <FacebookOutlined className='foot'/>
                    </a>
                    <a href="https://www.instagram.com/lasermetlimited/" target="_blank" rel="noreferrer">
                        <InstagramOutlined className='foot'/>
                    </a>
                    <a href="https://x.com/lasermetlimited" target="_blank" rel="noreferrer">
                        <XOutlined className='foot'/>
                    </a>
                    <a href="https://www.youtube.com/@Lasermet" target="_blank" rel="noreferrer">
                        <YoutubeOutlined className='foot'/>
                    </a>
                </Flex>
            </Flex>
          </Col>  
          <Col lg={8} md={24} sm={24} xs={24} className='responsive-footer-right' style={{display:"flex"}}>
            <Flex vertical justify='center' style={{ color: "white"}}>
                <a href="/terms-and-conditions" target="_blank" rel="noreferrer" className='foot'>
                    <p>Terms & Conditions</p>
                </a>
                <a href="/privacy-policy" target="_blank" rel="noreferrer" className='foot'>
                    <p>Privacy Policy</p>
                </a>
                {/* <NavLink to="/about" className='foot'>
                    <p >About</p>
                </NavLink> */}
                {/* <NavLink to="/contacts" className='foot'>
                    <p >Contacts</p>
                </NavLink> */}
            </Flex>
          </Col>  
         </Row>
        </Footer>
    )
}
