import { Card, Row, Typography, Flex } from "antd";
import { FooterHandler } from "./Footer";

export function About() {
    return(
    <>
    <Row style={{width: "100%", height:"100vh", display:"flex", justifyContent:"center", marginTop:"100px"}}>
        <Row style={{width: "40%", justifyContent:"center"}}>
        <Card style={{width: "100%", height:"20%"}}>
        About Here
        </Card>
        </Row>
    </Row>   
    <FooterHandler />
    </>
    )
    
}