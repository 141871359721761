import { Form, Modal, Input } from "antd";
import React from "react";

export function AddLaserProfile(props) {
  return (<Modal title='Save this profile?' open={props.laserProfileSaveVisible} onCancel={() => {
    props.setLaserProfileSaveVisible(false);
    props.laserProfileForm.resetFields();
  }} okText='Save' okButtonProps={{
    loading: props.submitProfileIsLoading
  }} onOk={() => {
    props.laserProfileForm.submit();
  }}>
    <Form name='laserProfile' form={props.laserProfileForm} onFinish={props.handleConfirmSaveLaser}>
      <Form.Item name={['laserProfile', 'description']} label='Description' rules={[{
        required: true,
        message: 'Please provide a unique description.'
      }]}>
        <Input.TextArea placeholder='Please provide a short description of this laser.' />
      </Form.Item>

      <Form.Item name={['laserProfile', 'data']} noStyle />
  
    </Form>
  </Modal>);
}
