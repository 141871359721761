import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography

const PFHandler = (props) => {
    const { hec, condition, laserClass } = props

    const pf = hec?.[`condition${condition}`]?.class?.[`class${laserClass}`]?.pf || '-'
    return (
        <Text
            strong
            type={(() => {
                if (pf !== null && pf !== undefined) {
                    if (pf === 'Pass') return 'success'
                    if (pf === 'Fail') return 'danger'
                    return null
                } else {
                    return null
                }
            })()}

        >
            {pf}
        </Text>
    )
}

export default PFHandler