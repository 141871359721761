import { Button, Card, Col, Form, Radio, Row, Select, Tooltip, Typography, message, Spin, Space, ConfigProvider, Flex } from 'antd'
import { DownloadOutlined, CloseOutlined, LockOutlined, SaveTwoTone, FullscreenExitOutlined, FullscreenOutlined, SettingOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import CWCalculator from './MPE_AEL/CWCalculator'
import SinglePulseCalculator from './MPE_AEL/SinglePulseCalculator'
import RepetitivelyPulsedCalculator from './MPE_AEL/RepetitivelyPulsedCalculator'
import Field from '../../../components/Field'
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import '../../../components/nav_bar.css'
import { ResultCW } from './Results/MPE_AEL_Results/ResultCW'
import { ResultSinglePulse } from './Results/MPE_AEL_Results/ResultSinglePulse'
import { ResultRepetitivelyPulsed } from './Results/MPE_AEL_Results/ResultRepetitivelyPulsed'
import { ResultCWExtended } from './Results/MPE_AEL_Results/ResultCWExtended'
import { ResultRepetitivelyPulsedExtended } from './Results/MPE_AEL_Results/ResultRepetitivelyPulsedExtended'
import { ResultSinglePulseExtended } from './Results/MPE_AEL_Results/ResultSinglePulseExtended'
import { AddLaserProfile } from '../../../components/LaserProfile/AddLaserProfile'
import { ReportModal } from './ReportModal'
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ResultsPDF } from '../../../components/PDF/ResultsPDF'
import FetchCalculations from '../../../components/FetchCalculations'
import FetchLaserProfile from '../../../components/MyLaserProfileFetch'
import { CalculationStatusModal } from '../../../components/CalculationStatusModal'
import { ReactComponent as Bars } from '../../../assets/Bars.svg'
import { ReactComponent as Wedges } from '../../../assets/Wedges.svg'
import { ConfirmCalculateModal } from '../../../components/ConfirmCalculateModal'
import Loader from '../../../assets/Loader'
import './Calcs.css'
import { fetchSVJSON } from '../../../helpers/svgToJSON_Generator/fetchSVJSON'
import {
  Font,
  StyleSheet,
  Document,
  Page,
  Svg,
  Line,
  Polyline,
  Polygon,
  Path,
  Rect,
  Circle,
  Ellipse,
  Text,
  Tspan,
  G,
  Stop,
  Defs,
  ClipPath,
  LinearGradient,
  RadialGradient,
  Image
} from '@react-pdf/renderer'
import { svgToSvg } from '../../../helpers/svgToJSON_Generator/svgToSvg'
import SettingsModal from './SettingsModal'

// Below are imports for driver.js <-- the automated tour guide for the webapp
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

export const textCenter = { display: 'flex', justifyContent: 'center' }

const myInitialValues = {
  calculatorType: "MPE_AEL",
  laserOperation: 'cw',
  sourceType: 'point',
  cw: {
    power: {
      unit: false
    },
    wavelength: {
      unit: 'nm'
    },
    time: {
      unit: false
    },
    beamDivergence: {
      unit: 'm'
    },
    distance: {
      unit: false
    },
    beamDiameter: {
      unit: "m"
    }
  },

  singlePulse: {
    outputUnits: 'energy',

    wavelength: {
      unit: 'nm'
    },
    beamDivergence: {
      unit: 'm'
    },

    distance: {
      unit: false
    },
    energy: {
      unit: false
    },
    peakPower: {
      unit: false
    },
    pulseDuration: {
      unit: false
    },
    beamDiameter: {
      unit: "m"
    }

  },

  extendedSettings: {
    sourceSettings: 'sourceSize',
    apparentSourceSize: {
      unit: 'm'
    }
  },


  repetitivelyPulsed: {
    outputUnits: 'energy',

    wavelength: {
      unit: 'nm'
    },
    beamDivergence: {
      unit: 'm'
    },
    distance: {
      unit: false
    },
    energy: {
      unit: false
    },
    peakPower: {
      unit: false
    },
    averagePower: {
      unit: false
    },
    time: {
      unit: false
    },
    pulseFrequency: {
      unit: false
    },
    pulseDuration: {
      unit: 'm'
    },
    beamDiameter: {
      unit: "m"
    }
  }
}

const MPE_AEL = ({ isLocal, myUser, setMyUser }) => {
  // This state is only used by this component and its children. it's not meant to be used outside of this component at all
  // -Jayson
  const [result, setResult] = useState(null)
  const [form] = Form.useForm()
  const [laserProfileForm] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState(myInitialValues)
  const [form2] = Form.useForm()
  const [laserProfileSaveVisible, setLaserProfileSaveVisible] = useState(false)
  const [laserprofiledebug, setlaserprofiledebug] = useState(null)
  const [submitProfileIsLoading, setSubmitProfileIsLoading] = useState(false)
  const [isUsingLaserProfile, setIsUsingLaserProfile] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form3] = Form.useForm()
  const [downLoadPDF, setdownLoadPDF] = useState(null);
  // const [isDisabled, setIsDisabled] = useState(false)
  const [numberOfCalcs2, setNumberOfCalcs2] = useState(null)
  const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial } = FetchCalculations(isLocal, myUser, numberOfCalcs2)
  const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal,)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [openBuy, setOpenBuy] = useState(false)
  const [units, setunits] = useState("joules")
  const [resultEmpty, setresultEmpty] = useState(null)
  const [spinning, setSpinning] = useState(true);
  const [svJSON, setSVJSON] = useState({})
  const [buffer, setBuffer] = useState(null)
  const [svgComponent, setSvgComponent] = useState(null)
  const [isFullScreen, setisFullScreen] = useState(false)

  const [showSettingsModal, setShowSettingsModal] = useState(false)
  Font.register({ family: 'Source Sans 3', src: "https://fonts.googleapis.com/css2?family=Source+Sans+3:ital@0;1&display=swap" })
  const stylez = StyleSheet.create({
    all: { fontFamily: 'Source Sans 3' }
  })

  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '#save-laser',
        popover: {
          title: 'Save Laser',
          description: 'Clicking this button will save the laser after providing a description for it.'
        }
      }
    ]
  })

  // useEffect(() => {
  //   // This use effect tries out the svgToSvg function
  //   svgToSvg().then((response) => {
  //     console.log(`response from svgToSvg: `, response)
  //     setSvgComponent(response)
  //   })
  // }, [])

  const toggleFullscreen = () => {
    setisFullScreen((prevFullScreen) => !prevFullScreen)
  }

  {/*Lets the user calculate if numberofcalculations !== 0 */ }
  const checkStatus = () => {
    {/*Prompts user to buy more calculations if numberofcalculations === 0 */ }
    if (isDisabled === true && isSubscribed === false && isFreeTrial === null) {
      setOpenBuy(true)
     
    }
    else if (isDisabled === false || isSubscribed === true || isFreeTrial === true) {
      {/*Prompts the user to proceed with the calculation */ }
      const { hide_confirmation } = JSON.parse(Cookies.get('RTLSS_SETTINGS'))
      if (hide_confirmation) {
        submitForm()
      } else {
        showPopconfirm(true)
      }
    }
  }

  const handleCancelCheckStatus = () => {
    {/**Close calculation status modal */ }
    
    setOpenBuy(false)
  };

  const showPopconfirm = () => {
    setIsConfirmOpen(true);
  };
  const handleCancelPopConfirm = () => {
   
    setIsConfirmOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);

  };

  useEffect(() => {
    if (numberOfCalcs !== null) {
      setSpinning(false);
    } else {
      setSpinning(true);
    }
  }, [numberOfCalcs]);

  const handleOk = () => {
    setLoading(true);
    let allReports = { ...form3.getFieldValue(), ...form.getFieldsValue(), result }
   
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/report'
        }
        else {
          return 'api/report'
        }
      })(),
      withCredentials: true,
      data: { data: allReports }
    }).then((res) => {
     
      success(res.data.message)

    }).catch((err) => {
     
      showError(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      setIsModalOpen(false)
      form3.resetFields()

    })

  };
  const handleCancel = () => {
    form3.resetFields()
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })
  }

  const showError = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
      duration: 4
    })
  }

  const onUnitChange = (e) => {
    setunits(e.target.value)
  }

  const submitForm = async () => {
    setIsSubmitting(true)
    setIsConfirmOpen(false);
   
    const data = form.getFieldsValue()
    let url = () => {
      if (isLocal === true) {
        return 'http://localhost:8000/calculate'
      } else {
        return 'api/calculate'
      }
    }
    axios({ method: 'post', url: url(), withCredentials: true, headers: { Authorization: `Bearer ${Cookies.get('RTLSS_TOKEN')}` }, data: { data, numberOfCalcs, isFreeTrial, isSubscribed } })
      .then(res => {
        setNumberOfCalcs2(res.data.newNumberOfCalculations)
        setResult(form.getFieldsValue())
        setIsSubmitting(false)
        setResult(res.data.calcResult)
      })
      .catch((err) => {
        navigate('/logout')
      })
  }

  const handleSaveLaser = () => {
    setLaserProfileSaveVisible(true)
  }

  const handleConfirmSaveLaser = () => {
    setSubmitProfileIsLoading(true)
    laserProfileForm.setFieldValue(['laserProfile', 'data'], form.getFieldsValue())
    setlaserprofiledebug(JSON.stringify(laserProfileForm.getFieldsValue(), null, 2))
    // Save this to database.
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/add-profile'
        } else {
          return 'api/add-profile'
        }
      })(),
      withCredentials: true,
      data: { data: laserProfileForm.getFieldsValue(), calculatorType: initialValues.calculatorType }
    }).then((res) => {
      success(res.data.message)
      setMyUser({ ...myUser, laserProfile: res.data.response.rows })
    }).catch((err) => {
      showError(err.response.data.message)
    }).finally(() => {
      setLaserProfileSaveVisible(false)
      setSubmitProfileIsLoading(false)
      laserProfileForm.resetFields()

    })
  }
  useEffect(() => {
    setdownLoadPDF(generatePDF())
  }, [result])
  const generatePDF = () => {

  }

  const handleDownload = () => {
    if (result) {
      const date = new Date().toLocaleString().split(',')[0]
      const pdfContent = (
        <ResultsPDF result={result} date={date} form={form.getFieldsValue()} />
      )
      
      return (
        <Space>
          <PDFDownloadLink document={pdfContent} fileName='Point-Extended-Results.pdf'>
            {({ blob, url, loading, error }) => (
              <Button icon={<DownloadOutlined />} type="primary">
                Download Results
              </Button>
            )}
          </PDFDownloadLink>

          {/* <PDFDownloadLink document={
            <Document>
              <Page size="LETTER" style={stylez}>
                <Svg viewBox={`0 0 595.3 841.9`}>
                  {svgComponent}
                </Svg>

              </Page>
            </Document>
          }>
            {({ blob, url, loading, error }) => (
              loading ? <Spin /> : <Button icon={<DownloadOutlined />}>Download Results V2</Button>
            )}
          </PDFDownloadLink> */}
        </Space>

      )
    }
  };


  // const handleDownloadV2 = () => {
  //   // Now that we have a new pdf with replaced data, we now create a new pdf document
  //   return (
  //     <PDFDownloadLink
  //       document={
  //         <Document>
  //           <Page size={"A1"}>
  //             <Image src={buffer} />
  //             <Text>
  //               Hello
  //             </Text>
  //           </Page>
  //         </Document>
  //       }
  //       fileName='pdfv2ssssss'
  //     >
  //       {({ blob, url, loading, error }) => (
  //         loading ? (
  //           <Spin />
  //         ) : (
  //           <Button icon={<DownloadOutlined />} type="primary">
  //             Download Results V2
  //           </Button>
  //         )
  //       )}
  //     </PDFDownloadLink>
  //   )
  // }
  // Change the details of the svg here







  const gradientBackground = {
    backgroundImage: 'linear-gradient(90deg, #e6007e, #ffcc01, #10c96d, #00B5E2)',
    marginTop: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px', // Adjust height as needed
  };

  return (
    <>
      {contextHolder}

      <AddLaserProfile laserProfileForm={laserProfileForm}
        laserProfileSaveVisible={laserProfileSaveVisible}
        setLaserProfileSaveVisible={setLaserProfileSaveVisible}
        submitProfileIsLoading={submitProfileIsLoading}
        handleConfirmSaveLaser={handleConfirmSaveLaser} />


      <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", right: "50%", top: "60%", transform: "translate(-41%, -50%)" }} />}>
        <div class="calcs">
          <Spin spinning={isSubmitting} indicator={<Loader />} style={{ zIndex: 10000, fontSize: "500px" }}>
            {numberOfCalcs !== null ? (
              <>
                <Row justify={"center"}>
                  <Col lg={result === null ? 24 : 12} sm={24}
                    style={{
                      padding: '15px 20px',
                      display: (() => {
                        if (isFullScreen) {
                          return "none"
                        }
                        else {
                          return null
                        }
                      })()
                    }}>
                    <Row justify={'center'}>
                      <Col lg={result === null ? 10 : 18} md={24}>
                        <Card title={<h4>Point and Extended Calculator</h4>} style={{ width: '100%', display: 'block', marginTop: "60px", boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.3)" }} extra={result != null ? (
                          <>
                            <Tooltip
                              mouseEnterDelay={0.5}
                              destroyTooltipOnHide
                              showArrow
                              placement='left'
                              title={`Click me to save this laser setup.`}>
                              <Button id='save-laser' type='text' onClick={handleSaveLaser} icon={<SaveTwoTone />} />
                            </Tooltip>
                            <Button type='text' icon={<SettingOutlined />}
                              onClick={() => {
                                setShowSettingsModal(true)
                              }} />
                            <Button type='text' icon={<CloseOutlined />} />
                          </>
                        ) : (
                          <Button type='text' icon={<SettingOutlined />}
                            onClick={() => {
                              setShowSettingsModal(true)
                            }} />
                        )}>
                          <Form
                            form={form2}
                            labelCol={{ span: 8 }}>
                            <Form.Item label='Saved Laser' name='laserProfile'>
                              <Select placeholder='Select from saved lasers'
                                options={(() => {
                                  if (myLaserProfile?.laserProfile) {
                                    const { laserProfile } = myLaserProfile
                                    let filterProfile = laserProfile.filter(laserProfile => laserProfile.calculatortype === "MPE_AEL")
                                    return filterProfile.map((profile, index) => ({
                                      key: index,
                                      label: profile.description,
                                      value: JSON.stringify(profile.data)
                                    }))
                                  } else {
                                    return null
                                  }
                                })()}
                                disabled={(() => {
                                  if (myLaserProfile?.laserProfile.length !== 0) {
                                    return false
                                  } else {
                                    return true
                                  }
                                })()}
                                onChange={(props) => {
                                  setInitialValues({ ...myInitialValues, ...JSON.parse(props) })
                                  setIsUsingLaserProfile(true)
                                  setResult(null)
                                }}
                              />
                            </Form.Item>
                          </Form>



                          <Form
                            form={form}
                            initialValues={initialValues}
                            name='laserSafety'
                            onFinish={checkStatus}
                            onValuesChange={() => {
                              setResult(null)
                              form2.resetFields()
                            }}
                            labelCol={{ span: 8 }}>
                            <Form.Item
                              name='laserOperation'
                              label='Laser Operation'>
                              <Radio.Group optionType='default'>
                                <Radio.Button value='cw'>CW</Radio.Button>
                                <Radio.Button value='singlePulse'>Single Pulse</Radio.Button>
                                <Radio.Button value='repetitivelyPulsed'>Repetitively Pulsed</Radio.Button>
                              </Radio.Group>
                            </Form.Item>

                            <Form.Item label='Source Type' name='sourceType'>
                              <Radio.Group optionType='default'>
                                <Radio.Button value='point'>Point Source</Radio.Button>
                                <Radio.Button value='extended'>Extended Source</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                              {({ getFieldValue }) => {
                                let sourceType = getFieldValue(['sourceType'])
                                return (
                                  sourceType === 'extended' ? (
                                    <Card size='small' title='Source Settings'>
                                      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.extendedSettings.sourceSettings !== curr.extendedSettings.sourceSettings}>
                                        <Field
                                          tabIndex={1}
                                          name={['extendedSettings', 'apparentSourceSize']}
                                          label='Apparent Source Size'
                                          unit='m'
                                          placeholder={`Enter apparent source size`}
                                          denoms={['u', 'm', 'c']}
                                          toolTipText={`The diameter of a real or virtual object that forms from the smallest possible retinal image.`}
                                        />
                                      </Form.Item>
                                    </Card>
                                  ) : (
                                    sourceType === 'point' ? null : null
                                  )
                                )
                              }}
                            </Form.Item>

                            {/* Note below, I added an if statement to check if anything has changed with the form before I render it. */}
                            {/* Without it, the program will re-render everything inside the Form.Item, and in turn, lag. :P */}
                            <Form.Item noStyle shouldUpdate={(prevItem, currItem) => prevItem.laserOperation !== currItem.laserOperation || prevItem.laserOperation === null}>
                              {({ getFieldsValue }) => {
                                let laserOperation = getFieldsValue(['laserOperation']).laserOperation
                                return (
                                  laserOperation === 'cw' ? (<CWCalculator />) : (
                                    laserOperation === 'singlePulse' ? (<SinglePulseCalculator form={form} />) : (
                                      laserOperation === 'repetitivelyPulsed' ? (<RepetitivelyPulsedCalculator form={form} />) : (null)
                                    )
                                  )
                                )

                              }}
                            </Form.Item>

                            <div class="hover-underline-animation2" style={{ width: '100%' }} >
                              <Button
                                style={{
                                  margin: '24px 0 0',
                                  background: '#1d1f8c'
                                }}
                                htmlType='submit'
                                block
                                type='primary'
                                loading={isSubmitting} >
                                Calculate
                              </Button>
                            </div>
                            {isSubscribed === false ? (
                              <>
                                <Row justify={'end'} style={{ marginTop: "1%" }}>
                                  <Typography.Text type='secondary' style={{ fontSize: "20px" }}>
                                    {isFreeTrial ? <i>Free Trial</i> : <i>Remaining Calculations: <strong style={{ color: "black" }}>{numberOfCalcs}</strong></i>}
                                  </Typography.Text>
                                </Row>
                                {numberOfCalcs < 6 && !isFreeTrial ? (
                                  <Row justify={'end'} style={{ marginTop: "1%" }}>
                                    <Typography.Text type='secondary' style={{ fontSize: "15px" }}>
                                      Running low on calculations? Get more calculations <NavLink to={`/home?defKey=${"4"}&handleClick=${"billing"}`}>here!</NavLink>
                                    </Typography.Text>
                                  </Row>
                                ) : null}
                              </>
                            ) :
                              <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "20px" }}><i>Unlimited Calculations</i></Typography.Text></Row>
                            }

                            {result && (
                              <Flex justify='right'>
                                <Button
                                  type='text'
                                  onClick={() => {
                                    driverObj.drive()
                                  }}
                                >
                                  <Typography.Text italic>Do you want to save this laser?</Typography.Text>
                                </Button>
                              </Flex>
                            )}
                          </Form>
                        </Card>

                      </Col>
                    </Row>
                  </Col>
                  {result !== null && numberOfCalcs !== null ? (
                    <>
                      <Col lg={12} sm={24} style={{ padding: "15px 20px", width: "100vw", marginTop: "60px" }}>
                        <Row justify={"center"} >
                          <Card
                            title="Calculation Results"
                            extra={[
                              <>
                                <Space>
                                  <Radio.Group onChange={onUnitChange} value={units} buttonStyle="solid">
                                    <Radio.Button value="joules">Joules</Radio.Button>
                                    <Radio.Button value="watts">Watts</Radio.Button>
                                  </Radio.Group>
                                  <Tooltip destroyTooltipOnHide placement='top' showArrow mouseEnterDelay={0.5} title="Click me to hide inputs." zIndex={1}>
                                    <Button icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={toggleFullscreen} />
                                  </Tooltip>
                                </Space>
                              </>]}
                            style={{ width: "90vw", boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.3)" }}>

                            {result.laserOperation === "cw" ? (
                              <ResultCW result={result} textCenter={textCenter} units={units} />
                            ) : result.laserOperation === "singlePulse" ? (
                              <ResultSinglePulse result={result} textCenter={textCenter} units={units} />
                            ) : result.laserOperation === "repetitivelyPulsed" ? (
                              <ResultRepetitivelyPulsed result={result} textCenter={textCenter} units={units} />
                            ) : result.laserOperation === "cw-extended" ? (
                              <ResultCWExtended result={result} textCenter={textCenter} units={units} />
                            ) : result.laserOperation === "singlePulse-extended" ? (
                              <ResultSinglePulseExtended result={result} textCenter={textCenter} units={units} ></ResultSinglePulseExtended>
                            ) : result.laserOperation === "repetitivelyPulsed-extended" ? (
                              <ResultRepetitivelyPulsedExtended result={result} textCenter={textCenter} units={units} />
                            ) : null}
                            <Row justify={"end"} style={{ margin: "25px 5px 5px 0" }}>
                              {/* <PDFDownloadLink document={downLoadPDF} fileName="Results">
                                          <Button icon={<DownloadOutlined />} type="primary" onClick={handleDownload}>Download Results</Button>
                                      </PDFDownloadLink>  */}
                              {handleDownload()}
                            </Row>
                          </Card>
                          <Button type='primary' danger style={{ marginTop: 10, width: "100%", whiteSpace: "normal", height: "auto", color: "white" }} onClick={showModal}><Typography color='white' style={{ color: "white" }} >Is there something wrong with the results? Click here to report a bug.</Typography></Button>

                          <ReportModal loading={loading} isModalOpen={isModalOpen} form3={form3} handleOk={handleOk} handleCancel={handleCancel}></ReportModal>
                        </Row>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </>)
              : null}
          </Spin>
        </div>
      </Spin>

      <ConfirmCalculateModal isConfirmOpen={isConfirmOpen} handleCancelPopConfirm={handleCancelPopConfirm} submitForm={submitForm}></ConfirmCalculateModal>

      <SettingsModal showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />
      {/**C */}
      <CalculationStatusModal openBuy={openBuy} handleCancelCheckStatus={handleCancelCheckStatus}></CalculationStatusModal>
    </>
  )
}


export default MPE_AEL
