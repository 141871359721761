import { Card, Row, Typography, Flex } from "antd";
import { FooterHandler } from "./Footer";

export function TermsAndConditions() {
    return(
    <>
    <Row style={{width: "100%", height:"auto", display:"flex", justifyContent:"center", marginTop:"60px", marginBottom:"20px"}}>
        <Row className="content">
            <Card className="scroll" bordered={false} style={{width: "100%"}}>
            <Flex justify="center">
                <Typography.Title>
                Terms & Conditions (sample)
                </Typography.Title>
            </Flex>
          

            {/* Last Updated: [Date] */}
            <Typography>
                Welcome to RealTime LSS Calculator. By accessing or using our Service, you agree to comply with and be bound by these Terms and Conditions (the “Terms”). If you do not agree with these Terms, please do not use our Service.
            </Typography>
            
            <Typography.Text strong={true}>
            1. Acceptance of Terms
            </Typography.Text>
            <Typography.Paragraph>
            By using our Service, you affirm that you are at least 18 years old or have obtained parental consent to use the Service.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            2. Changes to Terms
            </Typography.Text>
            <Typography.Paragraph>
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on this page. Your continued use of the Service after any changes constitutes your acceptance of the new Terms.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            3. Privacy Policy
            </Typography.Text>
            <Typography.Paragraph>
            Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            4. User Obligations
            </Typography.Text>
            <Typography.Paragraph>
            You agree to use the Service only for lawful purposes and in a manner that does not infringe the rights of others or restrict or inhibit anyone else's use of the Service.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            5. Account Registration
            </Typography.Text>
            <Typography.Paragraph>
            To access certain features of the Service, you may need to register for an account. You agree to provide accurate and complete information and to update it as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            6. Intellectual Property
            </Typography.Text>
            <Typography.Paragraph>
            All content and materials available through the Service, including but not limited to text, graphics, logos, and software, are the property of Lasermet Ltd. or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works of any content from the Service without our express written permission.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            7. Limitation of Liability
            </Typography.Text>
            <Typography.Paragraph>
            To the fullest extent permitted by law, Lasermet Ltd. shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Service, even if we have been advised of the possibility of such damages.
            </Typography.Paragraph>
            <Typography.Text strong={true}>
            8. Governing Law
            </Typography.Text>
            <Typography.Paragraph>
            </Typography.Paragraph>
            These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
            <Typography.Text strong={true}>
            9. Contact Information
            </Typography.Text>
            <Typography.Paragraph>
            For questions about these Terms, please contact us at:
            </Typography.Paragraph>
            {/* [Your Email Address]
            [Your Phone Number]
            [Your Physical Address] */}
            <Typography.Text strong={true}>
            10. Entire Agreement
            </Typography.Text>
            <Typography.Paragraph>
            These Terms, together with our Privacy Policy, constitute the entire agreement between you and Lasermet Ltd. regarding your use of the Service, superseding any prior agreements between you and Lasermet Ltd..
            </Typography.Paragraph>
            </Card>
        </Row>
    </Row>   
    <FooterHandler />
    </>
    )
    
}