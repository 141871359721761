import React, {useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import LookupTable from '../../pages/Loginv2/Calculators/LookupTable';
import ResultCard from "../ResultCard";
import { Card, Row, Typography } from 'antd';
import Watermark from './Watermark';
import logo from "../../assets/Logo/logosidetext.png"
import laserFooter from "../../assets/Logo/laserFooter.png"
// Create styles
Font.register(({family: 'Roboto', src: "http://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf" }))
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: "center",
    position: 'relative',
    paddingTop: '25px'
  },
  title: {
    justify: 'cenetr',
    textAlign: 'center',
    borderBottom: '1px solid black',
    marginBottom: "0.2cm",
    marginTop: "0.5cm"

  },
  image: {
    width: "35vw",
    marginBottom: "45px"
   
  },
  row: {
    flexDirection: "row",
    justifyContent:"space-evenly"
  },
  box: {
    width: "0.8in",
    height: '0.8in',
    justify: 'center',
    borderRadius:"50px"
  
  },
  // marginLeft: {
  //   marginLeft: "2cm"
  // },
  underLine: {
    borderBottom: '1px solid black',
    marginBottom: "0.5cm"
  },
  data: {
    fontSize: "6mm"
  },
  center: {
    display:"flex",
    justify: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  subTitle: {
    fontSize: "16px",
    justifyContent: "space-around",
    fontWeight: "bold",
    marginBottom: "3px"
  },
  result: {
    fontSize: "15px",
    fontWeight: "bold",
    marginTop: "10px"
  },
  titleBox: {
    justify: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    backgroundColor: 'rgb(230,230,230)',
    marginBottom: "0.2cm",
    marginTop: "0.5cm",
    width: "90vw",
    height: "30px",
    padding: "5px",
    fontSize: "15px",
    fontWeight: "bold"

  },
  header: {
    justifyContent: "space-between",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    width: "120vw",
    height: "4vh",
    marginLeft: "-32px"
  
  },

});
export function LookUpTablePDF({
  results,
  textCenter,
  date
})

{

// Create Document Component
return (
  <Document>
   <Page size="LETTER" style={styles.page}>
   <Watermark/>
        <View>
          <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
            </View>
          </View>
            
          <View style={styles.row}>
            <div style={styles.titleBox}>
              <Text>LOOKUP TABLE RESULTS </Text>
            </div>
          </View>
          <View style={styles.row}>
            <View>
              <Text style={styles.result}>Wavelength: {results.wavelength} nm </Text>
              {results.calcType === "extended" ? ( <>
              <Text style={styles.result}> Apparent Source Size: {results.sourceSize}rad</Text>
              <Text style={styles.result}> Viewing Distance: {results.distance}m</Text>
            </>):null}
          
            </View>
            <View>
          	  <Text style={styles.result}>Time: {results.time}s</Text>
            </View>    
          </View>
     
            <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Accessible Emission Limit</Text> 
              </div>
            </View>
            <View style={styles.row}>
            
                <View>
                  <Text style={styles.subTitle}>Classes</Text>
                  <Text style={styles.result}>Class 1:</Text>
                  <Text style={styles.result}>Class 2:</Text>
                  <Text style={styles.result}>Class 3R:</Text>
                  <Text style={styles.result}>Class 3B:</Text>
                </View>
                <View>
                  <Text style={styles.subTitle}>Joules</Text>
                  <Text style={styles.result}> {results.ael.class1.joulesPretty !== "nullJ" ? results.ael.class1.joulesPretty : results.ael.class1.joulesPerSqmPretty } </Text>
                  <Text style={styles.result}> {results.ael.class2.joulesPretty } </Text>
                  <Text style={styles.result}>{results.ael.class3r.joulesPretty !== "nullJ" ? results.ael.class3r.joulesPretty : results.ael.class3r.joulesPerSqmPretty} </Text>
                  <Text style={styles.result}> {results.ael.class3b.joulesPretty }  </Text>
                </View>
                <View>
                  <Text style={styles.subTitle}>Watts</Text>
                  <Text style={styles.result}> {results.ael.class1.wattsPretty !== "nullW" ? results.ael.class1.wattsPretty : results.ael.class1.wattsPerSqmPretty}</Text>
                  <Text style={styles.result}>  {results.ael.class2.wattsPretty} </Text>
                  <Text style={styles.result}> {results.ael.class3r.wattsPretty !== "nullW" ? results.ael.class3r.wattsPretty : results.ael.class3r.wattsPerSqmPretty}</Text>
                  <Text style={styles.result}>{results.ael.class3b.wattsPretty}</Text>
                </View>
              </View>
          
           <View style={styles.row}>
                <div style={styles.titleBox}>
                  <Text>Maximum Permissible Emission</Text> 
                </div>
          </View>
              <View style={styles.row}>
                <View>
                  <Text> </Text>
                  <Text style={styles.result}>Eye MPE:</Text>
                  <Text style={styles.result}>Skin MPE:</Text>
                </View>
                <View>
                  <Text style={styles.subTitle}>Joules</Text>
                  <Text style={styles.result}>{results.mpe.eye.joulesPerSqmPretty.eng}J/m²</Text>
                  <Text style={styles.result}>{results.mpe.skin.joulesPerSqmPretty.eng}J/m²</Text>
                </View>
                <View>
                <Text style={styles.subTitle}>Watts</Text>
                <Text style={styles.result}> {results.mpe.eye.wattsPerSqmPretty.eng}W/m²</Text>
                <Text style={styles.result}>{results.mpe.skin.wattsPerSqmPretty.eng}W/m²</Text>
                </View>
              </View>
              <View style={styles.row}>
                  <div style={styles.titleBox}>
                    <Text>Spectrum</Text> 
                  </div>
                </View>
                <View style={styles.center}>
                    <View style={styles.center}>
                      <Text style={[
                          styles.box, 
                          {backgroundColor: (() => {
                            if (results.spectrum.range == "Infrared") {
                              return "maroon"
                            }
                            else if (results.spectrum.range == "Ultraviolet") {
                              return "purple"
                            }
                              return "rgb(" + results.spectrum.rgb + ")"
                            })()} 
                      ]}>
                      </Text>
                    </View>
                    <View style={{alignSelf:"center", marginLeft: "10px"}}>
                      <Text>{results.spectrum.region}</Text>
                    </View>
                </View>
            
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </View> 
    </Page>

    <Page size="LETTER" style={styles.page}>
    <Watermark />
    <View>
      <View style={[styles.row, styles.header]}>
              <View>
                <Image src={logo} style={styles.image}></Image>
            </View>
              <View>
                <Text style={{marginTop:"20px"}}>{date}</Text>
              </View>
      </View>
      <View style={styles.row}>
                <div style={styles.titleBox}>
                  <Text>Correction Factors & Time Bases</Text>
                </div>
      </View>
      <View style={styles.row}>
        <View>  
          {results.corectionFactors.map(cf => {
            if (cf.name === "T1" || cf.name === "T2") {
              return null // Skip rendering for filtered items
            }
            return (
              <Text style={styles.result}>{cf.name}: {cf.pretty}</Text>
            )
          })}
        </View>
      <View>
        {results.corectionFactors.map(cf => {
          if (cf.name === "T1" || cf.name === "T2") {
            return <Text style={styles.result}> {cf.name}: {cf.pretty}</Text>// Skip rendering for filtered items
          }
        })}
      </View>  
    </View>
    <View style={styles.row}>      
                  <Text style={styles.titleBox}>Limiting Aperture</Text>
                </View>
                <View style={styles.row}>
                  <View>
                    <Text style={styles.result}>Eye:  {results.limitingAperture.eye}mm</Text>
                  </View>
                  <View>
                    <Text style={styles.result}>Skin: {results.limitingAperture.skin}mm</Text>
                  </View>
                </View>  
                
                {results.calcType === "extended" ? (
                <><View style={styles.row}>
                    <Text style={styles.titleBox}>Angular Subtense</Text>
                  </View>
                  <View style={ styles.center}>
                    <Text style={[styles.result, styles.center]}>{results.angularSubtense.eng}rad</Text>
                  </View></>
                ): null}
                
       
             
          <View style={styles.row}>  
            <Text style={styles.titleBox}>Measurement Aperture Diameters and Distances</Text>
          </View>
        <View  style={styles.row}>
          <Text></Text>
          <Text style={{...styles.subTitle, marginLeft: "60px"}}>Aperture Stop </Text>
          <Text style={{...styles.subTitle, marginLeft: "-50px"}}>Distance</Text>
        </View>
        <View style={styles.row}>
            <View>
                <Text style={styles.result}>Condition 1</Text>
            </View>
              <View>
                <Text style={styles.result}>{results.conditions.condition1.aperture}</Text>
              </View>
              <View>
                <Text style={styles.result}> {results.conditions.condition1.distance}</Text>
              </View>  
          </View>
          <View style={[styles.row, { width: "100%", borderBottom: "1px solid black", margin:"10px 5px 5px 10px"}]}></View>        
          <View style={styles.row}>
              <View>
                <Text style={styles.result}>Condition 3</Text>
              </View>
              <View>
                <Text style={styles.result}>{results.conditions.condition3.aperture}</Text>
              </View>
              <View>
                <Text style={styles.result}>{results.conditions.condition3.distance}</Text>
              </View>  
          </View>
        
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>        
      </View>
    </Page>
  </Document>
)
}
export default LookUpTablePDF