import { Form, InputNumber, Radio, Select, Card } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Field from '../../../../components/Field';

export function DRSPFields({ form }) {

    const decimalForm = ({ unit, num }) => {
        switch (unit) {
            case 'T': {
                return num * 1e12
            }
            case 'G': {
                return num * 1e9
            }
            case 'M': {
                return num * 1e6
            }
            case 'k': {
                return num * 1e3
            }
            case false: {
                return num
            }
            case 'm': {
                return num * 1e-3
            }
            case 'u': {
                return num * 1e-6
            }
            case 'n': {
                return num * 1e-9
            }
            case 'p': {
                return num * 1e-12
            }
            case 'f': {
                return num * 1e-15
            }
        }
    }
    return <>
        <Card title="Single Pulse Settings">
        {/* Wavelength */}
        <Field
            name={['diffuseReflectiveSP', 'wavelength']}
            denoms={['u', 'n']}
            unit='m'
            min='180'
            max='1000000'
            label='Wavelength'
            placeholder='Enter wavelength (180nm - 1,000,000nm)'
            toolTipText={'The operating wavelength of the laser beam.'} />

        {/* Pulse Duration */}
        <Form.Item
            name={['diffuseReflectiveSP', 'pulseDuration', 'num']}
            label={`Pulse duration`}
            style={{ width: '100%' }}
            rules={[
                { required: true, message: 'Please specify a value' },
                () => ({
                    validator(_, value) {
                        let pulseDuration = form.getFieldsValue()?.diffuseReflectiveSP?.pulseDuration;
                        if (decimalForm(pulseDuration) >= 0.25) {
                            return Promise.reject(<>The pulse duration is greater than or equal to 250 ms - This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'diffuseReflectiveCW'); }}>Click here</NavLink> to use the CW calculator instead.</>);
                        } else {
                            return Promise.resolve();
                        }
                    }
                })
            ]}
        >
            <InputNumber
                placeholder='Enter pulse duration'
                style={{ width: '100%' }}
                addonAfter={<Form.Item noStyle name={['diffuseReflectiveSP', 'pulseDuration', 'unit']}>
                    <Select
                        style={{ width: 80 }}
                        options={[
                            {
                                key: 1,
                                value: false,
                                label: 's'
                            },
                            {
                                key: 2,
                                value: 'm',
                                label: 'ms'
                            },
                            {
                                key: 3,
                                value: 'u',
                                label: 'µs'
                            },
                            {
                                key: 4,
                                value: 'n',
                                label: 'ns'
                            },
                        ]} />
                </Form.Item>} />


        </Form.Item>

        {/* Beam Output Unit */}
        <Form.Item
            name={['diffuseReflectiveSP', 'beamOutputUnit']}
            label='Beam output units'>
            <Radio.Group optionType='default'>
                <Radio.Button value='energy'>Energy</Radio.Button>
                <Radio.Button value='peakPower'>Peak Power</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.diffuseReflectiveSP.beamOutputUnit !== curr.diffuseReflectiveSP.beamOutputUnit}>
            {({ getFieldValue }) => {
                let beamOutputUnit = getFieldValue(['diffuseReflectiveSP', 'beamOutputUnit']);
                if (beamOutputUnit === 'energy') {
                    return (
                        <Field
                            name={['diffuseReflectiveSP', 'energy']}
                            label='Pulse Energy'
                            unit='J'
                            denoms={['u', 'm', false, 'k', 'M']}
                            placeholder={`Enter laser's output value`}
                            toolTipText={`The laser's output energy measured in Joules.`} />
                    );
                }
                else if (beamOutputUnit === 'peakPower') {
                    return (
                        <Field
                            name={['diffuseReflectiveSP', 'peakPower']}
                            unit='W'
                            denoms={['G', 'M', 'k', false, 'm', 'u', 'n', 'p']}
                            placeholder={`Enter laser's power output`}
                            label={'Laser Output Power'}
                            toolTipText={'The continuous power output of the laser beam.'} />
                    );
                }
            }}
        </Form.Item>

        {
            /* Beam Diameter */
        }
        <Field
            name={['diffuseReflectiveSP', 'beamDiameter']}
            label='Beam Diameter'
            unit='mm'
            placeholder={`Enter laser's beam diameter`}
            toolTipText={`The diameter of the source of the laser beam.`} />
        {
            /* Beam Divergence */
        }
        <Field
            name={['diffuseReflectiveSP', 'beamDivergence']}
            label='Beam Divergence'
            unit='rad'
            denoms={['m', false, 'degree']}
            placeholder={`Enter laser's beam divergence`}
            toolTipText={`The far field plane angle of the cone defined by the beam diameter.`} />

        <Field
            name={['diffuseReflectiveSP', 'distance']}
            label='Laser to Surface Distance'
            unit='m'
            denoms={['k', false, 'm', 'c', 'u', 'n']}
            placeholder={`Enter distance between laser and reflecting surface`}
            toolTipText={`The distance between the source of the laser and the reflecting surface.`} />
    </Card>
    </>;
}
