import React from "react";
import {Flex, Col} from "antd"
// This is the MPE and AEL Calculator components. All updates regarding this component should be done here.
export class Classification extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <>
        <Flex justify="center" vertical style={{ display: "grid" }}>
       
        <div style={{
          width: "300px",
          height: "150px",
          padding: "7px",
          backgroundColor: "#ffcd05",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          color: "black"
        }}>

          <div style={{
            width: "100%",
            height: "100%",
            borderStyle: "solid",
            borderWidth: "13px",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "10px"
          }}>

            <strong style={{
              display: "contents",
              whiteSpace: "nowrap",
              textAlign: "center",
              alignSelf: "center"
            }}>
              {this.props.Class === "1" ? <p style={{fontSize: "30px", alignSelf: "center"}}>
                <span style={this.props.spanBlock}>CLASS 1 </span> <span style={this.props.spanBlock}>LASER PRODUCT</span></p>
                
                : this.props.Class === "1M" ? <p>LASER RADIATION <span style={this.props.spanBlock}>DO NOT VIEW DIRECTLY </span><span style={this.props.spanBlock}>WITH OPTICAL INSTRUMENTS</span>CLASS 1M LASER PRODUCT</p>
                  
                : this.props.Class === "2" ? <p>LASER RADIATION
                <span style={this.props.spanBlock}> DO NO STARE INTO BEAM</span> CLASS 2 LASER PRODUCT</p>
                    
                : this.props.Class === "2M" ? <p>LASER RADIATION <span style={this.props.spanBlock}> DO NOT STARE INTO THE BEAM</span> <span style={this.props.spanBlock}>OR VIEW DIRECTLY WITH </span><span style={this.props.spanBlock}>OPTICAL INSTRUMENTS </span>CLASS 2M LASER PRODUCT</p>
                
                : this.props.Class === "3R" ? <p style={{fontSize: "15px", alignSelf: "center"}}>
                LASER RADIATION <span style={this.props.spanBlock}>AVOID DIRECT</span><span style={this.props.spanBlock}> EYE EXPOSURE</span> CLASS 3R LASER PRODUCT</p>
                
                : this.props.Class === "3B" ? <p style={{fontSize: "17px"}}>
                LASER RADIATION<span style={this.props.spanBlock}> AVOID EXPOSURE TO BEAM</span>CLASS 3B LASER PRODUCT</p>  
                
                : this.props.Class === "4" ? <p style={{fontSize: "15px"}}>
                LASER RADIATION <span style={this.props.spanBlock}> AVOID EYE OR SKIN EXPOSURE TO</span><span style={this.props.spanBlock}>DIRECT OR SCATTERED RADIATION </span>CLASS 4 LASER PRODUCT</p> 
                
                : null}
            </strong>
          </div>
        </div>

        {/* <p style={{ display:"grid", justifySelf:"center"}}>
         Get Laser Classification Label here.
        </p> */}
      </Flex>
      </>
    );
  }
}
