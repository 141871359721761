import { Card, Col, Row, Space } from 'antd'
import React from 'react'

const textCenter = { display: 'flex', justifyContent: 'center' }
const ResultCard = ({ children }) => {
    return (
        <Col style={{ margin: '10px 10px' }}>
            <Card style={{ height: '100%' }} bodyStyle={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Space direction='vertical'>
                    {children}
                </Space>
            </Card>
        </Col>
    )
}

export default ResultCard