import { Alert, Button, Form, Input, Layout, Row, Card, Typography, Flex, Checkbox } from 'antd'
import Password from 'antd/es/input/Password'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import miniIcon from '../assets/Logo/logosidetext.png'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import Axios from 'axios'
import { FooterHandler } from '../components/Footer/Footer'

const Register = (props) => {
    const navigate = useNavigate()
    const isLocal = props.isLocal
    const year = new Date().getFullYear();
    const [form] = Form.useForm()
    const [errorMessage, setErrorMessage] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const initialValues = {
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    const handleSubmit = () => {
        setIsSubmitting(true)
        setErrorMessage(null)

        let url = () => {
            if (isLocal === true) {
                return 'http://localhost:8000/register'
            } else {
                return 'api/register'
            }
        }

        Axios({
            method: 'post',
            url: url(),
            data: form.getFieldsValue(),
            withCredentials: true
        })
            .then((response) => {
                // this branch means the email has been sent and will now transfer user to success message.
                navigate('/confirm')
            })
            .catch((error) => {
                setIsSubmitting(false)
                setErrorMessage(error.response.data.message)
            })


    };
    return (
      <>
            <Content style={{ backgroundColor: 'white', height: "100vh" }}>
                
                <Flex  justify='center' style={{ width: "100%", minHeight: "100vh" }}>
                
                    <Card 
                        style={{
                                width: 600,
                                height: "100vh",
                                fontWeight: '500',
                                border:"none",
                                marginTop:"100px"
                            }}
                        cover={
                            <Flex justify='center' align='center' style={{ marginBottom: "60px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                                <img
                                    justify='center' align='center'
                                    src={miniIcon}
                                    style={{ width: "90%", justifySelf: "center"}}
                                />
                            </Flex> 
                    }>
                        <Form
                            initialValues={initialValues}
                            onFinish={handleSubmit}
                            labelAlign={'right'}
                            labelCol={{ span: 7 }}
                            form={form}
                            style={{display:"grid"}}>
                            
                            <Form.Item
                                name='fullName'
                                label='Full Name'
                                required='true'
                                style={{marginBottom:"0px"}}>
                                <Form.Item
                                    name='firstName'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px' }}
                                    rules={[{ required: true, message: 'First name required!' }]}>
                                    <Input placeholder='First name' />
                                </Form.Item>

                                <Form.Item
                                    name='lastName'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                    rules={[{ required: true, message: 'Last name required!' }]}>
                                    <Input placeholder='Last name' />
                                </Form.Item>
                            </Form.Item>
                            
                            <Form.Item
                                name='username'
                                label='Username'
                                rules={[{ required: true, message: 'Please provide a username.' }]}>
                                <Input placeholder='Username'/>
                            </Form.Item>


                            <Form.Item name='email'
                                label='Email'
                                rules={[{ required: true, message: 'The activation key is sent in this email so it is important to have a valid email address entered here.' }, { type: 'email', message: 'Invalid email!' }]}>
                                <Input placeholder='test@example.com' />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password autoComplete='new-password' placeholder='Password'/>
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder='Confirm Password' />
                            </Form.Item>
                            <Form.Item
                                name="consent"
                                valuePropName="checked"
                                required='true'
                                label=""
                                style={{justifySelf:"center"}}
                                > 
                                <Checkbox required='true'>
                                    <Typography.Text type='secondary'>
                                        I have read and agree to the <a href="/terms-and-conditions" target='_blank' rel="noreferrer">Terms and Conditions</a> and 
                                        <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{textDecoration:"none"}}> Privacy Policy</a>.
                                    </Typography.Text>
                                </Checkbox>
                            </Form.Item>

                            <Button loading={isSubmitting} htmlType='submit' type='primary' size='large' block>Register</Button>
                            {errorMessage !== null ? (
                                <Alert
                                    style={{ margin: '24px 0' }}
                                    description={errorMessage}
                                    type='error'
                                    showIcon
                                />
                            ) : null}
                            <Row justify='center' style={{marginTop:"15px"}}>
                                <Row>
                                    <Typography>
                                        Already have an account? <NavLink to='/'>Login</NavLink> here.
                                    </Typography>
                                </Row>
                            </Row>
                        </Form>
                    </Card>
                </Flex>
            </Content>
            <FooterHandler />
            {/* <Footer style={{
                textAlign: 'center',
                backgroundColor: '#3a4e84',
                color: '#f5f5f5',
                fontSize: '16px'
            }}
            >
                Lasermet Philippines © {year}. Created by Lasermet Philippines.
            </Footer> */}
      </>
    )
}

export default Register