import { Card, Form, InputNumber, Radio, Select, Tooltip } from 'antd'
import React, { useState } from 'react'
import Field from '../../../../components/Field'
import { NavLink } from 'react-router-dom'

const RepetitivelyPulsedCalculator = ({ form }) => {
    const [customPlaceholder, setCustomPlaceholder] = useState('Must be less than 250 ms')
    const [customMin, setCustomMin] = useState(0.25)
    const decimalForm = ({ unit, num }) => {
        switch (unit) {
            case 'T': {
                return num * 1e12
            }
            case 'G': {
                return num * 1e9
            }
            case 'M': {
                return num * 1e6
            }
            case 'k': {
                return num * 1e3
            }
            case false: {
                return num
            }
            case 'm': {
                return num * 1e-3
            }
            case 'u': {
                return num * 1e-6
            }
            case 'n': {
                return num * 1e-9
            }
            case 'p': {
                return num * 1e-12
            }
            case 'f': {
                return num * 1e-15
            }
        }
    }
    const handleUnitChange = (e) => {
        switch (e) {
            case false: {
                setCustomPlaceholder(`Must be less than 0.25 s`)
                setCustomMin(0.25)
                break
            }
            case 'm': {
                setCustomPlaceholder(`Must be less than 250 ms`)
                setCustomMin(250)
                break
            }
            case 'u': {
                setCustomPlaceholder(`Must be less than 250,000 µs`)
                setCustomMin(250000)
                break
            }
            case 'n': {
                setCustomPlaceholder(`Must be less than 250,000,000 ns`)
                setCustomMin(250000000)
                break
            }
            case 'p': {
                setCustomPlaceholder(`Must be less than 250,000,000,000 ps`)
                setCustomMin(250000000000)
                break
            }
            case 'f': {
                setCustomPlaceholder(`Must be less than 250,000,000,000,000 fs`)
                setCustomMin(250000000000000)
                break
            }

        }
    }
    return (
        <Card
            style={{ margin: '24px 0 0' }}
            size='small'
            title='Repetitively Pulsed Settings'>
            <Card title='Laser settings'
                size='small'>
                <Field
                    tabIndex={1}
                    name={['repetitivelyPulsed', 'wavelength']}
                    label='Wavelength'
                    unit='m'
                    min='180'
                    max='1000000'
                    denoms={["n", "u"]}
                    placeholder={`Enter laser's wavelength`}
                    toolTipText={`The laser's wavelength`}
                />
                <Field
                    tabIndex={2}
                    name={['repetitivelyPulsed', 'time']}
                    label='Exposure Duration'
                    unit='s'
                    denoms={['f', 'p', 'n', 'u', 'm', false]}
                    placeholder={`Enter exposure duration`}
                    min='0.001'
                    toolTipText={`The total time of anticipated direct eye exposure to the laser radiation.`}
                />
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                    {({ getFieldValue }) => {
                        let sourceType = getFieldValue(['sourceType'])
                        return (
                            sourceType === 'point' ? (
                                <Field
                                    tabIndex={3}
                                    name={['repetitivelyPulsed', 'beamDiameter']}
                                    label='Beam Diameter'
                                    unit='m'
                                    denoms={["m","u"]}
                                    min='0.1'
                                    placeholder={`Enter laser's beam diameter`}
                                    toolTipText={`The diameter of the source of the laser beam.`}
                                />) : null
                        )
                    }}
                </Form.Item>

                <Field
                    tabIndex={4}
                    name={['repetitivelyPulsed', 'beamDivergence']}
                    label='Beam Divergence'
                    unit='rad'
                    denoms={[false, 'm', 'u', 'degree']}
                    min='0.01'
                    placeholder={`Enter laser's beam divergence`}
                    toolTipText={`The far field plane angle of the cone defined by the beam diameter.`}
                />

                <Field
                    tabIndex={5}
                    name={['repetitivelyPulsed', 'distance']}
                    label='Distance'
                    unit='m'
                    denoms={['k', false, 'm', 'u']}
                    min='0.01'
                    placeholder={`Enter target's distance from laser`}
                    toolTipText={`The distance between the source of the laser beam and the target.`}
                />
            </Card>

            <Card
                size='small'
                title='Laser Output'
                style={{ margin: '24px 0 0' }}>
                <Form.Item
                    name={['repetitivelyPulsed', 'outputUnits']}
                    label='Laser Output Units'>
                    <Radio.Group optionType='default'>
                        <Radio.Button value='energy'>Energy</Radio.Button>
                        <Radio.Button value='peakPower'>Peak Power</Radio.Button>
                        <Radio.Button value='averagePower'>Average Power</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.repetitivelyPulsed.outputUnits !== curr.repetitivelyPulsed.outputUnits}>
                    {({ getFieldValue }) => {
                        let outputUnits = getFieldValue(['repetitivelyPulsed', 'outputUnits'])
                        return (
                            outputUnits === 'energy' ? (
                                <Field
                                    tabIndex={6}
                                    name={['repetitivelyPulsed', 'energy']}
                                    label='Pulse Energy'
                                    unit='J'
                                    denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M']}
                                    placeholder='Enter output energy'
                                    min={0.1}
                                    toolTipText={'The output of the laser expressed as Energy (J).'}
                                />
                            ) : (
                                outputUnits === 'averagePower' ? (
                                    <Field
                                        tabIndex={6}
                                        name={['repetitivelyPulsed', 'averagePower']}
                                        label='Average Power'
                                        unit='W'
                                        denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M']}
                                        min={0.1}
                                        placeholder='Enter output average power'
                                        toolTipText={`The output of the laser expressed as Average Power (W).`}
                                    />
                                ) : (
                                    outputUnits === 'peakPower' ? (
                                        <Field
                                            tabIndex={6}
                                            name={['repetitivelyPulsed', 'peakPower']}
                                            label='Peak Power'
                                            unit='W'
                                            denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M']}
                                            placeholder='Enter peak power'
                                            min={0.1}
                                            toolTipText={`The output of the laser expressed as Peak Power (W).`}
                                        />
                                    ) : null
                                )
                            )
                        )

                    }}
                </Form.Item>
                {/* <Field
                    name={['repetitivelyPulsed', 'pulseDuration']}
                    label='Pulse Duration'
                    unit='s'
                    denoms={['f', 'p', 'n', 'u', 'm', false]}
                    placeholder='Time must be between 0 ~ 0.25s'
                    min='0.001'
                    max={0.25}
                    toolTipText={`The time it takes for a whole pulse of laser radiation to be delivered`}
                    isSpecial={true}
                /> */}

                <Tooltip
                    mouseEnterDelay={0.5}
                    title={`The time it takes for a whole pulse of laser radiation to be delivered.`}
                    placement='right'
                    destroyTooltipOnHide
                >
                    <Form.Item
                        label='Pulse Duration'
                        name={['repetitivelyPulsed', 'pulseDuration', 'num']}
                        rules={[
                            { required: true, message: 'Please specify value.' },
                            
                       
                        ]}
                    >
                        <InputNumber
                            tabIndex={7}
                            placeholder={customPlaceholder}
                            // max={customMin}
                            style={{ width: '100%' }}
                            addonAfter={
                                <>
                                    <Form.Item noStyle
                                        name={['repetitivelyPulsed', 'pulseDuration', 'unit']}
                                        rules={[
                                            { required: true, message: 'Please specify unit.' },
                                            () => ({
                                                validator(_, value) {
                                                    let pulseFrequency = form.getFieldsValue()?.repetitivelyPulsed?.pulseFrequency
                                                    let pulseDuration = form.getFieldsValue()?.repetitivelyPulsed?.pulseDuration
                                                    let ratio = decimalForm(pulseFrequency) * decimalForm(pulseDuration)
                                                    if (decimalForm(pulseDuration) >= 0.25) {
                                                        return Promise.reject(<>The pulse duration is greater than or equal to 0.25 s - This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'cw') }}>Click here</NavLink> to use the CW calculator instead.</>)
                                                    } else {
                                                        if (ratio < 1) {
                                                            return Promise.resolve()
                                                        }
                                                        else if (ratio === 1) {
                                                            return Promise.reject(<>This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'cw') }}>Click here</NavLink> to calculate CW instead.</>)
                                                        } else if (ratio > 1) {
                                                            return Promise.reject('This is an invalid setup! Pulse frequency * Pulse duration should not be more than 1!')
                                                        }
                                                    }

                                                }
                                            })
                                        ]}>
                                        <Select
                                            onChange={handleUnitChange}
                                            style={{ width: 80 }}
                                            options={(() => {
                                                let units = ['f', 'p', 'n', 'u', 'm', false]
                                                return units.map((unit, index) => {
                                                    if (unit === false) {
                                                        return (
                                                            {
                                                                label: 's',
                                                                value: false
                                                            }
                                                        )
                                                    } else if (unit === 'u') {
                                                        return (
                                                            {
                                                                label: 'µs',
                                                                value: unit
                                                            }
                                                        )
                                                    } else {
                                                        return (
                                                            {
                                                                label: unit + 's',
                                                                value: unit
                                                            }
                                                        )
                                                    }
                                                })
                                            })()}
                                        />
                                    </Form.Item>
                                </>
                            } />
                    </Form.Item>
                </Tooltip>

                <Field
                    tabIndex={8}
                    name={['repetitivelyPulsed', 'pulseFrequency']}
                    label='Pulse Frequency'
                    unit='Hz'
                    denoms={['u', 'm', false, 'k', 'M']}
                    placeholder={`Enter pulse frequency`}
                    min='0.001'
                    toolTipText={`The rate at which laser pulses are delivered within a given time period.`}
                />

            </Card>
        </Card>
    )
}

export default RepetitivelyPulsedCalculator