import { Card, Flex, Button, Row, Form, Col, Typography } from "antd"

export function FreeTrialCard(props) {
    return (
    <Col xs={24} sm={12} md={8} lg={6} style={{
      display: props.freeTrialActivated ? "none" : null
    }}>
      <div class="creditBox creditBox1">
        <Flex justify={"center"} style={{
          height: "6vh",
          background: "linear-gradient(135deg, #444789 2%, #1d1f8c 19%, #1d1f8c 84%, #444789 98%)",
          marginBottom: "20%",
          whiteSpace: "normal"
        }}>
          {
            /*Title**/
          }
          {
            /* <div style={{position: "absolute", width:"100%", height:"20%", backgroundColor: "red"}}></div> */
          }
          <div style={{
            textAlign: "center",
            alignSelf: "center"
          }}>
            <Typography><h1 style={{
              color: "white",
              fontSize: "25px"
            }}>Activate Free Trial </h1></Typography>
          </div>
        </Flex>
        <Flex justify="center" align="center" style={{
          height: "12vh"
        }}>
          <div style={{
            alignSelf: "center",
            padding: "10px",
            border: "2px solid #1d1f8c",
            borderRadius: "10px",
            width: "60%",
            height: "100%",
            justifyContent: "center",
            display: "grid"
          }}>
            <Row justify="center" align="center">
              <h1 style={{
                fontSize: "2rem",
                color: "#1d1f8c"
              }}>30 days</h1>
            </Row>
            <Row style={{
              justifySelf: "center"
            }}>
              <Typography style={{
                marginTop: "-10px",
                justifyContent: "center",
                textAlign: "center"
              }}><p>Free Trial</p> </Typography>
            </Row>
          </div>
        </Flex>
        <Flex justify={"center"} align="end" style={{
          flexGrow: "1"
        }}>
          {
            /* Add a hidden field with the lookup_key of your Price */
          }
          <input type="hidden" name="lookup_key" />
          <Button type="primary" 
            disabled={props.disableButton} 
            loading={props.spinner1} 
            onClick={() => props.handleFreeTrial("00")} 
            style={{
              ...props.buttonStyle,
              backgroundColor: "#1d1f8c"
          }}>Activate Now</Button>
        </Flex>
      </div>
  
    </Col>);
  }