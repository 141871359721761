import { Card, Row, Typography, Flex } from "antd";
import { FooterHandler } from "./Footer";
import "../../pages/home.css"

export function PrivacyPolicy() {
    return(
    <>
    <Row style={{width: "100%", height:"auto", display:"flex", justifyContent:"center", marginTop:"60px", marginBottom:"20px"}}>
        <Row className="content">
            <Card bordered={false} style={{width: "100%"}}>
                <Flex justify="center">
                    <Typography.Title>Privacy Policy(draft)</Typography.Title>
                </Flex>
                {/* Last Updated: [Date] */}

                <Typography.Paragraph>
                    Lasermet Ltd. operates the RealTime LSS calculator and takes your privacy seriously. This Privacy Policy explains what personal information we collect, 
                    how we use it, how we protect it, and your rights regarding that information.               
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                    1. Information We Collect
                </Typography.Text>
                    <Typography> We collect the following personal data to provide and improve our services:</Typography>
                    <Typography>Name: Used to personalize your experience and account.</Typography>
                    <Typography>Email: For account registration and secure access.</Typography>
                    <Typography> Password: Stored in encrypted form for account security.</Typography>
                    <Typography> Geolocation Data: We use IPinfo.io to collect general location information, including your IP address, for the sole purpose of determining your currency. This geolocation information is stored in a cookie.</Typography>
                    <Typography> We do not collect any other personal data, and we do not send marketing emails or newsletters.</Typography>
                  
                <Typography.Text strong={true}>
                    2. Use of Cookies and JWT Web Tokens
                </Typography.Text>
                <Typography>-Cookies are small text files placed on your device to store certain information about your interactions with the App. We use cookies solely for the following purposes:</Typography>
                <Typography>-Authentication: We store JWT web tokens in cookies for secure login sessions. This ensures that users remain logged in securely while using the platform.</Typography>
                <Typography>-Session Management: Cookies help store user preferences and settings to enhance your experience.</Typography>
                <Typography>-Currency Determination: Geolocation data is used to determine the appropriate currency for transactions. If geolocation services are disabled or unavailable, a default currency (US dollars) will be used. Users may not be able to manually select a different currency. We do not store or use this data for any other purposes.</Typography>
                <Typography>We do not use cookies for advertising or tracking purposes. No third-party tracking tools, such as Google Analytics or Facebook Pixel, are implemented on our platform.</Typography>
                <Typography> 
                    You can manage or disable cookies by adjusting your browser settings. 
                    However, please note that disabling cookies may affect your ability to use certain features of the App, particularly those related to session authentication.
                </Typography>
                
                <Typography.Text strong={true}>
                3. How We Use Your Information
                </Typography.Text>
                <Typography>The personal information we collect is used for:</Typography>
                
                <Typography>-Creating and maintaining your user account.</Typography>
                <Typography>-Authenticating user sessions securely.</Typography>
                <Typography>-Enabling geolocation features for currency determination.</Typography>
                <Typography>-Processing payments securely through Stripe. </Typography>
                <Typography>-We do not sell, rent, or share your personal information with third parties for marketing purposes.</Typography>
                
              
                <Typography.Text strong={true}>
                4. Payments
                </Typography.Text>
                <Typography.Paragraph>
                We process payments via Stripe, a secure third-party payment gateway. 
                Your payment details are handled directly by Stripe, and we do not store any payment information on our servers. 
                Please review Stripe’s privacy policy for more details on their practices.
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                5. Data Protection and Security
                </Typography.Text>
                <Typography.Paragraph>
                We are committed to ensuring that your information is secure. 
                We have implemented appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or misuse. 
                Passwords are stored in encrypted form, and JWT tokens are securely managed.
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                6. Data Collection from Minors
                </Typography.Text>
                <Typography.Paragraph>
                RealTime LSS is available to users under the age of 13 in compliance with the Children’s Online Privacy Protection Act (COPPA). 
                We do not knowingly collect any more personal data than is necessary to provide services for children.
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                7. Legal Compliance
                </Typography.Text>
                <Typography.Paragraph>
                California Online Privacy Protection Act (CalOPPA): We allow users to visit our site anonymously and update our privacy policy whenever necessary.
                General Data Protection Regulation (GDPR): Users in the EU have the right to access, modify, or delete their personal information. You can contact us at office@lasermet.com to exercise these rights.
                California Consumer Privacy Act (CCPA): California residents have the right to request information on the personal data we collect and request its deletion. We do not sell personal data.
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                8. Your Rights and Choices
                </Typography.Text>
                <Typography> You have the following rights regarding your personal data:</Typography>
                <Typography>Access: You can request a copy of the personal data we hold about you.</Typography>
                <Typography>Correction: You can ask us to correct inaccurate information.</Typography>
                <Typography> Deletion: You can request the deletion of your personal data, subject to legal requirements.</Typography>
                <Typography>Opt-Out: You may choose to opt-out of certain data collection practices by disabling cookies in your browser settings.</Typography>
              
                <Typography.Text strong={true}>
                9. Changes to This Privacy Policy
                </Typography.Text>
                <Typography.Paragraph>
                We may update this Privacy Policy from time to time. Any changes will be posted on our website with a revised "Effective Date." We encourage you to review this policy periodically.
                </Typography.Paragraph>
                <Typography.Text strong={true}>
                10. Links to Other Sites
                </Typography.Text>
                <Typography.Paragraph>
                Links to other sites in this web application direct you to our official Lasermet website. However, our social media links lead to our profiles on those respective platforms.

                This Privacy Policy does not apply to those third-party services, and we are not responsible for their privacy practices. We recommend reviewing their privacy policies before providing any personal information.
                </Typography.Paragraph>

                <Typography>
                <strong>11. Contact Us</strong>
                </Typography>
                <Typography>If you have any questions or concerns about this Privacy Policy, or if you wish to exercise your rights regarding your personal data,</Typography>
                <Typography> please contact us at:</Typography>
                <Typography> Email: office@lasermet.com</Typography>
                <Typography>Phone: +44 1202 770 740</Typography>
                <Typography>Website: www.lasermet.com</Typography>
                <Typography>Twitter: @LasermetLtd</Typography>
                <Typography>LinkedIn: Lasermet Ltd on LinkedIn</Typography>

                
             
            </Card>
        </Row>
    </Row>   
    <FooterHandler />
    </>
    )
    
}