import { Button, Form, Modal, Input, Typography } from 'antd';
import React from 'react';
const { TextArea } = Input;

export function ReportModal(props) {
  return (<Modal title="Report a Bug" onCancel={props.handleCancel} open={props.isModalOpen} footer={[<Button key="back" onClick={props.handleCancel}>
    Cancel
  </Button>, <Button loading={props.loading} htmlType="submit" type='primary' onClick={() => {
    props.form3.submit();
  }}>
    Submit Report
  </Button>]}>
    <Form form={props.form3} onFinish={props.handleOk}>
      <Form.Item label="Description" name="description" rules={[{
        required: true,
       
         }]}
        help={
            <Typography.Text type='secondary' italic>Describe the problem you have encountered. Please be as specific as possible.</Typography.Text>
        }>
        <TextArea placeholder="Please input description" style={{
          height: 120,
          width: 350,
          resize: 'none',
        }} />
      </Form.Item>
    </Form>
  </Modal>);
}
