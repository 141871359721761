import React, { createContext } from 'react'

const EnvironmentContext = createContext()

const EnvironmentProvider = ({ isLocal, children }) => {

    return (
        <EnvironmentContext.Provider
            value={{ isLocal }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
}

export { EnvironmentContext, EnvironmentProvider }