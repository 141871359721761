import { useState, useEffect } from 'react';
import axios from 'axios';

const FetchCalculations = (isLocal, myUser, numberOfCalcs2) => {

  const [isDisabled, setIsDisabled] = useState(null)
  const [numberOfCalcs, setNumberOfCalcs] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(null)
  const [isFreeTrial, setIsFreeTrial] = useState(null)
  const [freeTrialActivated, setFreeTrialActivated] = useState(false)
  const [isPreAuthorized, setIsPreAuthorized] = useState(false)
  useEffect(() => {

    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/fetchCalculations'
        } else {
          return 'api/fetchCalculations'
        }
      })(),
      data: { myUser },
      withCredentials: true,
    })
      .then((response) => {
        setNumberOfCalcs(parseInt(response.data[0].numberofcalculations))
        setIsDisabled(response.data[0].isdisabled)
        setIsSubscribed(response.data[0].issubscribed)
        setIsFreeTrial(response.data[0].isfreetrial)
        setFreeTrialActivated(response.data[0].freeTrialActivated)
        setIsPreAuthorized(response.data[0].isPreAuthorized)

      })
      .catch((err) => {
        console.error("fetch calc error", err)
      })
  }, [numberOfCalcs2, isLocal, myUser,])
  return { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial, freeTrialActivated, isPreAuthorized }
}

export default FetchCalculations