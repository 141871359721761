import { NavLink, Route, Routes} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Modal, Button, Icon, Typography, Row } from 'antd';
import { WarningFilled, ShoppingCartOutlined   } from '@ant-design/icons';
import React from 'react';

export function CalculationStatusModal(props) {
  const handleClick = 'billing'; 
  const defKey = "4"
  return (
    <Modal
     title={
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", justify:"center"}}>
         
       
         <WarningFilled style={{display:"flex", justifyContent:"center", color: "orange", fontSize: "6rem" }} />

{/*         
        <div style={{display:"flex"}}>
              <Row style={{display:"block"}}><h4>Ooops!</h4></Row>
          </div> */}
        </div>
     }
      open={props.openBuy}
      onCancel={props.handleCancelCheckStatus}
      okButtonProps={props.handleCancelCheckStatus}
      footer={[
        <NavLink to={`/home?defKey=${defKey}&handleClick=${handleClick}`}>
          <Button key='ok' icon={<ShoppingCartOutlined />} style={{ backgroundColor: "#1d1f8c", color: "white"}}> BUY NOW</Button>
        </NavLink>
      ]} >
      <Row style={{ justifyContent: "center", textAlign: "center" }}><Typography><h1>Oops! :(</h1></Typography></Row>
      
      <Typography style={{ justifyContent: "center", textAlign: "center", marginBottom:"20px" }}>It looks like you've run out of calculations.<span style={{display: 'block'}}>Please buy more calculations to continue.</span> </Typography>
      
    </Modal>
  );
}
