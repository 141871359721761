import { Card, Col, Row, Space, Typography, Tabs, Divider, Tooltip, Radio, Flex } from "antd";
import React, { useState } from "react";
import ResultCard from "../../../../../components/ResultCard"
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import { LaserClassResultsV2 } from "./LaserClassResultsV2";
import LaserClassVerdict from "./LaserClassVerdict";
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import { Infograph } from "../../../../../components/infographicsModal/Infograph"
import { EyewearResults } from "../../ResultComponents/EyewearResults";

export function ResultSinglePulse(props) {
  const textCenter = props.textCenter

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [info, setInfo] = useState(null)

  const handleClick = (e) => {
    setIsModalOpen(true)
    setInfo(e)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Tabs defaultActiveKey="1" items={[{
      key: 1,
      label: "MPE Details",
      children: <>
        <Infograph
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          info={info}
        />
        <Card style={{marginBottom: "20px"}}>
          <Divider> <h4><img src={eye} width={50} style={{marginRight:"5px"}}/><strong>EYE</strong></h4></Divider>
          <Row justify={"center"}>
            <ResultCard>
              <Row justify={"center"}>
                <strong>Single Pulse MPE</strong>
              </Row>
              <Row justify={"center"}>
                <Space direction="vertical">
                  <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the eye may be exposed without suffering adverse effects."}>
                    <Typography style={textCenter}>
                      {(() => {
                        if (props.units === 'joules') {
                          return props.result.MPEsingle.joulesPerSqmPretty.eng + 'J/m²';
                        } else if (props.units === 'watts') {
                          return props.result.MPEsingle.wattsPerSqmPretty.eng + 'W/m²';
                        }
                      })()}
                    </Typography>
                  </Tooltip>
                </Space>
              </Row>
            </ResultCard>




            <ResultCard>
              <strong style={textCenter}>
                Accessible Emission
              </strong>
              <Space>
                <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                  <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                  <strong>
                    <Typography style={textCenter}>
                      {(() => {
                        if (props.units === 'joules') {
                          return props.result.AE.joulesPerSqmPretty;
                        } else if (props.units === 'watts') {
                          return props.result.AE.wattsPerSqmPretty;
                        }
                      })()}

                    </Typography>
                  </strong>
                </Tooltip>
                <Divider type="vertical" style={{ height: "40px" }}></Divider>
                <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                  <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                  <strong>
                    <Typography style={textCenter}>
                      {(() => {
                        if (props.units === 'joules') {
                          return props.result.AE.actual_joulesPerSqmPretty;
                        } else if (props.units === 'watts') {
                          return props.result.AE.actual_wattsPerSqmPretty;
                        }
                      })()}
                    </Typography>
                  </strong></Tooltip>
              </Space>
            </ResultCard>

            <ResultCard>
              <strong style={textCenter}>
                Eye Safety Ratio
              </strong>
              <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                <strong style={props.result.MPEexcess.joules > 1 ? {
                  ...textCenter,
                  color: "#d82424"
                } : props.result.MPEexcess.joules <= 1 && props.result.MPEexcess.joules >= 0.5 ? {
                  ...textCenter,
                  color: "#ffa200"
                } : props.result.MPEexcess.joules < 0.5 ? {
                  ...textCenter,
                  color: "#10c96d"
                } : null}>
                  {props.result.MPEexcess.joulesPretty.pretty}
                </strong>
              </Tooltip>
            </ResultCard>
          </Row>
          <Card style={{
            backgroundColor: (() => {
              if (props.result.MPEexcess.joules > 1) {
                return "#d82424"
              }
              else if (props.result.MPEexcess.joules <= 0.5) {
                return "#10c96d"
              }
              else if (props.result.MPEexcess.joules > 0.5 || props.result.MPEexcess.joules <= 1) {
                return "#ffa200"
              }
            })()
          }}>
            <Typography style={textCenter}>
              <strong style={{ color: "#ffffff" }}>
                {(() => {

                  if (props.result.MPEexcess.joules > 1) {
                    return "The configuration that has been entered is Dangerous for the Eye! "
                  }
                  else if (props.result.MPEexcess.joules <= 0.5) {
                    return "The configuration that has been entered is Safe for the Eye! "
                  }
                  else if (props.result.MPEexcess.joules > 0.5 || props.result.MPEexcess.joules <= 1) {
                    return "The configuration that has been entered is Marginally Safe for the Eye! "
                  }
                })()}
                {props.result.MPEexcess.joulesPretty.pretty} x MPE
              </strong>
            </Typography>
          </Card>
        </Card>
        <Card style={{marginBottom: "20px"}}>        
          <Divider><h4><img src={hand} width={50} style={{marginRight:"5px"}}/><strong> SKIN </strong> </h4></Divider>
          <Row justify={"center"}>
            <ResultCard>
              <strong style={textCenter}>
                Skin MPE
              </strong>
              <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the skin may be exposed without suffering adverse effects."}>
                <Typography style={textCenter}>
                  {(() => {
                    if (props.units === 'joules') {
                      return props.result.skinMPE.joulesPerSqmPretty.eng + 'J/m²';
                    } else if (props.units === 'watts') {
                      return props.result.skinMPE.wattsPerSqmPretty.eng + 'W/m²';
                    }
                  })()}
                </Typography>
              </Tooltip>
            </ResultCard>
            <ResultCard>
              <strong style={textCenter}>
                Skin AE
              </strong>

              <Space>
                <Typography style={textCenter}>
                  <Tooltip title={"The value of the Radiant Exposure to the taking into account the applicable beam area"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.joulesPerSqmPretty.eng + 'J/m²';
                          } else if (props.units === 'watts') {
                            return props.result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.actual_joulesPerSqmPretty.eng + 'J/m²';
                          } else if (props.units === 'watts') {
                            return props.result.skinAE.actual_wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}

                      </Typography>
                    </strong>
                  </Tooltip>
                </Typography>
              </Space>
            </ResultCard>

            <ResultCard>
              <strong style={textCenter}>
                Skin Safety Ratio
              </strong>
              <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                <strong style={props.result.skinMPEExcess.joules > 1 ? {
                  ...textCenter,
                  color: "#d82424"
                } : props.result.skinMPEExcess.joules <= 1 && props.result.skinMPEExcess.joules >= 0.5 ? {
                  ...textCenter,
                  color: "#ffa200"
                } : props.result.skinMPEExcess.joules < 0.5 ? {
                  ...textCenter,
                  color: "#10c96d"
                } : null}>
                  {props.result.skinMPEExcess.joulesPretty.pretty}
                </strong>
              </Tooltip>
            </ResultCard>
          </Row>
          <Card style={{
            backgroundColor: (() => {
              if (props.result.skinMPEExcess.joules > 1) {
                return "#d82424"
              }
              else if (props.result.skinMPEExcess.joules <= 0.5) {
                return "#10c96d"
              }
              else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                return "#ffa200"
              }
            })()
          }}>
            <Typography style={textCenter}>
              <strong style={{ color: "#ffffff" }}>
                {(() => {

                  if (props.result.skinMPEExcess.joules > 1) {
                    return "The configuration that has been entered is Dangerous for the Skin! "
                  }
                  else if (props.result.skinMPEExcess.joules <= 0.5) {
                    return "The configuration that has been entered is Safe for the Skin! "
                  }
                  else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                    return "The configuration that has been entered is Marginally Safe for the Skin! "
                  }
                })()}
                {props.result.skinMPEExcess.joulesPretty.pretty} x MPE
              </strong>
            </Typography>
          </Card>
        </Card>
        <Card>
        <Divider orientation="center">
          <h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4>
        </Divider>
        <Flex justify="center" gap="middle" wrap="wrap">
          <ResultCard>
            <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
            <strong style={textCenter}>
              N.O.H.D.
            </strong>
            <Tooltip title={"Distance from the output aperture beyond which the beam irradiance or radiant exposure remains below the appropriate corneal maximum permissible exposure."}>
              <Typography style={textCenter}>
                {props.result.NOHD.eng}m
              </Typography>
              </Tooltip>
            </div>  
          </ResultCard>

          <ResultCard>
            <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
            <strong style={textCenter}>
              E.N.O.H.D
            </strong>
            <Tooltip title={props.result.ENOHD !== 0 ? "The distance from a laser over which the MPE for the eye may be exceeded, taking into account the possible use of magnifying instruments like telescopes and binoculars." : "ENOHD only applies to wavelength between 302.5nm ~ 4000nm"}>
              <Typography style={textCenter}>
                {props.result.ENOHD !== 0 ? props.result.ENOHD.pretty : 'N/A'}
              </Typography>
            </Tooltip>
            </div>
          </ResultCard>


          <ResultCard>
          <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
            <Space direction="vertical" style={{
              height: "100%"
            }}>
              <strong style={textCenter}>
                Spot Size
              </strong>
              <Tooltip title={"The diameter of the beam at the target distance."}>
                <Typography style={textCenter}>
                  {props.result.outputBeamDiameter.outputBeamDiameterPretty}
                </Typography>
              </Tooltip>
            </Space>
          </div>
          </ResultCard>
          <ResultCard>
            <div onClick={()=>{handleClick("limit aperture")}} style={{cursor:"pointer"}}> 
            <strong style={textCenter}>
              Limit Aperture
            </strong>
            <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
              <Typography style={textCenter}>
                Eye: {props.result.limitingAperture.eye} mm
              </Typography>
              <Typography style={textCenter}>
                Skin: {props.result.limitingAperture.skin} mm
              </Typography>
            </Tooltip>
            </div>
          </ResultCard>
          <ResultCard>
            <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}> 
            <Tooltip title={'Area of the spot size or limit aperture - whichever is the largest.'}>
              <strong style={textCenter}>
                Applicable Beam Area
              </strong>
              <Typography style={textCenter}>
                Eye: {props.result.applicableBeamArea.applicableBeamAreaPrettiest}
              </Typography>

              <Typography style={textCenter}>
                Skin: {props.result.skinApplicableArea.skinApplicableAreaPrettiest}
              </Typography>
            </Tooltip>
            </div>
          </ResultCard>
          <ResultCard >
            <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}>
            <strong style={textCenter}>
              Spectrum
            </strong>
            <Row justify={"center"}>
              <div style={{
                width: "30px",
                height: "30px",
                backgroundColor: (() => {
                  if (props.result.spectrum.range == "Infrared") {
                    return "maroon"
                  }
                  else if (props.result.spectrum.range == "Ultraviolet") {
                    return "purple"
                  }
                  return "rgb(" + props.result.spectrum.rgb + ")"
                })(),
                borderRadius: 100,
                ustifyContent: "center"
              }}></div>
            </Row>
            <Row justify={"center"}>{props.result.spectrum.region}</Row>
            </div>
          </ResultCard>
        </Flex>
      </Card>     
      </>
    }, {
      key: 2,
      label: "More Information",
      children: <Row justify="center" align="center">
        <Divider orientation="center">
          Correction Factors & Time Bases
        </Divider>
        {props.result.correctionFactors.map(cf => {
          if (cf.val !== null) {
            return <ResultCard>
              <strong style={textCenter}>
                {cf.name}
              </strong>
              <Typography style={textCenter}>
                {cf.pretty}
              </Typography>
            </ResultCard>;
          }
        })}
      </Row>
    }, {
      key: 3,
      label: "Laser Class Details",
      children:
        <>
          <Row justify="space-evenly">
            <Divider orientation="center">
              Accessible Emission Limits
            </Divider>
            {/* <LaserClassResults laserClass={props.result.laserClass} /> */}
            <LaserClassResultsV2 laserClass={props.result.laserClass} condition={1} />
            <LaserClassResultsV2 laserClass={props.result.laserClass} condition={3} />
          </Row>
          <LaserClassVerdict laserClass={props.result.laserClass} />
        {/** <Tabs defaultActiveKey="1" centered items={[
          {
            key: 1,
            label: "Overall",
            children: <>
            </>
          }, {
            key: 2,
            label: "Time Base",
            children: <><Row justify="space-evenly">
              {props.result.AELresults.map(result => <Card style={{
                margin: 5
              }} block title={`Class ${result.laserClass}`}>
                <Row justify="space-evenly">
                  <Space direction="vertical">
                    {result.AELT.joulesPretty !== null && result.AELT.wattsPretty ? <>
                      <p style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        {(() => {
                          if (props.units === 'joules') {
                            return result.AELT.joulesPretty
                          } else if (props.units === 'watts') {
                            return result.AELT.wattsPretty
                          }
                        }
                        )()}
                      </p>
                    </> : <>
                      <Typography style={textCenter}>
                        N/A
                      </Typography>
                    </>}
                  </Space>
                </Row>
              </Card>)}
            </Row>
              <Row style={{
                justifyContent: "start"
              }}><p style={{
                fontSize: "12px",
                color: "#666666"
              }}>*Note: These results are based on the time base found in 4.3e of IEC60825 standard. </p></Row></>
          }, {
            key: 3,
            label: "Custom Time Base",
            children: <><Row justify="space-evenly">
              {props.result.AELresults.map(result => <Card style={{
                margin: 5
              }} block title={`Class ${result.laserClass}`}>
                <Row justify="space-evenly">
                  <Space direction="vertical">
                    {result.AELT.joulesPretty !== null && result.AELTcustom.wattsPretty !== null ? <>
                      <p style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        {(() => {
                          if (props.units === 'joules') {
                            return result.AELTcustom.joulesPretty
                          } else if (props.units === 'watts') {
                            return result.AELTcustom.wattsPretty
                          }
                        }
                        )()}
                      </p>
                    </> : <>
                      <Typography style={textCenter}>
                        N/A
                      </Typography>
                    </>}
                  </Space>
                </Row>
              </Card>)}
            </Row>
              <Row style={{
                justifyContent: "start"
              }}><p style={{
                fontSize: "12px",
                color: "#666666"
              }}>*Note: These results are based on user input exposure duration. </p></Row></>
          }
        ]} /> */}
        </>
    }, {
      key: 4,
      label: "Laser Protective Eyewear",
      children: <>
      <EyewearResults props={props} />
      <Row justify={'center'}>
        <Eyewear />
      </Row></>
    }]} />);
}
