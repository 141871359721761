
import './loader.css'
const Loader = () => {
    
return(
    // <><div class="loader">
    //     <div class="circle multiply">x</div>
    //     <div class="circle divide">/</div>
    //     <div class="circle add">+</div>
    //     <div class="circle subtract">-</div>
    // </div>
        <div class="calculator">
           
            <div class="circle2 multiply2">×</div>
            <div class="circle2 divide2">÷</div>
            <div class="circle2 add2">+</div>
            <div class="circle2 subtract2">-</div>
            {/* <div><p class="reveal">Calculating...</p></div> */}
        </div>
)
}
export default Loader