import { Card, Row, Typography, Flex } from "antd";
import { FooterHandler } from "./Footer";
import { PhoneOutlined, MailOutlined, MailFilled } from "@ant-design/icons";

export function Contacts() {
    return(
    <>
    <Row gap="middle" justify="center" vertical style={{marginTop:"90px", width:"100%", height:"100vh"}}>
        <Card style={{width: "40%", maxHeight: "30vh"}} >
            <Flex justify="center" style={{alignContent:"center"}}>
                <Typography.Title>
                   Contacts
                </Typography.Title>
            </Flex>
            <Flex justify="space-around" align="center" style={{alignContent:"center", height: "15vh"}}>
                <Flex vertical>
                    <Typography><PhoneOutlined /> +44 1202 770 740   </Typography>
                    <Typography> <MailOutlined /> office@lasermet.com </Typography>
                </Flex>
                <Flex  vertical>
                    <Typography>  <PhoneOutlined />+49 211 424 715 588  </Typography>
                    <Typography> <MailOutlined /> europe@lasermet.com </Typography>
                </Flex>
                <Flex  vertical>
                    <Typography><PhoneOutlined />+1 847 466 1475  </Typography>
                    <Typography>  <MailOutlined /> usa@lasermet.com  </Typography>
                </Flex>
           </Flex>
        </Card>
 
    </Row>
       
      
  
    <FooterHandler />
    </>
    )
    
}