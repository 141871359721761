import { Col, Row, Typography, Divider, Flex, Table, Collapse } from "antd";
import React from "react";
import { Classification } from "../components/Classification";
const { Text } = Typography

export function LaserClassResults(props) {
  const textCenter = { justifyContent: 'center', display: 'flex  ' }
  const textEnd = { justifyContent: 'center', display: 'flex' }
  const resultStyle = { borderRadius: '5px', margin: '2px 0 2px', backgroundColor: '#424769', color: '#F6B17A' }
  const { condition1, condition3, laserOperation, Class, timeBase, conditionIsEmpty, CE3isPersqm } = props.laserClass

  var spanBlock = {
    display: "block"
  }
  return (
    <>
      Computation Timebase = {timeBase}
      <div style={{
        width: '900px',
        margin: '12px 0 12px',
        backgroundColor: '#2D3250',
        padding: '15px',
        borderRadius: '5px'
      }}>
        {
          /* Condition 1 */
        }
        <Row style={{
          backgroundColor: 'black',
          color: 'white',
          ...resultStyle
        }}>

          <Col flex="50px"></Col>
          <Col flex={laserOperation === "RP" ? "355px" : "642px"} style={{ fontSize: "20px" }}><strong>CONDITION 1</strong></Col>
          {laserOperation === "RP" ? (
            <>
              <Col flex={laserOperation === "RP" ? "110px" : "200px"}>Single Pulse</Col>
              <Col flex="112px">Pulse Train</Col>
              <Col flex="112px">Average</Col>
            </>
          ) : null}
          <Col flex={laserOperation === "RP" ? "85px" : "145px"}> Excess</Col>
          <Col> P/F</Col>
        </Row>
        <Row gutter={[8, 8]}>

          {
            /* Column for AEL and Collected Emission */
          }
          <Col span={17}>
            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 1</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition1.AEL_standard.single.c1.watts < condition1.collectedEmissionWatts.watts ? { color: 'red', ...textCenter }
                  : condition1.AEL_standard.single.c1.watts >= condition1.collectedEmissionWatts.watts ? {
                    color: 'green', ...textCenter
                  }
                    : null}>
                  <strong style={{ backgroundColor: condition1.AEL_standard.single.c1.joules === condition1.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (conditionIsEmpty === false && laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      return condition1.AEL_standard.single.c1.wattsPretty;
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" && conditionIsEmpty === false) {
                      return condition1.AEL_standard.single.c1.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" && conditionIsEmpty === false ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition1.AEL_standard.pulseTrain.c1.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.pulseTrain.c1.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.pulseTrain.c1.raw === condition1.mostRestrictiveAEL.class1 && condition1.AEL_standard.single.c1.joules !== condition1.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition1.AEL_standard.pulseTrain.c1.raw !== 0 ? condition1.AEL_standard.pulseTrain.c1.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition1.AEL_standard.Average.c1.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.Average.c1.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.Average.c1.raw === condition1.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition1.AEL_standard.Average.c1.raw !== 0 ? condition1.AEL_standard.Average.c1.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>
                {typeof condition1.AEL_standard.Excess.c1excess.raw === "number" ? (
                  <Typography style={condition1.AEL_standard.Excess.c1excess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition1.AEL_standard.Excess.c1excess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition1.AEL_standard.Excess.c1excess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition1.AEL_standard.Excess.c1excess.raw}
                  </Typography>
                )}
              </Col>
              <Col flex="auto">
                {(() => {
                  if (condition1.AEL_standard.Excess.c1excess.raw == undefined) {
                    return null
                  }
                  else if (condition1.AEL_standard.Excess.c1excess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition1.AEL_standard.Excess.c1excess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 2</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition1.AEL_standard.single.c2.watts < condition1.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition1.AEL_standard.single.c2.watts >= condition1.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : null}>
                  <strong style={{ backgroundColor: condition1.AEL_standard.single.c2.joules === condition1.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (conditionIsEmpty === false && laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      if (condition1.AEL_standard.single.c2.wattsPretty !== "NullW") {
                        return condition1.AEL_standard.single.c2.wattsPretty;
                      } else {
                        return condition1.AEL_standard.single.c2.wattsPerSqmPretty;
                      }
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" && conditionIsEmpty === false) {
                      return condition1.AEL_standard.single.c2.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" && conditionIsEmpty === false ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition1.AEL_standard.pulseTrain.c2.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.pulseTrain.c2.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.pulseTrain.c2.raw === condition1.mostRestrictiveAEL.class2 && condition1.AEL_standard.single.c2.joules !== condition1.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition1.AEL_standard.pulseTrain.c2.raw !== 0 ? condition1.AEL_standard.pulseTrain.c2.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition1.AEL_standard.Average.c2.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.Average.c2.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.Average.c2.raw === condition1.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>{condition1.AEL_standard.Average.c2.raw !== 0 ? condition1.AEL_standard.Average.c2.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>
                {typeof condition1.AEL_standard.Excess.c2excess.raw === "number" ? (
                  <Typography
                    style={condition1.AEL_standard.Excess.c2excess.raw > 1 ? {
                      color: 'red',
                      ...textCenter
                    } : condition1.AEL_standard.Excess.c2excess.raw <= 1 ? {
                      color: 'green',
                      ...textCenter
                    } : null}>
                    <strong>{condition1.AEL_standard.Excess.c2excess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition1.AEL_standard.Excess.c2excess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition1.AEL_standard.Excess.c2excess.raw == undefined) {
                    return null
                  }
                  else if (condition1.AEL_standard.Excess.c2excess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition1.AEL_standard.Excess.c2excess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 3R</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition1.AEL_standard.single.c3r.watts < condition1.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition1.AEL_standard.single.c3r.watts >= condition1.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : null}>
                  <strong style={{ backgroundColor: condition1.AEL_standard.single.c3r.joules === condition1.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (conditionIsEmpty === false && laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      return condition1.AEL_standard.single.c3r.wattsPretty;
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" && conditionIsEmpty === false) {
                      return condition1.AEL_standard.single.c3r.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" && conditionIsEmpty === false ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition1.AEL_standard.pulseTrain.c3r.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.pulseTrain.c3r.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.pulseTrain.c3r.raw === condition1.mostRestrictiveAEL.class3R && condition1.AEL_standard.single.c3r.joules !== condition1.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition1.AEL_standard.pulseTrain.c3r.raw !== 0 ? condition1.AEL_standard.pulseTrain.c3r.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition1.AEL_standard.Average.c3r.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.Average.c3r.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.Average.c3r.raw === condition1.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>{condition1.AEL_standard.Average.c3r.raw !== 0 ? condition1.AEL_standard.Average.c3r.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>
                {typeof condition1.AEL_standard.Excess.c3rexcess.raw === "number" ? (
                  <Typography style={condition1.AEL_standard.Excess.c3rexcess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition1.AEL_standard.Excess.c3rexcess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition1.AEL_standard.Excess.c3rexcess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition1.AEL_standard.Excess.c3rexcess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition1.AEL_standard.Excess.c3rexcess.raw == undefined) {
                    return null
                  }
                  else if (condition1.AEL_standard.Excess.c3rexcess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition1.AEL_standard.Excess.c3rexcess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              backgroundColor: 'black',
              color: 'white',
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 3B</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition1.AEL_standard.single.c3b.watts < condition1.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition1.AEL_standard.single.c3b.watts >= condition1.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : null}>
                  <strong style={{ backgroundColor: condition1.AEL_standard.single.c3b.joules === condition1.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (conditionIsEmpty === false && laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      return condition1.AEL_standard.single.c3b.wattsPretty;
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" && conditionIsEmpty === false) {
                      return condition1.AEL_standard.single.c3b.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" && conditionIsEmpty === false ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition1.AEL_standard.pulseTrain.c3b.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.pulseTrain.c3b.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.pulseTrain.c3b.raw === condition1.mostRestrictiveAEL.class3B && condition1.AEL_standard.single.c3b.joules !== condition1.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition1.AEL_standard.pulseTrain.c3b.raw !== 0 ? condition1.AEL_standard.pulseTrain.c3b.pretty : null}</strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition1.AEL_standard.Average.c3b.raw < condition1.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition1.AEL_standard.Average.c3b.raw >= condition1.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition1.AEL_standard.Average.c3b.raw === condition1.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>{condition1.AEL_standard.Average.c3b.raw !== 0 ? condition1.AEL_standard.Average.c3b.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>
                {typeof condition1.AEL_standard.Excess.c3bexcess.raw === "number" ? (
                  <Typography style={condition1.AEL_standard.Excess.c3bexcess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition1.AEL_standard.Excess.c3bexcess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition1.AEL_standard.Excess.c3bexcess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition1.AEL_standard.Excess.c3bexcess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition1.AEL_standard.Excess.c3bexcess.raw == undefined) {
                    return null
                  }
                  else if (condition1.AEL_standard.Excess.c3bexcess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition1.AEL_standard.Excess.c3bexcess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            {
              /* Collected Emission */
            }
            <Row style={{
              ...resultStyle,
              backgroundColor: '#7077A1',
              color: '#F6B17A'
            }}>
              <Col flex='150px' style={textCenter}>Collected Emission</Col>
              <Col flex={laserOperation === "RP" ? "85px" : "180px"} style={textCenter}>
                <strong>{condition1.collectedEmissionWatts.wattsPretty === "NaNW" ? "--" : condition1.collectedEmissionWatts.wattsPretty}</strong>
              </Col>
              <Col flex={laserOperation === "RP" ? "115px" : "252px"} style={textCenter}>
                <strong>{condition1.collectedEmissionJoules.joulesPretty === "NaNJ" ? "--" : condition1.collectedEmissionJoules.joulesPretty}</strong>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "7px" }}>
          {
            /* Column for Condition 1 Params */
          }
          <Col span={7} style={{ marginRight: "7px" }}>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Aperture Stop</Col>
              <Col span={9} style={textCenter}>{condition1.apertureStop === "NaNm" ? "--" : condition1.apertureStop}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Aperture Distance</Col>
              <Col span={9} style={textCenter}>{condition1.apertureDistance === "NaN m" ? "--" : condition1.apertureDistance} </Col>
            </Row>

            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Acceptance Angle</Col>
              {/* <Col span={9} style={textCenter}>{condition1.angleOfAcceptance.eng}rad</Col> */}
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Angular Subtense</Col>
              <Col span={9} style={textCenter}>{condition1.angularSubtense}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Value for C₆</Col>
              <Col span={9} style={textCenter}>{condition1.C6}</Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{
        width: '900px',
        margin: '-9px 0 12px',
        backgroundColor: '#2D3250',
        padding: '15px',
        borderRadius: '5px'
      }}>
        {
          /* Condition 3 */
        }
        <Row justify={'end'} style={{
          backgroundColor: 'black',
          color: 'white',
          ...resultStyle,
        }}>
          <Col flex="50px"></Col>
          <Col flex={laserOperation === "RP" ? "355px" : "642px"} style={{ fontSize: "20px" }}><strong>CONDITION 3</strong></Col>
          {laserOperation === "RP" ? (
            <>
              <Col flex={laserOperation === "RP" ? "110px" : "200px"}>Single Pulse</Col>
              <Col flex="112px">Pulse Train</Col>
              <Col flex="112px">Average</Col>
            </>
          ) : null}
          <Col flex={laserOperation === "RP" ? "85px" : "145px"}> Excess</Col>
          <Col flex="auto"> P/F</Col>
        </Row>
        <Row gutter={[8, 8]}>
          {
            /* Column for AEL and Collected Emission */
          }
          <Col span={17}>
            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 1</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition3.AEL_standard.single.c1.watts < condition3.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition3.AEL_standard.single.c1.watts >= condition3.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : condition3.AEL_standard.single.c1.wattsPerSqm >= condition3.collectedEmissionPerSqm.watts ? {
                  color: 'green',
                  ...textCenter
                } : {
                  color: 'red',
                  ...textCenter
                }}>

                  <strong style={{ backgroundColor: condition3.AEL_standard.single.c1.joules === condition3.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      if (condition3.AEL_standard.single.c1.wattsPretty !== "nullW") {
                        return condition3.AEL_standard.single.c1.wattsPretty;
                      } else {
                        return condition3.AEL_standard.single.c1.wattsPerSqmPretty;
                      }
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP") {
                      if (condition3.AEL_standard.single.c1.joulesPretty !== "nullJ") {
                        return condition3.AEL_standard.single.c1.joulesPretty;
                      } else {
                        return condition3.AEL_standard.single.c1.joulesPerSqmPretty;
                      }
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition3.AEL_standard.pulseTrain.c1.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.pulseTrain.c1.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.pulseTrain.c1.raw === condition3.mostRestrictiveAEL.class1 && condition3.AEL_standard.single.c1.joules !== condition3.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition3.AEL_standard.pulseTrain.c1.raw !== 0 ? condition3.AEL_standard.pulseTrain.c1.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition3.AEL_standard.Average.c1.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.Average.c1.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.Average.c1.raw === condition3.mostRestrictiveAEL.class1 && laserOperation === "RP" ? '#F6B17A' : null }}>{condition3.AEL_standard.Average.c1.raw !== 0 ? condition3.AEL_standard.Average.c1.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>

                {typeof condition3.AEL_standard.Excess.c1excess.raw === "number" ? (
                  <Typography style={condition3.AEL_standard.Excess.c1excess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition3.AEL_standard.Excess.c1excess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : condition3.AEL_standard.Excess
                  }>
                    <strong>{condition3.AEL_standard.Excess.c1excess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition3.AEL_standard.Excess.c1excess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition3.AEL_standard.Excess.c1excess.raw == undefined) {
                    return null
                  }
                  else if (condition3.AEL_standard.Excess.c1excess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition3.AEL_standard.Excess.c1excess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 2</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition3.AEL_standard.single.c2.watts < condition3.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition3.AEL_standard.single.c2.watts >= condition3.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : null}>
                  <strong style={{ backgroundColor: condition3.AEL_standard.single.c2.joules === condition3.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      return condition3.AEL_standard.single.c2.wattsPretty;
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP") {
                      return condition3.AEL_standard.single.c2.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition3.AEL_standard.pulseTrain.c2.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.pulseTrain.c2.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.pulseTrain.c2.raw === condition3.mostRestrictiveAEL.class2 && condition3.AEL_standard.single.c2.joules !== condition3.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition3.AEL_standard.pulseTrain.c2.raw !== 0 ? condition3.AEL_standard.pulseTrain.c2.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition3.AEL_standard.Average.c2.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.Average.c2.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.Average.c2.raw === condition3.mostRestrictiveAEL.class2 && laserOperation === "RP" ? '#F6B17A' : null }}>{condition3.AEL_standard.Average.c2.raw !== 0 ? condition3.AEL_standard.Average.c2.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>

                {typeof condition3.AEL_standard.Excess.c2excess.raw === "number" ? (
                  <Typography style={condition3.AEL_standard.Excess.c2excess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition3.AEL_standard.Excess.c2excess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition3.AEL_standard.Excess.c2excess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition3.AEL_standard.Excess.c2excess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition3.AEL_standard.Excess.c2excess.raw == undefined) {
                    return null
                  }
                  else if (condition3.AEL_standard.Excess.c2excess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition3.AEL_standard.Excess.c2excess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 3R</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition3.AEL_standard.single.c3r.watts < condition3.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition3.AEL_standard.single.c3r.watts >= condition3.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : condition3.AEL_standard.single.c3r.wattsPerSqm >= condition3.collectedEmissionPerSqm.watts ? {
                  color: 'green',
                  ...textCenter
                } : {
                  color: 'red',
                  ...textCenter
                }}>
                  <strong style={{ backgroundColor: condition3.AEL_standard.single.c3r.joules === condition3.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      if (condition3.AEL_standard.single.c3r.wattsPretty !== "nullW") {
                        return condition3.AEL_standard.single.c3r.wattsPretty;
                      } else {
                        return condition3.AEL_standard.single.c3r.wattsPerSqmPretty;
                      }
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP") {
                      if (condition3.AEL_standard.single.c3r.joulesPretty !== "nullJ") {
                        return condition3.AEL_standard.single.c3r.joulesPretty;
                      } else {
                        return condition3.AEL_standard.single.c3r.joulesPerSqmPretty;
                      }
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition3.AEL_standard.pulseTrain.c3r.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.pulseTrain.c3r.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.pulseTrain.c3r.raw === condition3.mostRestrictiveAEL.class3R && condition3.AEL_standard.single.c3r.joules !== condition3.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition3.AEL_standard.pulseTrain.c3r.raw !== 0 ? condition3.AEL_standard.pulseTrain.c3r.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition3.AEL_standard.Average.c3r.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.Average.c3r.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.Average.c3r.raw === condition3.mostRestrictiveAEL.class3R && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition3.AEL_standard.Average.c3r.raw !== 0 ? condition3.AEL_standard.Average.c3r.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>

                {typeof condition3.AEL_standard.Excess.c3rexcess.raw === "number" ? (
                  <Typography style={condition3.AEL_standard.Excess.c3rexcess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition3.AEL_standard.Excess.c3rexcess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition3.AEL_standard.Excess.c3rexcess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition3.AEL_standard.Excess.c3rexcess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition3.AEL_standard.Excess.c3rexcess.raw == undefined) {
                    return null
                  }
                  else if (condition3.AEL_standard.Excess.c3rexcess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition3.AEL_standard.Excess.c3rexcess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            <Row style={{
              backgroundColor: 'black',
              color: 'white',
              ...resultStyle
            }}>
              <Col flex="150px" style={textCenter}>AEL for Class 3B</Col>
              <Col flex={laserOperation === "RP" ? "80px" : "180px"}>
                <Typography style={condition3.AEL_standard.single.c3b.watts < condition3.collectedEmissionWatts.watts ? {
                  color: 'red',
                  ...textCenter
                } : condition3.AEL_standard.single.c3b.watts >= condition3.collectedEmissionWatts.watts ? {
                  color: 'green',
                  ...textCenter
                } : null}>
                  <strong style={{ backgroundColor: condition3.AEL_standard.single.c3b.joules === condition3.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>{(() => {
                    if (laserOperation === 'cw' || laserOperation === 'FO_cw') {
                      return condition3.AEL_standard.single.c3b.wattsPretty;
                    } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP") {
                      return condition3.AEL_standard.single.c3b.joulesPretty;
                    }
                  })()}</strong>
                </Typography>
              </Col>
              {laserOperation === "RP" ? (
                <>
                  {/* Pulse Train*/}
                  <Col flex="120px">
                    <Typography style={condition3.AEL_standard.pulseTrain.c3b.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.pulseTrain.c3b.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.pulseTrain.c3b.raw === condition3.mostRestrictiveAEL.class3B && condition3.AEL_standard.single.c3b.joules !== condition3.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>
                        {condition3.AEL_standard.pulseTrain.c3b.raw !== 0 ? condition3.AEL_standard.pulseTrain.c3b.pretty : null}
                      </strong>
                    </Typography>
                  </Col>
                  {/* Average*/}
                  <Col flex="92px">
                    <Typography style={condition3.AEL_standard.Average.c3b.raw < condition3.collectedEmissionJoules.joules ? { color: 'red', ...textCenter }
                      : condition3.AEL_standard.Average.c3b.raw >= condition3.collectedEmissionJoules.joules ? {
                        color: 'green', ...textCenter
                      }
                        : null}>
                      <strong style={{ backgroundColor: condition3.AEL_standard.Average.c3b.raw === condition3.mostRestrictiveAEL.class3B && laserOperation === "RP" ? '#F6B17A' : null }}>{condition3.AEL_standard.Average.c3b.raw !== 0 ? condition3.AEL_standard.Average.c3b.pretty : null}</strong>
                    </Typography>
                  </Col>
                </>
              ) : null}
              <Col flex={laserOperation === "RP" ? "125px" : "250px"}>

                {typeof condition3.AEL_standard.Excess.c3bexcess.raw === "number" ? (
                  <Typography style={condition3.AEL_standard.Excess.c3bexcess.raw > 1 ? {
                    color: 'red',
                    ...textCenter
                  } : condition3.AEL_standard.Excess.c3bexcess.raw <= 1 ? {
                    color: 'green',
                    ...textCenter
                  } : null}>
                    <strong>{condition3.AEL_standard.Excess.c3bexcess.pretty}</strong>
                  </Typography>
                ) : (
                  <Typography>
                    {condition3.AEL_standard.Excess.c3bexcess.raw}
                  </Typography>
                )}
              </Col>
              <Col>
                {(() => {
                  if (condition3.AEL_standard.Excess.c3bexcess.raw == undefined) {
                    return null
                  }
                  else if (condition3.AEL_standard.Excess.c3bexcess.raw > 1) {
                    return <strong style={{ color: 'red' }}> Fail</strong>
                  }
                  else if (condition3.AEL_standard.Excess.c3bexcess.raw <= 1) {
                    return <strong style={{ color: 'green' }}> Pass</strong>
                  }
                })()}
              </Col>
            </Row>

            {
              /* Collected Emission */
            }
            <Row style={{
              ...resultStyle,
              backgroundColor: '#7077A1',
              color: '#F6B17A'
            }}>
              <Col flex='150px' style={textCenter}>Collected Emission</Col>
              <Col flex={laserOperation === "RP" ? "85px" : "180px"} style={textCenter}>
                <strong>{condition3.collectedEmissionWatts.wattsPretty === "NaNW" ? "--" : condition3.collectedEmissionWatts.wattsPretty}
                  {CE3isPersqm === true ? (
                    <>
                      <Divider type="vertical" />
                      {condition3.collectedEmissionPerSqm.wattsPretty + "/m²"}
                    </>
                  ) : (null)}

                </strong>
              </Col>
              <Col flex={laserOperation === "RP" ? "115px" : "252px"} style={textCenter}>
                <strong>{condition3.collectedEmissionJoules.joulesPretty === "NaNJ" ? "--" : condition3.collectedEmissionJoules.joulesPretty}
                  {CE3isPersqm === true ?
                    <>
                      <Divider type="vertical" />
                      {condition3.collectedEmissionPerSqm.joulesPretty + "/m²"}
                    </>
                    : null}
                </strong>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "7px" }}>
          {
            /* Column for Condition 3 Params */
          }
          <Col span={7}>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Aperture Stop</Col>
              <Col span={9} style={textCenter}>{condition3.apertureStop === "NaNm" ? "--" : condition3.apertureStop}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Aperture Distance</Col>
              <Col span={9} style={textCenter}>{condition3.apertureDistance === "NaN m" ? "--" : condition3.apertureDistance}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Angular Subtense</Col>
              <Col span={9} style={textCenter}>{condition3.angularSubtense}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Value for C₆</Col>
              <Col span={9} style={textCenter}>{condition3.C6}</Col>
            </Row>
            <Row style={{
              ...resultStyle
            }}>
              <Col span={15} style={textEnd}>Acceptance Angle</Col>
              {/* <Col span={9} style={textCenter}>{condition3.angleOfAcceptance.eng} rad</Col> */}
            </Row>
          </Col>
        </Row>
      </div>


      <div style={{ disaply: "flex", justifySelf: "center", alignSelf: "center", borderStyle: "solid", height: "100%", padding: "5px", borderImage: 'linear-gradient(90deg ,#e6007e, #ffcc01, #10c96d, #00B5E2) 1', display: 'flex', textAlign: "center", justifyContent: "center", alignItems: 'center' }}>
        <strong style={{ fontSize: '20px' }}><span style={spanBlock}>THIS PRODUCT IS CLASSIFIED AS A </span>CLASS {Class} LASER</strong>
      </div>

      <Classification Class={Class} spanBlock={spanBlock} />

      <br />
    </>
  );
}
