import { Space, Typography, Divider, Tooltip, Flex, Row, Col} from "antd";
import ResultCard from "../../../../components/ResultCard";

export function EyewearResults(eyewearResults){

    let props = eyewearResults.props
    const textCenter = { display: 'flex', justifyContent: 'center' }
    let message = "The LB number indicates the level of protection provided by glass/plastic. ".concat((props.result.Glass != null && props.result.Plastic != null) ? ""
        : "If the pulse duration exceeds the exposure duration specified in Table B.1, a scale number for I is not applicable, and only a scale number for the D mode should be computed accordingly.")
    return (
        <> 
        <Row justify="center" align="center" style={{textAlign: "center"}}>
            <Divider>
                <Typography>Optical Density, LB Rating, and RB Number</Typography>
            </Divider>
            <Col sm={24} md={24} lg={24} xl={24} xxl={3} style={{alignContent:"center"}}>
                <Flex wrap="true" style={{justifyContent: "center", justifySelf:"center"}}>
                    <strong style={{ display: "flex", alignItems: "center"}}>Results at {props.result.distance} m</strong>
                </Flex>
            </Col>
            <Flex wrap="true">
                <ResultCard>
                    <strong style={textCenter}> Nominal O.D.</strong>
                    <>
                    <Space style={textCenter}>
                        <strong>
                            <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                                <Typography>{props.result.NominalOD.OD}</Typography>
                            </Tooltip>
                        </strong>
                    </Space>
                    </>
                </ResultCard>      
                <ResultCard>
                    <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                        <strong style={textCenter}>RB Number</strong>
                        <Typography style={textCenter}>{props.result.RBNumber}</Typography>
                    </Tooltip>
                </ResultCard>
            </Flex>
            <Flex wrap="true">
                <ResultCard>
                    <Tooltip title={message}>
                        <strong style={textCenter}>LB Number for Glass</strong>
                        <>
                            {props.result.Glass_average != null ? (
                            <>
                            <Space style={textCenter}>
                                <Typography style={textCenter}>{props.result.Glass_average}</Typography>
                                {props.result.Glass == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                <Typography style={textCenter}>{props.result.Glass}</Typography>
                            </Space>
                            </> 
                            ) : <Typography style={textCenter}>{props.result.Glass}</Typography>} 
                        </>
                    </Tooltip>
                </ResultCard>
                <ResultCard>
                    <Tooltip title={message}>
                        <strong style={textCenter}>LB Number for Plastic</strong>
                        {props.result.Plastic_average != null ? (
                            <Space style={textCenter}>
                                <Typography style={textCenter}>{props.result.Plastic_average}</Typography>
                                {props.result.Plastic == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                <Typography style={textCenter}>{props.result.Plastic}</Typography>
                            </Space>
                        ) : <Typography style={textCenter}>{props.result.Plastic}</Typography>
                    }
                    </Tooltip>
                </ResultCard>
            </Flex>

            <Divider />
            <Col sm={24} md={24} lg={24} xl={24} xxl={3} style={{alignContent:"center"}}>        
                <Flex wrap="true" style={{justifyContent: "center"}}>
                    <strong style={{ display: "flex", alignItems: "center" }}>Results at 0.1 m</strong>
                </Flex>
            </Col>
            <Flex wrap="true">
                <ResultCard>
                <strong style={textCenter}>Nominal O.D.</strong>
                <>
                    <Space style={textCenter}>
                    <strong>
                        <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                        <Typography>{props.result.NominalOD_distance.OD}</Typography>
                        </Tooltip>
                    </strong>
                    </Space>
                </>
                </ResultCard>
                <ResultCard>
                <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                    <strong style={textCenter}>RB Number</strong>
                    <Typography style={textCenter}>{props.result.RBNumber_distance}</Typography>
                </Tooltip>
                </ResultCard>
            </Flex>
            <Flex wrap="true">
                <ResultCard>
                <Tooltip title={message}>
                    <strong style={textCenter}>LB Number for Glass</strong>
                    <>
                    {props.result.GlassAverage_Distance != null ? (
                        <Space style={textCenter}>
                            <Typography style={textCenter}>{props.result.GlassAverage_Distance}</Typography>
                            {props.result.Glass_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                            <Typography style={textCenter}>{props.result.Glass_Distance}</Typography>
                        </Space>
                    ) : <Typography style={textCenter}>{props.result.Glass_Distance}</Typography>}
                    </>
                </Tooltip>
                </ResultCard>
                <ResultCard>
                <Tooltip title={message}>
                    <strong style={textCenter}>LB Number for Plastic</strong>
                    {props.result.PlasticAverage_Distance != null ? (
                    <Space style={{textCenter}}>
                        <Typography style={textCenter}>{props.result.PlasticAverage_Distance}</Typography>
                        {props.result.Plastic_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                        <Typography style={textCenter}>{props.result.Plastic_Distance}</Typography>
                    </Space>
                    ) : <Typography style={textCenter}>{props.result.Plastic_Distance}</Typography>}
                </Tooltip>
                </ResultCard>
            </Flex>
        </Row>
      </>
    )
}