import React from 'react'
import {Row, Col} from 'antd'
import { Classification } from '../../../../../components/Classification'
import { Label, TestingCenter } from '../../../../../components/TestAndLabelLinks'
const LaserClassVerdict = (props) => {
    const { Class } = props.laserClass
    var spanBlock = {
        display: "block"
    }
    return (
        <> 
            <Row justify={"space-evenly"} align={"middle"}>
                <Col>
                    <div style={{ disaply: "flex", justifySelf: "center", alignSelf: "center",  height: "100%", padding: "5px", border: "4px solid #1d1f8c", borderRadius:"15px", display: 'flex', textAlign: "center", justifyContent: "center", alignItems: 'center' }}>
                        <strong style={{ fontSize: '20px', color:"#1d1f8c"}}><span style={spanBlock}>THIS PRODUCT IS CLASSIFIED AS A </span>CLASS {Class} LASER</strong>
                    </div>
                </Col>   
                <Col>
                    <Classification Class={Class} spanBlock={spanBlock} />
                </Col>
            </Row>
            <Row justify={"center"} style={{marginTop:"20px"}}>
                <Label />
            </Row>
            <Row justify={"center"} style={{marginTop:"20px"}}>
                <TestingCenter />
            </Row>
        </>
    )
}

export default LaserClassVerdict