import { Card, Flex, Button, Row, Form, Col } from "antd"
import { NavLink } from 'react-router-dom';
import PointandExtended from '../assets/MenuCalculators/Laser.png'
import FiberOptic from '../assets/MenuCalculators/FibreOptics.png'
import DiffuseReflection from '../assets/MenuCalculators/DiffuseReflection.png'
import LookupTable from '../assets/MenuCalculators/LookUp Table.png'
import DiffuseReflection2 from '../assets/MenuCalculators/Diffuse Reflection P.png'
import PointnadExtendedP from '../assets/MenuCalculators/Laser P.png'
import FiberOpticP from '../assets/MenuCalculators/Fibre Optics P.png'
import LookupTableP from '../assets/MenuCalculators/LookUp Table P.png'

import './plan.css'
const { Meta } = Card
const cardStyle = { alignText:'start', width: "100%", border: "0px solid black", margin: " 0px", borderRadius: "0", overflow: "hidden" }


export function CalculatorNavigationHomePage() {
    

return (
<>
    <Row justify={'center'} style={{ margin: '3% 0 3% 0'}} gutter={[16, 16]}>
        <Col align='middle' xs={24} sm={24} md={12} lg={5} >
            <div class="card" style={{border: "1px solid black"}}>
                <NavLink to="/mpe-ael-calc" style={{ textDecoration: "none", display: "flex", justifyContent: 'center'}}>
                    <img src={PointnadExtendedP}></img>
                    <div class="intro">
                        <h1>Point & Extended</h1>
                        <p>The point source has a laser emission from a single point and has a miniscule diameter 
                                compared with the viewing distance while an extended source is the opposite with
                                laser emitted from various points and a relatively large diameter.
                        </p>
                    </div>    
                </NavLink>
            </div>
        </Col>    
        <Col align='middle' xs={24} sm={24} md={12} lg={5} >
            <div class="card" style={{border: "1px solid black"}}>
                <NavLink to="/fiber-optic" style={{ textDecoration: "none", display: "flex", justifyContent: 'center'}}>
                    <img src={FiberOpticP}></img>
                    <div class="intro">
                        <h1>Fiber Optic</h1>
                            <p>Utilises flexible quartz or glass fibres that use total internal reflection to pass light through
                                thousands of glancing reflections. Optical sources typically used are laser diodes,
                                LEDs and VCSEL (Vertical Cavity Surface Emitting Laser).
                            </p>
                    </div>    
                </NavLink>
            </div>
        </Col>    
        <Col align='middle' xs={24} sm={24} md={12} lg={5} >
            <div class="card" style={{border: "1px solid black"}}>
                <NavLink to="/diffuse-reflective" style={{ textDecoration: "none", display: "flex", justifyContent: 'center'}}>
                    <img src={DiffuseReflection2}></img>
                    <div class="intro">
                        <h1>Diffuse Reflection</h1>
                            <p>This happens when different parts of a beam strike a surface and reflects over a wide range of angles.
                                The reflection typically strikes a rough or irregular surface.
                            </p>
                    </div>    
                </NavLink>
            </div>
        </Col>    
        <Col align='middle' xs={24} sm={24} md={12} lg={5} >
            <div class="card" style={{border: "1px solid black"}}>
                <NavLink to="/lookup-table" style={{ textDecoration: "none", display: "flex", justifyContent: 'center'}}>
                    <img src={LookupTableP}></img>
                    <div class="intro">
                        <h1>Lookup Table</h1>
                            <p>Find out the accessible emission limit for all laser class in relation to the laser wavelength you want to calculate.
                                Evaluate the laser properties to determine safety values for eye and skin.
                            </p>
                    </div>    
                </NavLink>
            </div>
        </Col>    
    </Row>
    </> )
        
}  