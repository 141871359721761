import React, { useEffect, useState } from 'react'
import { Modal, Row, Spin, Typography } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import  NOHD  from "../../assets/Infographics/beam.1.png"

import enohd from "../../assets/Infographics/enohd.png"
import spectrum from "../../assets/Infographics/spectrum box with label v2.png"
import spotsize from "../../assets/Infographics/SPOT SIZE ai.png"
import limitAperture from "../../assets/Infographics/limiting aperture.png"
import applicableBeamArea from "../../assets/Infographics/applicable beam area.png"
import beamDivergence from "../../assets/Infographics/Beam Divergence.png"

export function Infograph(props) {

const [isModalOpen, setIsModalOpen] = useState(false)
const [isImageLoaded, setIsImageLoaded] = useState(false);
const [spinning, setSpinning] = useState(true);

    
let infograph
let height = "200vh"
let message
    
if(props.info === "NOHD"){
    infograph = enohd;
    message = "Distance from the output aperture beyond which the beam irradiance or radiant exposure remains below the appropriate corneal maximum permissible exposure."
}
else if(props.info === "ENOHD"){
    infograph = enohd;
    message = "Distance from the output aperture beyond which the beam irradiance or radiant exposure remains below the appropriate corneal maximum permissible exposure. If the NOHD includes the possibility of viewing through optical aids, this is termed the 'extended NOHD (ENOHD)'."
}
else if (props.info === "Spot Size") {
    infograph = spotsize;
    message = "The diameter of the smallest circle which contains u % of the total laser power (or energy)."
}
else if (props.info === "spectrum") {
    infograph = spectrum;
    height = "300vh"
}
else if (props.info === "limit aperture") {
    infograph = limitAperture;
    height = "300vh"
    message = "The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."
}
else if (props.info === "applicable beam area") {
    infograph = applicableBeamArea;
    height = "230vh"
    message = "The area of the spot size or limit aperture - whichever is the largest."
}
else if (props.info === "beam divergence") {
    infograph = beamDivergence;
    height = "250vh"
    message = "The angular measure of the increase in beam diameter with distance from which the beam emerges"
}


useEffect(() => {
    const image = new Image();
    image.src = infograph 
    // setTimeout(() => {
    //     setIsImageLoaded2(false); 
    // }, 500)
    
    image.onload = () => {
        setIsImageLoaded(true); 
        setSpinning(false); 
      };
  
      image.onerror = () => {
        // Handle error if image fails to load
        setIsImageLoaded(false);
      };
}, [infograph ])
   
return(
    <Modal open={props.isModalOpen} onCancel={props.handleCancel} width={1000} >
        <Spin
            spinning={spinning}
            indicator={
            <LoadingOutlined
                style={{
                fontSize: 24,
                }}
                spin
            />}
        >
                <Row justify={"center"}><strong style={{textTransform:"uppercase"}}>{props.info}</strong></Row>
                <Row justify={"center"} style={{marginTop:"30px"}}>
                    {isImageLoaded && (
                        <img src={infograph} height={height} />
                    )}
                </Row>
                <Row style={{justifyContent:"center", marginTop:"25px"}}>
                    <div style={{width:"60%", textAlign:"center"}}>
                        <Typography>
                            {message}
                        </Typography>
                    </div>
                </Row>
        </Spin>
    </Modal>
)
}