import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Result } from 'antd';

const SubscriptionSuccess = () => (
  <Result
    status="success"
    title={[<h1>You have Successfully Subscribed!</h1>]}
    style={{justifySelf:"center", marginTop:" 10%",}}
    subTitle="Enjoy Unlimited Calculations for 1 year!"
    extra={[
    <NavLink to='/home'>
      <Button type="primary" key="console">
       Return to Homepage
      </Button>
    </NavLink>    
    ]}
  />
);
export default SubscriptionSuccess;