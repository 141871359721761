/*
================================================================================
FILE        : Login.jsx
AUTHOR      : Kathryn Marie P. Sigaya
DESCRIPTION : JSX file that shows the login page.
COPYRIGHT   : 26 September 2023
REVISION HISTORY
Date: 			     By: 		 Description:
26 September 2023    Sigaya      Creation of file, initial layout using Ant Design framework
18 October 2023      Sigaya      Added mini icon, changed font family to Helvetica Neue, changed
                                 colors to official colors
================================================================================
*/
import React, { useEffect, useState } from "react";
import Axios from 'axios'
import Cookies from "js-cookie";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Typography,
  Flex,
  Modal
} from 'antd';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import miniIcon from '../assets/Logo/rtlss logo.png'
import logo from '../assets/Logo/logosidetext.png'
import { FooterHandler } from "../components/Footer/Footer";

// const {Title} = Typography;

function Login({ setMyUser, myUser, isLocal, disableNav, setDisableNav }) {
  const { Header, Content, Footer } = Layout;
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [openCookieNotice, setOpenCookieNotice] = useState(false)
  const {state} = useLocation()
  setDisableNav(true)
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const year = new Date().getFullYear();
  const handleCloseFlash = () => {
    setFlashMessage(null)
  }

  const onFinish = (values) => {
    const { username, password, remember } = form.getFieldsValue()
    setIsSubmitting(true)
    Axios({
      method: 'post',
      data: { username: username, password: password, remember: remember },
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/jwt-login'
        } else {
          return 'api/jwt-login'
        }
      })(),
      withCredentials: true,
    })
      .then((response) => {
        fetch('https://ipinfo.io/json?token=7a0cb72fba6b42')
          .then(response => response.json())
          .catch(() => ({ country: "GB", timezone: "Europe" }))
          .then(data => {
            Cookies.set('RTLSS_Loc', JSON.stringify(data), { expires: 1 })
            setMyUser({ ...myUser })
            console.log(`token successfully appended.`)
            setFlashMessage({ type: 'success', message: 'Login successful!' })
            navigate('/home')
            setDisableNav(false)
          })

      })
      .catch((err) => {
        setIsSubmitting(false)
        console.error(err)
        setFlashMessage({ type: 'error', message: err.response?.data.error || 'Error' })
      })
  };

  useEffect(() => {
    if (Cookies.get('_cookieConsent') === undefined) {
      setOpenCookieNotice(true)
    } else {

    }
  }, [])

  const handleCookieNotice =() =>{
    setOpenCookieNotice(false)
    Cookies.set('_cookieConsent', true, {expires: 182})
  }

  const onFinishFailed = (errorInfo) => {

  };


  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1d1f8c',

          },
          components: {
            Layout: {
              bodyBg: '#efefef',
              footerBg: '#1d1f8c'
            },
            Input: {
              errorActiveShadow: '#d82424'
            }
          }
        }
        }>
        {/*Layout - Applies to the whole web page.*/}
        {/* Set the minHeight to 100vh to stick the footer to the bottom of the page.*/}
        {/* <Layout className="layout" style={{ minHeight: "100vh", display: 'flex' }}> */}
        {/* <Row style={{ display: 'flex', padding: '100px 0px 0px 0px', justifyContent: 'center' }}>
            <img src={logo} alt="Lasermet Alternative Logo" style={{width:"320px"}} />
          </Row> */}
        {/* <Row style={{ display: 'flex', padding: '20px 0px 0px 0px', justifyContent: 'center' }}>
            <Typography.Text style={{ color: '#3a4e84', fontSize: '30px', fontWeight: '500' }}>RealTime Laser Safety Software</Typography.Text>
          </Row> */}
        {/*
          <Content style={{
            padding: '20px 50px',
            justifyContent: 'center',
            display: 'flex'
          }}> */}

          <Flex justify='center' align='center' style={{ width: "100%", minHeight: "100vh" }}>
            <Card
              // title={<Typography.Text style={{ fontWeight: '500' }}>Log In to the System</Typography.Text>}
              style={{
                width: 500,
                height: "auto",
                fontWeight: '500',
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
              }}
              cover={
                <Flex justify='center' align='center' style={{ marginBottom: "25px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                  <img
                    justify='center' align='center'
                    src={logo}
                    style={{ width: "90%", justifySelf: "center"}}
                  />
                </Flex>
              }
              bordered={false}>
              <Form
                name="Log In"
                className="login-form"
                initialValues={{
                  remember: false,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
              <Form.Item name="username">
                <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username" />
              </Form.Item>
              {/* Password  */}
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password.',
                  },
                ]}
              >
                {/*Input.Password has the visibility toggle*/}
                <Input.Password
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                noStyle
                valuePropName="checked"
              >
                <Checkbox style={{ fontSize: '16px' }}>Remember me</Checkbox>
              </Form.Item>

              {/* <Form.Item>
                <Button loading={isSubmitting} size={'large'} type={'primary'} htmlType="submit"
                  className="login-form-button" block>
                  Login
                </Button>
              </Form.Item>
              <Divider plain style={{ fontSize: '16px' }}>Don't have an account yet? Register <Link
                to="/register">here</Link>!</Divider>
              <Divider plain style={{ fontSize: '16px' }}>Got a confirmation code? Activate <Link
                to="/confirm">here</Link>!</Divider> */}
                <Form.Item>
                  <Button loading={isSubmitting} size={'large'} type={'primary'} htmlType="submit"
                    className="login-form-button" block>
                    Log In
                  </Button>
                </Form.Item>
                <Row justify={"center"}>
                  <Link to="/register"><h6>Create a new account</h6></Link>
                    <Divider type="vertical" style={{height:"25px"}}/>
                  <Link to="/confirm"><h6>Activate Account</h6></Link>

                </Row>
{/* 
              {flashMessage != null ? (
                <Alert
                  description={flashMessage.message}
                  type={flashMessage.type}
                  closable
                  onClose={handleCloseFlash}
                />
              ) : (null)} */}

               


                <Typography.Text type="secondary"fontFamily='Segoe UI' style={{fontWeight:"lighter", fontSize:"12px"}}>By logging in, you accept our use of essential cookies as described in our <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{textDecoration:"underline"}}>Privacy Policy</a></Typography.Text>

                {flashMessage != null ? (
                  <Alert
                    description={flashMessage.message}
                    type={flashMessage.type}
                    closable
                    onClose={handleCloseFlash}
                  />
                ) : (null)}

                {/*For JSON format display only*/}
                {/* <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Typography>
                      <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                    </Typography>
                  )}
                </Form.Item> */}
            </Form>
          </Card>
          {/* </Content> */}
        </Flex>

          {/*Footer Section*/}
          <FooterHandler />
          {/* <Footer style={{
            textAlign: 'center',
            backgroundColor: '#1d1f8c',
            color: '#f5f5f5',
            fontSize: '16px'
          }}
          >
            Lasermet Philippines © {year}. Created by Lasermet Philippines.
          </Footer> */}
        {/* </Layout> */}
      </ConfigProvider>
        {/* <Modal
          open={openCookieNotice}
          title={<h3>We use cookies</h3>}
          closable={false}
          footer={
            <Row style={{width: "100%", justifyContent:"center"}}>
              <Button type='primary'  onClick={()=>{handleCookieNotice()}} style={{width: "50%"}}> Accept </Button>
            </Row>

          }>
            <Typography.Paragraph>
              We utilize essential cookies that are vital for the proper functioning of our application. These cookies support critical features such as session management and geolocation, which we use exclusively to determine your currency.
              As these cookies are necessary for our app to operate effectively, they cannot be disabled in our systems.
              By using our services, you consent to the use of these essential cookies as outlined in our <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{textDecoration:"underline"}}>Privacy Policy</a>.
            </Typography.Paragraph>
            <Typography.Text type='secondary' italic>Please note that you can only acknowledge this notice by clicking the 'Accept' button.</Typography.Text>
        </Modal> */}
    </>
  )
}

export default Login;
