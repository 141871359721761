import { Row, Typography, Tabs, Divider, Card , Tooltip, Space, Flex } from "antd";
import ResultCard from "../../../../../components/ResultCard";
import React, { useState } from "react";
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import LaserClassVerdict from "../MPE_AEL_Results/LaserClassVerdict";
import { LaserClassResultsV2 } from "../MPE_AEL_Results/LaserClassResultsV2";
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { Infograph } from "../../../../../components/infographicsModal/Infograph";
import { LaserClassResultsDiffuseReflective } from "../MPE_AEL_Results/LaserClassResultsDiffuseReflective";
import { EyewearResults } from "../../ResultComponents/EyewearResults";

export function DRResultRepetitivelyPulsed({result, units, laserOperation}) {
  const textCenter = { display: 'flex', justifyContent: 'center' }
  let message = "The LB number indicates the level of protection provided by glass/plastic. ".concat((result.Glass != null && result.Plastic != null) ? "" : "If the pulse duration exceeds the exposure duration specified in Table B.1, a scale number for I is not applicable, and only a scale number for the D mode should be computed accordingly.")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [info, setInfo] = useState(null)
  let props = {result: result}
  const handleClick = (e) => {
    setIsModalOpen(true)
    setInfo(e)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
  <Tabs items={[{
    key: 1,
    label: "MPE Details",
    children: <>
        <Tabs centered items={[{
            key: 1,
            label: result.albedo.num + "% Albedo",
            children: <>
                <Infograph
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancel}
                    info={info}
                />
                <Card style={{marginBottom: "20px"}}>
                    <Divider orientation="center"><h4><img src={eye} width={50} style={{marginRight:"5px"}}/><strong>EYE</strong></h4></Divider>
                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm)
                                return "#fcf151"
                        })()
                    }}>
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Single Pulse MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to a single pulse laser without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.single.joulesPerSqmPretty.eng + 'J/m²';
                                            } else if (units === 'watts') {
                                                return result.MPE.single.wattsPerSqmPretty.eng + 'W/m²';
                                            }
                                        })()}

                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.albedo_watts_single > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.albedo_watts_single <= 1 && result.MPEExcess.albedo_watts_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.albedo_watts_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.albedo_joulesPerSqm_singlePretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>

                    </Card>

                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm) {
                                return null
                            }
                            else if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.single.joulesPerSqm) {
                                return "#fcf151"
                            }
                        })()
                    }}
                    >
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Pulse Train MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.pulseTrain.joulesPerSqmPretty;
                                            } else if (units === 'watts') {
                                                return result.MPE.pulseTrain.wattsPerSqmPretty;
                                            }
                                        })()}
                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                        {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.albedo_watts_train > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.albedo_watts_train <= 1 && result.MPEExcess.albedo_watts_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.albedo_watts_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.albedo_joulesPerSqm_trainPretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>
                    </Card>
                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.average.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm) {
                                return "null"
                            }
                            else if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.average.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.single.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.pulseTrain.joulesPerSqm) {
                                return "#fcf151"
                            }
                        })()
                    }}>
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Average MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.average.joulesPerSqmPretty;
                                            } else if (units === 'watts') {
                                                return result.MPE.average.wattsPerSqmPretty;
                                            }
                                        })()}
                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                            {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.albedo_joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                            </Typography>
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.albedo_watts_average > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.albedo_watts_average <= 1 && result.MPEExcess.albedo_watts_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.albedo_watts_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.albedo_joulesPerSqm_averagePretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>
                    </Card>
                    <Card style={{
                        margin: "5px 0px 0px 0px",
                        backgroundColor: (() => {
                            if (result.MPEExcess.joulesPerSqm > 1) {
                            return "#d82424"
                            }
                            else if (result.MPEExcess.joulesPerSqm <= 0.5) {
                            return "#10c96d"
                            }
                            else if (result.MPEExcess.joulesPerSqm > 0.5 || result.MPEExcess.joulesPerSqm <= 1) {
                            return "#ffa200"
                            }
                        })()
                }}>
                <Typography style={textCenter}>
                    <strong style={{color: "#ffffff"}}>
                    {(() => {

                            if (result.MPEExcess.joulesPerSqm > 1) {
                                return "The configuration that has been entered is Dangerous for the Eye!"
                            }
                            else if (result.MPEExcess.joulesPerSqm <= 0.5) {
                                return "The configuration that has been entered is Safe for the Eye!"
                            }
                            else if (result.MPEExcess.joulesPerSqm > 0.5 || result.MPEExcess.joulesPerSqm <= 1) {
                                return "The configuration that has been entered is Marginally Safe for the Eye!"
                            }
                    })()}
                </strong>
                </Typography>
                    </Card>
                </Card>
            {/*///////////////////////////////////////// SKIN RESULTS/////////////////////////////////////////////// */}
            <Card style={{marginBottom: "20px"}}>
                <Divider><h4><img src={hand} width={50} style={{marginRight:"5px"}}/><strong> SKIN </strong> </h4></Divider>
                <Card style={{
                        backgroundColor: (() => {
                        if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm &&
                            result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm)
                        return "#fcf151"
                })()
                }}>
                <Flex justify="space-evenly"  wrap="wrap">
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Single Pulse MPE
                    </strong>
                    <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                        <Typography style={textCenter}>
                        {(() => {
                            if (units === 'joules'){
                                return result.skinMPE.single.joulesPerSqmPretty.eng + 'J/m²';
                            }else if (units === 'watts'){
                                return result.skinMPE.single.wattsPerSqmPretty.eng + 'W/m²';
                            }
                            })()}
                        </Typography>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Accessible Emission
                    </strong>
                    <Space>
                        <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';
                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';
                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                        <Divider type="vertical" style={{ height: "40px" }}></Divider>
                        <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';
                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';
                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                    </Space></Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Skin Safety Ratio
                    </strong>
                    <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                        <strong style={result.skinMPEExcess.joules_single > 1 ? {
                        ...textCenter, color: "#d82424"
                        } : result.skinMPEExcess.joules_single <= 1 && result.skinMPEExcess.joules_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                        : result.skinMPEExcess.joules_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                            : null}>
                        {result.skinMPEExcess.joulesPretty_single.pretty}
                        </strong>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Limit Aperture
                    </strong>
                    <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                        <Typography style={textCenter}>
                        Eye: {result.limitingAperture.eye} mm
                        </Typography>
                        <Typography style={textCenter}>
                        Skin: {result.limitingAperture.skin} mm
                        </Typography>
                    </Tooltip>
                    </Space>
                </Flex>
                </Card>

                <Card style={{
                        backgroundColor: (() => {
                        if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm &&
                            result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm){
                                return null
                        }
                        else if(result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.single.joulesPerSqm) {
                                    return "#fcf151"
                                }
                })()
                }}>
                <Flex justify="space-evenly"  wrap="wrap">
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Pulse Train MPE
                    </strong>
                    <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                        <Typography style={textCenter}>
                        {(() => {
                            if (units === 'joules'){
                                return result.skinMPE.PulseTrain.joulesPerSqmPretty.eng + 'J/m²';

                            }else if (units === 'watts'){
                                return result.skinMPE.PulseTrain.wattsPerSqmPretty.eng + 'W/m²';

                            }
                            })()}
                        </Typography>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Accessible Emission
                    </strong>
                    <Space>
                        <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';


                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';

                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                        <Divider type="vertical" style={{ height: "40px" }}></Divider>
                        <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';


                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';

                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                    </Space></Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Skin Safety Ratio
                    </strong>
                    <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                        <strong style={result.skinMPEExcess.joules_train > 1 ? {
                        ...textCenter, color: "#d82424"
                        } : result.skinMPEExcess.joules_train <= 1 && result.skinMPEExcess.joules_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                        : result.skinMPEExcess.joules_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                            : null}>
                        {result.skinMPEExcess.joulesPretty_train.pretty}
                        </strong>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Limit Aperture
                    </strong>
                    <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                        <Typography style={textCenter}>
                        Eye: {result.limitingAperture.eye} mm
                        </Typography>
                        <Typography style={textCenter}>
                        Skin: {result.limitingAperture.skin} mm
                        </Typography>
                    </Tooltip>
                    </Space>
                </Flex>
                </Card>

                <Card style={{
                        backgroundColor: (() => {
                        if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.MPEAverage.joulesPerSqm &&
                            result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm &&
                            result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm){
                            return null
                        }
                        else if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.MPEAverage.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.single.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.PulseTrain.joulesPerSqm) {
                                    return "#fcf151"
                        }

                })()
                }}>
                <Flex justify="space-evenly"  wrap="wrap">
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Average MPE
                    </strong>
                    <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                        <Typography style={textCenter}>
                        {(() => {
                            if (units === 'joules'){
                                return result.skinMPE.MPEAverage.joulesPerSqmPretty.eng + 'J/m²';


                            }else if (units === 'watts'){
                                return result.skinMPE.MPEAverage.wattsPerSqmPretty.eng + 'W/m²';

                            }
                            })()}
                        </Typography>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Accessible Emission
                    </strong>
                    <Space>
                        <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';


                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';
                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                        <Divider type="vertical" style={{ height: "40px" }}></Divider>
                        <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                        <strong>
                            <Typography style={textCenter}>
                            {(() => {
                            if (units === 'joules'){
                                return result.skinAE.albedo_joulesPerSqmPretty.eng + 'J/m²';

                            }else if (units === 'watts'){
                                return result.skinAE.albedo_wattsPerSqmPretty.eng + 'W/m²';
                            }
                            })()}
                            </Typography>
                        </strong>
                        </Tooltip>
                    </Space></Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Skin Safety Ratio
                    </strong>
                    <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                        <strong style={result.skinMPEExcess.joules_average > 1 ? { ...textCenter, color: "#d82424" }
                        : result.skinMPEExcess.joules_average <= 1 && result.skinMPEExcess.joules_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                            : result.skinMPEExcess.joules_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                            : null}>
                        {result.skinMPEExcess.joulesPretty_average.pretty}
                        </strong>
                    </Tooltip>
                    </Space>
                    <Space direction="vertical">
                    <strong style={textCenter}>
                        Limit Aperture
                    </strong>
                    <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                        <Typography style={textCenter}>
                        Eye: {result.limitingAperture.eye} mm
                        </Typography>
                        <Typography style={textCenter}>
                        Skin: {result.limitingAperture.skin} mm
                        </Typography>
                    </Tooltip>
                    </Space>
                </Flex>
                </Card>
                <Card style={{
                        margin: "5px 0px 0px 0px",
                        backgroundColor: (() => {
                            if (result.skinMPEExcess.joules > 1) {
                            return "#d82424"
                            }
                            else if (result.skinMPEExcess.joules <= 0.5) {
                            return "#10c96d"
                            }
                            else if (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <= 1) {
                            return "#ffa200"
                            }
                        })()
                }}>
                <Typography style={textCenter}>
                    <strong style={{color: "#ffffff"}}>
                    {(() => {

                            if (result.skinMPEExcess.joules > 1) {
                                return "The configuration that has been entered is Dangerous for the Skin!"
                            }
                            else if (result.skinMPEExcess.joules <= 0.5) {
                                return "The configuration that has been entered is Safe for the Skin!"
                            }
                            else if (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <= 1) {
                                return "The configuration that has been entered is Marginally Safe for the Skin!"
                            }
                    })()}
                </strong>
                </Typography>
                </Card>
            </Card>
            </>
            }, result.albedo.num === 100 ? null : {
            // For 100% albedo
            key: 2,
            label: "100% Albedo",
            children: <>
                <Card style={{marginBottom: "20px"}}>
                    <Divider orientation="center"><h4><img src={eye} width={50} style={{marginRight:"5px"}}/><strong>EYE</strong></h4></Divider>
                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm)
                                return "#fcf151"
                        })()
                    }}>
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Single Pulse MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to a single pulse laser without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.single.joulesPerSqmPretty.eng + 'J/m²';
                                            } else if (units === 'watts') {
                                                return result.MPE.single.wattsPerSqmPretty.eng + 'W/m²';
                                            }
                                        })()}

                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.watts_single > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.watts_single <= 1 && result.MPEExcess.watts_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.watts_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.joulesPerSqm_singlePretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>

                    </Card>

                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm) {
                                return null
                            }
                            else if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.single.joulesPerSqm) {
                                return "#fcf151"
                            }
                        })()
                    }}
                    >
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Pulse Train MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.pulseTrain.joulesPerSqmPretty;
                                            } else if (units === 'watts') {
                                                return result.MPE.pulseTrain.wattsPerSqmPretty;
                                            }
                                        })()}
                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                        {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.watts_train > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.watts_train <= 1 && result.MPEExcess.watts_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.watts_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.joulesPerSqm_trainPretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>
                    </Card>
                    <Card style={{
                        backgroundColor: (() => {
                            if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.average.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.single.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm === result.MPE.pulseTrain.joulesPerSqm) {
                                return "null"
                            }
                            else if (result.MPE.mostRestrictive.joulesPerSqm === result.MPE.average.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.single.joulesPerSqm &&
                                result.MPE.mostRestrictive.joulesPerSqm !== result.MPE.pulseTrain.joulesPerSqm) {
                                return "#fcf151"
                            }
                        })()
                    }}>
                        <Flex justify="space-evenly" wrap="wrap">
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Average MPE
                                </strong>
                                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                                    <Typography style={textCenter}>
                                        {(() => {
                                            if (units === 'joules') {
                                                return result.MPE.average.joulesPerSqmPretty;
                                            } else if (units === 'watts') {
                                                return result.MPE.average.wattsPerSqmPretty;
                                            }
                                        })()}
                                    </Typography>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Accessible Emission
                                </strong>
                                <Space>
                                    <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                                        <strong>
                                            <Typography style={textCenter}>
                                                {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Typography>
                                        </strong>
                                    </Tooltip>
                                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                                        <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                                        <strong>
                                        {(() => {
                                                    if (units === 'joules') {
                                                        return result.AE.joulesPerSqmPretty
                                                    } else if (units === 'watts') {
                                                        return result.AE.wattsPerSqmPretty
                                                    }
                                                })()}
                                        </strong>
                                    </Tooltip>
                                </Space></Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Eye Safety Ratio
                                </strong>
                                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                                    <strong style={result.MPEExcess.watts_average > 1 ? {
                                        ...textCenter, color: "#d82424"
                                    } : result.MPEExcess.watts_average <= 1 && result.MPEExcess.watts_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                        : result.MPEExcess.watts_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                                            : null}>
                                        {result.MPEExcess.joulesPerSqm_averagePretty}
                                    </strong>
                                </Tooltip>
                            </Space>
                            <Space direction="vertical">
                                <strong style={textCenter}>
                                    Limit Aperture
                                </strong>
                                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                                    <Typography style={textCenter}>
                                        Eye: {result.limitingAperture.eye} mm
                                    </Typography>
                                    <Typography style={textCenter}>
                                        Skin: {result.limitingAperture.skin} mm
                                    </Typography>
                                </Tooltip>
                            </Space>
                        </Flex>
                    </Card>

                 
                    <Card style={{
                        margin: "5px 0px 0px 0px",
                        backgroundColor: (() => {
                            if (result.MPEExcess.joulesPerSqm > 1) {
                            return "#d82424"
                            }
                            else if (result.MPEExcess.joulesPerSqm <= 0.5) {
                            return "#10c96d"
                            }
                            else if (result.MPEExcess.joulesPerSqm > 0.5 || result.MPEExcess.joulesPerSqm <= 1) {
                            return "#ffa200"
                            }
                        })()
                }}>
                <Typography style={textCenter}>
                    <strong style={{color: "#ffffff"}}>
                    {(() => {

                            if (result.MPEExcess.joulesPerSqm > 1) {
                                return "The configuration that has been entered is Dangerous for the Eye!"
                            }
                            else if (result.MPEExcess.joulesPerSqm <= 0.5) {
                                return "The configuration that has been entered is Safe for the Eye!"
                            }
                            else if (result.MPEExcess.joulesPerSqm > 0.5 || result.MPEExcess.joulesPerSqm <= 1) {
                                return "The configuration that has been entered is Marginally Safe for the Eye!"
                            }
                    })()}
                </strong>
                </Typography>
                    </Card>
                </Card>
                <Card style={{marginBottom: "20px"}}>
                    <Divider><h4><img src={hand} width={50} style={{marginRight:"5px"}}/><strong> SKIN </strong> </h4></Divider>
                    <Card style={{
                            backgroundColor: (() => {
                            if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm)
                            return "#fcf151"
                    })()
                    }}>
                    <Flex justify="space-evenly"  wrap="wrap">
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Single Pulse MPE
                        </strong>
                        <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                            <Typography style={textCenter}>
                            {(() => {
                                if (units === 'joules'){
                                    return result.skinMPE.single.joulesPerSqmPretty.eng + 'J/m²';
                                }else if (units === 'watts'){
                                    return result.skinMPE.single.wattsPerSqmPretty.eng + 'W/m²';
                                }
                                })()}
                            </Typography>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Accessible Emission
                        </strong>
                        <Space>
                            <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';
                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                            <Divider type="vertical" style={{ height: "40px" }}></Divider>
                            <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';
                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                        </Space></Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Skin Safety Ratio
                        </strong>
                        <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                            <strong style={result.skinMPEExcess.joules_single > 1 ? {
                            ...textCenter, color: "#d82424"
                            } : result.skinMPEExcess.joules_single <= 1 && result.skinMPEExcess.joules_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                            : result.skinMPEExcess.joules_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                                : null}>
                            {result.skinMPEExcess.joulesPretty_single.pretty}
                            </strong>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Limit Aperture
                        </strong>
                        <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                            <Typography style={textCenter}>
                            Eye: {result.limitingAperture.eye} mm
                            </Typography>
                            <Typography style={textCenter}>
                            Skin: {result.limitingAperture.skin} mm
                            </Typography>
                        </Tooltip>
                        </Space>
                    </Flex>
                    </Card>

                    <Card style={{
                            backgroundColor: (() => {
                            if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm){
                                    return null
                            }
                            else if(result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm &&
                                    result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.single.joulesPerSqm) {
                                        return "#fcf151"
                                    }
                    })()
                    }}>
                    <Flex justify="space-evenly"  wrap="wrap">
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Pulse Train MPE
                        </strong>
                        <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                            <Typography style={textCenter}>
                            {(() => {
                                if (units === 'joules'){
                                    return result.skinMPE.PulseTrain.joulesPerSqmPretty.eng + 'J/m²';

                                }else if (units === 'watts'){
                                    return result.skinMPE.PulseTrain.wattsPerSqmPretty.eng + 'W/m²';

                                }
                                })()}
                            </Typography>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Accessible Emission
                        </strong>
                        <Space>
                            <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';


                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';

                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                            <Divider type="vertical" style={{ height: "40px" }}></Divider>
                            <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';


                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';

                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                        </Space></Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Skin Safety Ratio
                        </strong>
                        <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                            <strong style={result.skinMPEExcess.joules_train > 1 ? {
                            ...textCenter, color: "#d82424"
                            } : result.skinMPEExcess.joules_train <= 1 && result.skinMPEExcess.joules_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                            : result.skinMPEExcess.joules_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                                : null}>
                            {result.skinMPEExcess.joulesPretty_train.pretty}
                            </strong>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Limit Aperture
                        </strong>
                        <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                            <Typography style={textCenter}>
                            Eye: {result.limitingAperture.eye} mm
                            </Typography>
                            <Typography style={textCenter}>
                            Skin: {result.limitingAperture.skin} mm
                            </Typography>
                        </Tooltip>
                        </Space>
                    </Flex>
                    </Card>

                    <Card style={{
                            backgroundColor: (() => {
                            if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.MPEAverage.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.single.joulesPerSqm &&
                                result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.PulseTrain.joulesPerSqm){
                                return null
                            }
                            else if (result.skinMPE.mostRestrictive.joulesPerSqm === result.skinMPE.MPEAverage.joulesPerSqm &&
                                    result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.single.joulesPerSqm &&
                                    result.skinMPE.mostRestrictive.joulesPerSqm !== result.skinMPE.PulseTrain.joulesPerSqm) {
                                        return "#fcf151"
                            }

                    })()
                    }}>
                    <Flex justify="space-evenly"  wrap="wrap">
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Average MPE
                        </strong>
                        <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                            <Typography style={textCenter}>
                            {(() => {
                                if (units === 'joules'){
                                    return result.skinMPE.MPEAverage.joulesPerSqmPretty.eng + 'J/m²';


                                }else if (units === 'watts'){
                                    return result.skinMPE.MPEAverage.wattsPerSqmPretty.eng + 'W/m²';

                                }
                                })()}
                            </Typography>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Accessible Emission
                        </strong>
                        <Space>
                            <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';


                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                            <Divider type="vertical" style={{ height: "40px" }}></Divider>
                            <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                            <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                            <strong>
                                <Typography style={textCenter}>
                                {(() => {
                                if (units === 'joules'){
                                    return result.skinAE.joulesPerSqmPretty.eng + 'J/m²';

                                }else if (units === 'watts'){
                                    return result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                                }
                                })()}
                                </Typography>
                            </strong>
                            </Tooltip>
                        </Space></Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Skin Safety Ratio
                        </strong>
                        <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                            <strong style={result.skinMPEExcess.joules_average > 1 ? { ...textCenter, color: "#d82424" }
                            : result.skinMPEExcess.joules_average <= 1 && result.skinMPEExcess.joules_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                                : result.skinMPEExcess.joules_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                                : null}>
                            {result.skinMPEExcess.joulesPretty_average.pretty}
                            </strong>
                        </Tooltip>
                        </Space>
                        <Space direction="vertical">
                        <strong style={textCenter}>
                            Limit Aperture
                        </strong>
                        <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                            <Typography style={textCenter}>
                            Eye: {result.limitingAperture.eye} mm
                            </Typography>
                            <Typography style={textCenter}>
                            Skin: {result.limitingAperture.skin} mm
                            </Typography>
                        </Tooltip>
                        </Space>
                    </Flex>
                    </Card>
                    <Card style={{
                            margin: "5px 0px 0px 0px",
                            backgroundColor: (() => {
                                if (result.skinMPEExcess.joules > 1) {
                                return "#d82424"
                                }
                                else if (result.skinMPEExcess.joules <= 0.5) {
                                return "#10c96d"
                                }
                                else if (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <= 1) {
                                return "#ffa200"
                                }
                            })()
                    }}>
                    <Typography style={textCenter}>
                        <strong style={{color: "#ffffff"}}>
                        {(() => {

                                if (result.skinMPEExcess.joules > 1) {
                                    return "The configuration that has been entered is Dangerous for the Skin!"
                                }
                                else if (result.skinMPEExcess.joules <= 0.5) {
                                    return "The configuration that has been entered is Safe for the Skin!"
                                }
                                else if (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <= 1) {
                                    return "The configuration that has been entered is Marginally Safe for the Skin!"
                                }
                        })()}
                    </strong>
                    </Typography>
                    </Card>
                </Card>
            </>
            }]} />
    <Card>
        <Divider><h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4></Divider>
        <Row justify="center" >

                <ResultCard>
                    <Space direction="vertical">
                    <strong style={textCenter}>Beam Output</strong>
                        <Tooltip title={`The energy per pulse.`}>
                            <Typography style={textCenter}>
                            Energy:  {result.beamOutput.pulseEnergy.pretty}
                            </Typography>
                        </Tooltip>

                        <Tooltip title={`The maximum power that the laser pulse will attain.`}>
                            <Typography style={textCenter}>
                            Peak Power: {result.beamOutput.peakPower.pretty}
                            </Typography>
                        </Tooltip>

                        <Tooltip title={`The average power over one cycle.`}>
                            <Typography style={textCenter}>
                            Average Power: {result.beamOutput.averagePower.pretty}
                            </Typography>
                        </Tooltip>
                    </Space>

                </ResultCard>

                <ResultCard>
                    <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
                        <Flex justify="center"><strong>NOHD</strong></Flex>
                        <Flex justify="center">{result?.NOHD?.isError ? result?.NOHD?.message : result?.NOHD?.value?.pretty}</Flex>
                    </div>
                </ResultCard>
                <ResultCard>
                    <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
                        <Flex justify="center"><strong>ENOHD</strong></Flex>
                        <Flex justify="center">{result?.ENOHD?.value !== null ? result?.ENOHD?.value?.pretty : 'N/A'}</Flex>
                    </div>
                                        </ResultCard>
                <ResultCard>
                    <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
                        <Flex justify="center"><strong>Spot Size</strong></Flex>
                        <Flex justify="center">{result.outputBeamDiameter}</Flex>
                    </div>

                </ResultCard>
                <ResultCard>
                    <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}>
                        <Flex justify="center"><strong>Beam Area</strong></Flex>
                        <Flex justify="center">
                            <Space direction="vertical">
                                <Flex justify="center"><strong>Actual</strong></Flex>
                                <Flex>{result.beamArea.actualPretty}</Flex>
                            </Space>
                            <Divider orientation="center" type="vertical" />
                            <Space direction="vertical">
                                <Flex><strong>Applicable</strong></Flex>
                                <Flex>{result.beamArea.applicablePretty}</Flex>
                            </Space>
                        </Flex>
                    </div>
                </ResultCard>

                <ResultCard  style={{width: "200px", height: "100px",display:"flex", justifyContent: "center", alignItems:"center"}}>
                    <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}>
                    <Flex justify="center"><strong> Spectrum</strong></Flex>
                        <Row justify={"center"}>
                            <div style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: (() => {
                                        if (result.spectrum.range == "Infrared") {
                                            return "maroon"
                                        }
                                        else if (result.spectrum.range == "Ultraviolet") {
                                            return "purple"
                                        }
                                            return "rgb("+result.spectrum.rgb +")"
                                        })(),
                                borderRadius: 100,
                                justifyContent: "center"
                            }}>
                            </div>
                        </Row>
                        <Row justify={"center"}>{result.spectrum.region}</Row>
                    </div>
                </ResultCard>
        </Row>
    </Card>


    </>
      },
      {
            key: 2,
            label: 'More Information',
            children:
                        <>
                                <Divider>Correction Factors and Break Points</Divider>
                                <Flex justify="center">
                                    <Space>
                                        {result.correctionFactors.map((cf => {
                                            return (
                                                <Card>
                                                    <Space direction="vertical">
                                                        <Flex justify="center"><strong>{cf.name}</strong></Flex>
                                                        <Flex justify="center">{cf.pretty}</Flex>
                                                    </Space>
                                                </Card>
                                            )
                                        }))}
                                    </Space>
                                </Flex>
                                <Divider>Pulse Data</Divider>
                                {result.isEmployed ? 
                                    <Flex justify="center" gap="middle">
                                        <Typography.Text type='secondary'><i>Multiple Pulses Occur Within the Period Ti</i></Typography.Text>
                                    </Flex> 
                                    : null}
                                
                                <Flex justify="center" gap="middle" wrap="wrap">
                                    <Card>
                                        <strong style={textCenter}>Pulse Train Duration</strong>
                                        <Typography style={textCenter}>
                                            {result.pulseTrainDuration}
                                        </Typography>
                                    </Card>

                                    <Card>
                                        <strong style={textCenter}>Number of Pulses</strong>
                                        <Typography style={textCenter}>
                                            {result.Nv2.pretty}
                                        </Typography>
                                    </Card>
                                    {result.isEmployed ? 
                                    <>
                                        <Card>
                                        <strong style={textCenter}>Tᵢ</strong>
                                            <Typography style={textCenter}>
                                            {result.T_i}
                                            </Typography>
                                        </Card>

                                        <Card>
                                        <strong style={textCenter}>Pulses Contained in the Period Tᵢ</strong>
                                            <Typography style={textCenter}>
                                            {result.PulsesWithinT_i}
                                            </Typography>
                                        </Card>
                                    </>
                                    : null}
                                </Flex>
                                <Divider>Angular Parameters</Divider>
                                <Flex justify="center">
                                    <Space>
                                        <Card>
                                            <Space direction="vertical">
                                                <Flex justify="center"><strong>Angular Subtense</strong></Flex>
                                                <Flex justify="center">{result.angularSubtense}</Flex>
                                            </Space>
                                        </Card>

                                        {/* <Card>
                                            <Space direction="vertical">
                                                <Flex justify="center"><strong>Angle of Acceptance</strong></Flex>
                                                <Flex justify="center">{result.MPE.angleOfAcceptancePretty}</Flex>
                                            </Space>
                                        </Card> */}
                                    </Space>
                                </Flex>

                            </>
        },
        {
            key: 3,
            label: "Laser Class Details",
            children:
                <>
                <Row justify="space-evenly">
                    <Divider orientation="center">  Accessible Emission Limits </Divider>
                    {/* <LaserClassResultsV2 laserClass={result.laserClass} condition={1} />
                    <LaserClassResultsV2 laserClass={result.laserClass} condition={3} /> */}
                    <LaserClassResultsDiffuseReflective condition={1} result={result} laserOperation={laserOperation} kani={"kani"}/>
                    <br />
                    <LaserClassResultsDiffuseReflective condition={3} result={result} laserOperation={laserOperation}/>
                </Row>
                <LaserClassVerdict laserClass={result.Class} />
                </>
        },{
            key: 4,
            label: "Laser Protective Eyewear",
            children: (
                <>  <EyewearResults props={props} />
                    <Row justify={'center'}>
                        <Eyewear />
                    </Row></>
            ),
        }
        ]} />);
}
