import { Card } from "antd";
import React from "react";
import Field from "../../../../components/Field";

export function DRCWFields() {
    return (<Card title={`CW Settings`}>
        {
            /* Wavelength */
        }
        <Field
            name={['diffuseReflectiveCW', 'wavelength']}
            denoms={['u', 'n']}
            unit='m'
            min='180'
            max='1000000'
            label='Wavelength'
            placeholder='Enter wavelength (180nm - 1,000,000nm)'
            toolTipText={'The operating wavelength of the laser beam.'}
        />
        {
            /* Exposure */
        }
        <Field isCW name={['diffuseReflectiveCW', 'time']} unit='s' denoms={[false, 'm', 'u', 'n', 'p', 'f']} label='Exposure Duration' placeholder='Enter emission duration' min={0.25} toolTipText='The total time period in seconds for which direct eye exposure to laser radiation is anticipated.' />

        {
            /* Power */
        }
        <Field name={['diffuseReflectiveCW', 'power']} unit='W' denoms={['G', 'M', 'k', false, 'm', 'u', 'n', 'p']} label={'Laser Output Power'} toolTipText={'The continuous power output of the laser beam.'} placeholder={`Enter laser's power`} />

        {
            /* Beam Diameter */
        }
        <Field name={['diffuseReflectiveCW', 'beamDiameter']} label='Beam Diameter' unit='mm' placeholder={`Enter laser's beam diameter`} toolTipText={`The diameter of the source of the laser beam.`} />
        {
            /* Beam Divergence */
        }
        <Field name={['diffuseReflectiveCW', 'beamDivergence']} label='Beam Divergence' unit='rad' denoms={['m', false, 'degree']} placeholder={`Enter laser's beam divergence`} toolTipText={`The far field plane angle of the cone defined by the beam diameter.`} />

        <Field name={['diffuseReflectiveCW', 'distance']} label='Laser to Surface Distance' unit='m' denoms={['k', false, 'm', 'c', 'u', 'n']} placeholder={`Enter distance between laser and reflecting surface`} toolTipText={`The distance between the source of the laser and the reflecting surface.`} />




    </Card>);
}
