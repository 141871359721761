import { Card, Row, Space, Typography, Tabs, Divider, Tooltip, Flex, Radio } from "antd";
import React, { useState } from "react";
import ResultCard from "../../../../../components/ResultCard";
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import { LaserClassResultsV2 } from "./LaserClassResultsV2";
import LaserClassVerdict from "./LaserClassVerdict"
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import { Infograph } from "../../../../../components/infographicsModal/Infograph"
import { EyewearResults } from "../../ResultComponents/EyewearResults";

export function ResultRepetitivelyPulsed(props) {
  const textCenter = props.textCenter
  let message = "The LB number indicates the level of protection provided by glass/plastic. ".concat((props.result.Glass != null && props.result.Plastic != null) ? ""
        : "If the pulse duration exceeds the exposure duration specified in Table B.1, a scale number for I is not applicable, and only a scale number for the D mode should be computed accordingly.")
  
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [info, setInfo] = useState(null)

  const handleClick = (e) => {
    setIsModalOpen(true)
    setInfo(e)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Tabs defaultActiveKey="1" items={[{
      key: 1,
      label: "MPE Details",
      children: <>
         <Infograph
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          info={info}
        />
        <Card style={{marginBottom: "20px"}}>
          <Divider> <h4><img src={eye} width={50} style={{marginRight:"5px"}}/><strong>EYE</strong></h4></Divider>
          <Card style={{
            backgroundColor: (() => {
              if (props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.pulseTrain.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.single.joulesPerSqm)
                return "#fcf151"
            })()
          }}>
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Single Pulse MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the eye may be exposed to a single pulse laser without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.MPE.single.joulesPerSqmPretty.eng + 'J/m²';
                      } else if (props.units === 'watts') {
                        return props.result.MPE.single.wattsPerSqmPretty.eng + 'W/m²';
                      }
                    })()}

                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.actual_joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.actual_wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Eye Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                  <strong style={props.result.MPEexcess.joules_single > 1 ? {
                    ...textCenter, color: "#d82424"
                  } : props.result.MPEexcess.joules_single <= 1 && props.result.MPEexcess.joules_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                    : props.result.MPEexcess.joules_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                      : null}>
                    {props.result.MPEexcess.joulesPretty_single.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>

          </Card>

          <Card style={{
            backgroundColor: (() => {
              if (props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.pulseTrain.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.single.joulesPerSqm) {
                return null
              }
              else if (props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.pulseTrain.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm !== props.result.MPE.single.joulesPerSqm) {
                return "#fcf151"
              }
            })()
          }}>
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Pulse Train MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.MPE.pulseTrain.joulesPerSqmPretty;
                      } else if (props.units === 'watts') {
                        return props.result.MPE.pulseTrain.wattsPerSqmPretty;
                      }
                    })()}
                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.actual_joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.actual_wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Eye Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                  <strong style={props.result.MPEexcess.joules_train > 1 ? {
                    ...textCenter, color: "#d82424"
                  } : props.result.MPEexcess.joules_train <= 1 && props.result.MPEexcess.joules_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                    : props.result.MPEexcess.joules_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                      : null}>
                    {props.result.MPEexcess.joulesPretty_train.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>
          </Card>
          <Card style={{
            backgroundColor: (() => {
              if (props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.average.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.single.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.pulseTrain.joulesPerSqm) {
                return "null"
              }
              else if (props.result.MPE.mostRestrictive.joulesPerSqm === props.result.MPE.average.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm !== props.result.MPE.single.joulesPerSqm &&
                props.result.MPE.mostRestrictive.joulesPerSqm !== props.result.MPE.pulseTrain.joulesPerSqm) {
                return "#fcf151"
              }
            })()
          }}
          >
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Average MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the eye may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.MPE.average.joulesPerSqmPretty;
                      } else if (props.units === 'watts') {
                        return props.result.MPE.average.wattsPerSqmPretty;
                      }
                    })()}
                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.AE.actual_joulesPerSqmPretty;
                          } else if (props.units === 'watts') {
                            return props.result.AE.actual_wattsPerSqmPretty;
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Eye Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>
                  <strong style={props.result.MPEexcess.joules_average > 1 ? {
                    ...textCenter, color: "#d82424"
                  } : props.result.MPEexcess.joules_average <= 1 && props.result.MPEexcess.joules_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                    : props.result.MPEexcess.joules_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                      : null}>
                    {props.result.MPEexcess.joulesPretty_average.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>
          </Card>
          <Card style={{
            margin: "5px 0px 0px 0px",
            backgroundColor: (() => {
              if (props.result.MPEexcess.joules > 1) {
                return "#d82424"
              }
              else if (props.result.MPEexcess.joules <= 0.5) {
                return "#10c96d"
              }
              else if (props.result.MPEexcess.joules > 0.5 || props.result.MPEexcess.joules <= 1) {
                return "#ffa200"
              }
            })()
          }}>
            <Typography style={textCenter}>
              <strong style={{ color: "#ffffff" }}>
                {(() => {

                  if (props.result.MPEexcess.joules > 1) {
                    return "The configuration that has been entered is Dangerous for the Eye! "
                  }
                  else if (props.result.MPEexcess.joules <= 0.5) {
                    return "The configuration that has been entered is Safe for the Eye! "
                  }
                  else if (props.result.MPEexcess.joules > 0.5 || props.result.MPEexcess.joules <= 1) {
                    return "The configuration that has been entered is Marginally Safe for the Eye! "
                  }
                })()}
                {props.result.MPEexcess.joulesPretty.pretty} x MPE
              </strong>
            </Typography>
          </Card>
        </Card>

        <Card style={{marginBottom: "20px"}}>
          <Divider><h4><img src={hand} width={50} style={{marginRight:"5px"}}/><strong> SKIN </strong> </h4></Divider>
          {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>SKIN<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

          <Card style={{
            backgroundColor: (() => {
              if (props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.single.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.PulseTrain.joulesPerSqm)
                return "#fcf151"
            })()
          }}>
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Single Pulse MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the skin may be exposed to a single pulse laser without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.skinMPE.single.joulesPerSqmPretty.eng + 'J/m²';
                      } else if (props.units === 'watts') {
                        return props.result.skinMPE.single.wattsPerSqmPretty.eng + 'W/m²';
                      }
                    })()}
                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.joulesPerSqmPretty.eng + 'J/m²';
                          } else if (props.units === 'watts') {
                            return props.result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.actual_joulesPerSqmPretty.eng + 'J/m²';
                          } else if (props.units === 'watts') {
                            return props.result.skinAE.actual_wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Skin Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                  <strong style={props.result.skinMPEExcess.joules_single > 1 ? {
                    ...textCenter, color: "#d82424"
                  } : props.result.skinMPEExcess.joules_single <= 1 && props.result.skinMPEExcess.joules_single >= 0.5 ? { ...textCenter, color: "#ffa200" }
                    : props.result.skinMPEExcess.joules_single < 0.5 ? { ...textCenter, color: "#10c96d" }
                      : null}>
                    {props.result.skinMPEExcess.joulesPretty_single.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>
          </Card>


          <Card style={{
            backgroundColor: (() => {
              if (props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.PulseTrain.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.single.joulesPerSqm) {
                return null
              }
              else if (props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.PulseTrain.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm !== props.result.skinMPE.single.joulesPerSqm) {
                return "#fcf151"
              }
            })()
          }}>
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Pulse Train MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the skin may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.skinMPE.PulseTrain.joulesPerSqmPretty.eng + 'J/m²';

                      } else if (props.units === 'watts') {
                        return props.result.skinMPE.PulseTrain.wattsPerSqmPretty.eng + 'W/m²';

                      }
                    })()}
                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.joulesPerSqmPretty.eng + 'J/m²';


                          } else if (props.units === 'watts') {
                            return props.result.skinAE.wattsPerSqmPretty.eng + 'W/m²';

                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.actual_joulesPerSqmPretty.eng + 'J/m²';


                          } else if (props.units === 'watts') {
                            return props.result.skinAE.actual_wattsPerSqmPretty.eng + 'W/m²';

                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Skin Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                  <strong style={props.result.skinMPEExcess.joules_train > 1 ? {
                    ...textCenter, color: "#d82424"
                  } : props.result.skinMPEExcess.joules_train <= 1 && props.result.skinMPEExcess.joules_train >= 0.5 ? { ...textCenter, color: "#ffa200" }
                    : props.result.skinMPEExcess.joules_train < 0.5 ? { ...textCenter, color: "#10c96d" }
                      : null}>
                    {props.result.skinMPEExcess.joulesPretty_train.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>
          </Card>

          <Card style={{
            backgroundColor: (() => {
              if (props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.MPEAverage.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.single.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.PulseTrain.joulesPerSqm) {
                return null
              }
              else if (props.result.skinMPE.mostRestrictive.joulesPerSqm === props.result.skinMPE.MPEAverage.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm !== props.result.skinMPE.single.joulesPerSqm &&
                props.result.skinMPE.mostRestrictive.joulesPerSqm !== props.result.skinMPE.PulseTrain.joulesPerSqm) {
                return "#fcf151"
              }

            })()
          }}>
            <Flex justify="space-evenly" wrap="wrap">
              <Space direction="vertical">
                <strong style={textCenter}>
                  Average MPE
                </strong>
                <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which the skin may be exposed to all the pulses for the duration of the exposure time frame without suffering adverse effects."}>
                  <Typography style={textCenter}>
                    {(() => {
                      if (props.units === 'joules') {
                        return props.result.skinMPE.MPEAverage.joulesPerSqmPretty.eng + 'J/m²';


                      } else if (props.units === 'watts') {
                        return props.result.skinMPE.MPEAverage.wattsPerSqmPretty.eng + 'W/m²';

                      }
                    })()}
                  </Typography>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Accessible Emission
                </strong>
                <Space>
                  <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area."}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.joulesPerSqmPretty.eng + 'J/m²';


                          } else if (props.units === 'watts') {
                            return props.result.skinAE.wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                    <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                    <strong>
                      <Typography style={textCenter}>
                        {(() => {
                          if (props.units === 'joules') {
                            return props.result.skinAE.actual_joulesPerSqmPretty.eng + 'J/m²';

                          } else if (props.units === 'watts') {
                            return props.result.skinAE.actual_wattsPerSqmPretty.eng + 'W/m²';
                          }
                        })()}
                      </Typography>
                    </strong>
                  </Tooltip>
                </Space></Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Skin Safety Ratio
                </strong>
                <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                  <strong style={props.result.skinMPEExcess.joules_average > 1 ? { ...textCenter, color: "#d82424" }
                    : props.result.skinMPEExcess.joules_average <= 1 && props.result.skinMPEExcess.joules_average >= 0.5 ? { ...textCenter, color: "#ffa200" }
                      : props.result.skinMPEExcess.joules_average < 0.5 ? { ...textCenter, color: "#10c96d" }
                        : null}>
                    {props.result.skinMPEExcess.joulesPretty_average.pretty}
                  </strong>
                </Tooltip>
              </Space>
              <Space direction="vertical">
                <strong style={textCenter}>
                  Limit Aperture
                </strong>
                <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
                  <Typography style={textCenter}>
                    Eye: {props.result.limitingAperture.eye} mm
                  </Typography>
                  <Typography style={textCenter}>
                    Skin: {props.result.limitingAperture.skin} mm
                  </Typography>
                </Tooltip>
              </Space>
            </Flex>
          </Card>

          <Card style={{
            margin: "5px 0px 0px 0px",
            backgroundColor: (() => {
              if (props.result.skinMPEExcess.joules > 1) {
                return "#d82424"
              }
              else if (props.result.skinMPEExcess.joules <= 0.5) {
                return "#10c96d"
              }
              else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                return "#ffa200"
              }
            })()
          }}>
            <Typography style={textCenter}>
              <strong style={{ color: "#ffffff" }}>
                {(() => {

                  if (props.result.skinMPEExcess.joules > 1) {
                    return "The configuration that has been entered is Dangerous for the Skin! "
                  }
                  else if (props.result.skinMPEExcess.joules <= 0.5) {
                    return "The configuration that has been entered is Safe for the Skin! "
                  }
                  else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                    return "The configuration that has been entered is Marginally Safe for the Skin! "
                  }
                })()}
                {props.result.skinMPEExcess.joulesPretty.pretty} x MPE
              </strong>
            </Typography>
          </Card>
        </Card>

        <Card>
          <Divider><h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4></Divider>
          <Flex justify="center" gap="middle" wrap="wrap">
            <ResultCard>

              <Space direction="vertical">
                <strong style={textCenter}>Beam Output</strong>

                <Tooltip title={`The energy per pulse.`}>
                  <Typography style={textCenter}>
                    Energy:  {props.result.beamOutput.pulseEnergy.pretty}
                  </Typography>
                </Tooltip>

                <Tooltip title={`The maximum power that the laser pulse will attain.`}>
                  <Typography style={textCenter}>
                    Peak Power: {props.result.beamOutput.peakPower.pretty}
                  </Typography>
                </Tooltip>

                <Tooltip title={`The average power over one cycle.`}>
                  <Typography style={textCenter}>
                    Average Power: {props.result.beamOutput.averagePower.pretty}
                  </Typography>
                </Tooltip>

              </Space>
            </ResultCard>
            <ResultCard bodyStyle={{ height: "100%" }}>
              <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
              <strong style={textCenter}>
                N.O.H.D
              </strong>
              <Tooltip title={"Distance from the output aperture beyond which the beam irradiance or radiant exposure remains below the appropriate corneal maximum permissible exposure."}>
                <Typography style={textCenter}>
                  {props.result.NOHD.eng}m
                </Typography>
              </Tooltip>
              </div>
            </ResultCard>

            <ResultCard>
              <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
              <strong style={textCenter}>
                E.N.O.H.D.
              </strong>
              <Tooltip
                // title={"The distance from a laser over which the MPE for the eye may be exceeded, taking into account the possible use of magnifying instruments like telescopes and binoculars."}
                title={props.result.ENOHD === 0 ? ("ENOHD only applies to wavelengths between 302.5nm ~ 4000nm") : ("The distance from a laser over which the MPE for the eye may be exceeded, taking into account the possible use of magnifying instruments like telescopes and binoculars.")}
              >
                <Typography style={textCenter}>
                  {props.result.ENOHD === 0 ? ("N/A") : (
                    props.result.ENOHD.pretty
                  )}
                </Typography>
              </Tooltip>
              </div>
            </ResultCard>


            <ResultCard>
              <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
              <Space direction="vertical" style={{ height: "100%" }}>
                <strong style={textCenter}>
                  Spot Size
                </strong>
                <Tooltip title={"The diameter of the beam at the target distance."}>
                  <Typography style={textCenter}>
                    {props.result.outputBeamDiameter.outputBeamDiameterPretty}
                  </Typography>
                </Tooltip>
              </Space>
              </div>
            </ResultCard>
            <ResultCard>
              <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}> 
              <Tooltip title={'Area of the spot size or limit aperture - whichever is the largest.'}>
                <strong style={textCenter}>
                  Applicable Beam Area
                </strong>
                <Typography style={textCenter}>
                  Eye: {props.result.applicableBeamArea.applicableBeamAreaPrettiest}
                </Typography>

                <Typography style={textCenter}>
                  Skin: {props.result.skinApplicableArea.skinApplicableAreaPrettiest}
                </Typography>
              </Tooltip>
              </div>
            </ResultCard>

            <ResultCard style={{ width: "500px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}>
              <strong style={textCenter}>
                Spectrum
              </strong>
              <Row justify={"center"}>
                <div style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: (() => {
                    if (props.result.spectrum.range == "Infrared") {
                      return "maroon"
                    }
                    else if (props.result.spectrum.range == "Ultraviolet") {
                      return "purple"
                    }
                    return "rgb(" + props.result.spectrum.rgb + ")"
                  })(),
                  borderRadius: 100,
                  ustifyContent: "center"
                }}></div></Row>
              <Row justify={"center"}>{props.result.spectrum.region}</Row>
            </div>
            </ResultCard>
          </Flex>
        </Card>
      </>
    }, {
      key: 2,
      label: "More Information",
      children: <Row justify={"center"} style={{ margin: "24px 0 0" }}>
        <Card>
          <Row justify="center" align="center">
            <Divider orientation="center">
              Correction Factors & Time Bases
            </Divider>
            {props.result.correctionFactors.map(cf => {
              if (cf.val !== null) {
                return <ResultCard>
                  <strong style={textCenter}>
                    {cf.name}
                  </strong>
                  <Typography style={textCenter}>
                    {cf.pretty}
                  </Typography>
                </ResultCard>;
              }
            })}
            <Divider>Pulse Data</Divider>
            {props.result.isEmployed ? 
              <Flex justify="center" gap="middle">
                <Typography.Text type='secondary'><i>Multiple Pulses Occur Within the Period Ti</i></Typography.Text>
              </Flex> 
            : null}
            <Flex justify="center" gap="middle" wrap="wrap">
              <Card>
                <strong style={textCenter}>Pulse Train Duration</strong>
                <Typography style={textCenter}>
                  {props.result.pulseTrainDuration}
                </Typography>
              </Card>

              <Card>
                <strong style={textCenter}>Number of Pulses</strong>
                <Typography style={textCenter}>
                  {props.result.Nv2.pretty}
                </Typography>
              </Card>
              {props.result.isEmployed ? 
              <>
                <Card>
                  <strong style={textCenter}>Tᵢ</strong>
                  <Typography style={textCenter}>
                    {props.result.T_i}
                  </Typography>
                </Card>

                <Card>
                  <strong style={textCenter}>Pulses Contained in the Period Tᵢ</strong>
                  <Typography style={textCenter}>
                  {props.result.PulsesWithinT_i}
                  </Typography>
                </Card>
                </>
              : null }
            </Flex>
          </Row>
        </Card>
      </Row>
    }, {
      key: 3,
      label: "Laser Class Details",
      children: 
      <>
        <Row justify="space-evenly">
          <Divider orientation="center">
            Accessible Emission Limits
          </Divider>
          {/* <LaserClassResults laserClass={props.result.laserClass} /> */}
          <LaserClassResultsV2 laserClass={props.result.laserClass} condition={1} />
          <LaserClassResultsV2 laserClass={props.result.laserClass} condition={3} />
        </Row>
          <LaserClassVerdict laserClass={props.result.laserClass} />
      </>
    }, {
      key: 4,
      label: "Laser Protective Eyewear",
      children: <>
        <Row justify="center" align="center" style={{textAlign:"center"}}>
         <EyewearResults props={props}/>
        </Row>
        <Row justify={'center'} style={{textAlign:"center"}}>
          <Eyewear />
        </Row></>
    }]} />);
}
