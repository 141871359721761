import svg from "../../assets/svgs/pdf_template.svg";
import { Canvg, presets } from 'canvg'


const { parse, stringify } = require("svgson");
const preset = presets.offscreen()

export function fetchSVJSON() {
  return new Promise((resolve, reject) => {
    fetch(svg)
      .then((fetchedSVG) => {
        // fetchedSVG needs to be converted to text.
        fetchedSVG.text()
          .then((svgText) => {
            parse(svgText, [])
              .then((svJSON) => {
                console.log(svJSON);
                let localsvJSON = svJSON
                let { children } = localsvJSON


                console.log(`svgJSON: `, children)


                const placeHoldersToFind = ['tbWavelength', 'tbLaserOutput', 'tbExposureDuration', 'tbBeamDiameter', 'tbBeamDivergence', 'tbDistanceToTarget']

                if (children !== undefined && children !== null) {
                  placeHoldersToFind.forEach((thisPlaceHolder) => {
                    let indexOfPlaceholder = children.findIndex((thisElement, index) => {
                      // console.log(`this message: `, thisElement.attributes?.id, thisPlaceHolder)
                      if (thisElement.attributes?.id === thisPlaceHolder) {
                        console.log(`id found, index to return: `, index)
                        // Placeholder found!
                        // return the index.
                        return index
                      }
                    })
                    console.log(`indexOfPlaceHolder`, indexOfPlaceholder)

                    if (indexOfPlaceholder !== null && indexOfPlaceholder !== undefined && indexOfPlaceholder !== -1) {
                      // The index has a value, so we use that value to change the text
                      console.log(children)
                      children[indexOfPlaceholder].children[0].value = '100mW'
                    }
                  })

                  // At the end of the check, replace the svJSON with the children.
                  localsvJSON.children = children
                  console.log(`prevChildren`, localsvJSON)

                  // Once all values are changed, stringify the new svg.

                  let svgpic = stringify(localsvJSON)

                  const toPNG = (data) => {
                    return new Promise(async (resolve, reject) => {
                      const { width, height, svg } = data
                      const canvas = new OffscreenCanvas(width, height)
                      const ctx = canvas.getContext('2d')
                      const v = Canvg.fromString(ctx, svg, preset)

                      // Render only first frame, ignoring animations and mouse
                      await v.render()
                      const blob = await canvas.convertToBlob()

                      // const buff = await blob.arrayBuffer()

                      // resolve(buff)

                      const buff = URL.createObjectURL(blob)
                      resolve(buff)
                    })
                  }

                  toPNG({
                    width: 1000,
                    height: 1000,
                    svg: svgpic
                  })
                    .then((buffer) => {
                      console.log(`My url: `, buffer)
                      resolve(buffer)
                    })
                }
              })
              .catch((err) => { reject(err) })
          })
          .catch(err => reject(err))
      })
      .catch((err) => {
        reject(err)
      });
  });
}
