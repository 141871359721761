import React, {useState, useEffect} from "react";
import { Card, Row, Tabs, Col, Typography, Divider, Tooltip, Button } from "antd";
import ResultCard from "./ResultCard";
import { FilePdfOutlined } from "@ant-design/icons"
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { LookUpTablePDF } from "./PDF/LookUpTablePDF"
import {FullscreenExitOutlined, FullscreenOutlined, DownloadOutlined } from "@ant-design/icons"
import ReactPDF from '@react-pdf/renderer';
import { tab } from "@testing-library/user-event/dist/tab";
// import { ResultSinglePulse } from "../pages/Loginv2/Calculators/Results/ResultSinglePulse";

export function PointResults({
    textCenter,
    results,
    toggleFullscreen,
    isFullScreen
}) {
    // const tabList = [
    //     {
    //         key: '1',
    //         label: 'MPE & AEL'
    //     },
    //     {
    //         key: '2',
    //         label: 'Correction Factors'
    //     },
    //     {
    //         key: '3',
    //         label: 'Conditions'
    //     }
    // ]
    // const contentList = {
    //     1:
    //         <>
    //             {/* MPE & AEL */}
    //             <Card style={{
    //                 margin: '24px 0 0'
    //             }}>
    //                 {/* <Row justify='center'><strong>AEL & MPE</strong></Row> */}
    //                 <Divider>Accessible Emission Limit</Divider>
    //                 <Row justify='center'>

    //                     <ResultCard>
    //                         <strong style={textCenter}>Class 1 AEL</strong>
    //                         {results.ael.class1.joules !== null ? <>
    //                             <Typography style={textCenter}>{results.ael.class1.joulesPretty}</Typography>
    //                             <Typography style={textCenter}>{results.ael.class1.wattsPretty}</Typography>
    //                         </> : <>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                         </>}
    //                     </ResultCard>

    //                     <ResultCard>
    //                         <strong style={textCenter}>Class 2 AEL</strong>
    //                         {results.ael.class2.joules !== null ? <>
    //                             <Typography style={textCenter}>{results.ael.class2.joulesPretty}</Typography>
    //                             <Typography style={textCenter}>{results.ael.class2.wattsPretty}</Typography>
    //                         </> : <>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                         </>}
    //                     </ResultCard>

    //                     <ResultCard>
    //                         <strong style={textCenter}>Class 3R AEL</strong>
    //                         {results.ael.class3r.joules !== null ? <>
    //                             <Typography style={textCenter}>{results.ael.class3r.joulesPretty}</Typography>
    //                             <Typography style={textCenter}>{results.ael.class3r.wattsPretty}</Typography>
    //                         </> : <>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                         </>}
    //                     </ResultCard>

    //                     <ResultCard>
    //                         <strong style={textCenter}>Class 3B AEL</strong>
    //                         {results.ael.class3b.joules !== null ? <>
    //                             <Typography style={textCenter}>{results.ael.class3b.joulesPretty}</Typography>
    //                             <Typography style={textCenter}>{results.ael.class3b.wattsPretty}</Typography>
    //                         </> : <>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                             <Typography style={textCenter}>N/A</Typography>
    //                         </>}
    //                     </ResultCard>
    //                 </Row>
    //                 <Divider/>
    //                 <Row justify={"space-evenly"} >
    //                     <Col>
    //                     <Divider style={{display:""}}>Maximum Permissible Emission</Divider>
    //                     <Row justify={"space-around"}>
    //                         <Card>
    //                             <strong style={textCenter}>Ocular MPE</strong>
    //                             <Typography style={textCenter}>{results.mpe.eye.joulesPerSqmPretty.eng}J/m²</Typography>
    //                             <Typography style={textCenter}>{results.mpe.eye.wattsPerSqmPretty.eng}W/m²</Typography>
    //                         </Card>

    //                         <Card>
    //                             <strong style={textCenter}>Skin MPE</strong>
    //                             <Typography style={textCenter}>{results.mpe.skin.joulesPerSqmPretty.eng}J/m²</Typography>
    //                             <Typography style={textCenter}>{results.mpe.skin.wattsPerSqmPretty.eng}W/m²</Typography>
    //                         </Card>
    //                     </Row>  
    //                     </Col>
    //                 <Divider type="vertical" style={{height: "40vh"}} />
    //                     <Col>
    //                         <ResultCard>
    //                             <strong style={textCenter}>
    //                                 Limit Aperture
    //                             </strong>
    //                             <Tooltip title={"The circular area over which irradiance and radiant exposure is averaged."}>
    //                                 <Typography style={textCenter}>
    //                                     Eye: {results.limitingAperture.eye} mm
    //                                 </Typography>
    //                                 <Typography style={textCenter}>
    //                                     Skin: {results.limitingAperture.skin} mm
    //                                 </Typography>
    //                             </Tooltip>
    //                         </ResultCard>
    //                         {results.calcType === 'extended' ? (
    //                             <ResultCard>
    //                                 <strong style={textCenter}>Angular Subtense</strong>
    //                                 <Tooltip title='The visual angle subtended by the apparent source size of the laser.'>
    //                                     <Typography style={textCenter}>{results.angularSubtense.eng}rad</Typography>
    //                                 </Tooltip>
    //                             </ResultCard>
    //                         ) : null}
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </>,
        
    //     2:
    //         <>
    //             {/* Correction Factors Card */}
    //             <Card>
    //                 <Row justify='center'><strong>Correction Factors:</strong></Row>
    //                 <Row justify='center'>
    //                     {results.corectionFactors.map(cf => {
    //                         return <ResultCard>
    //                             <strong style={textCenter}>{cf.name}</strong>
    //                             <Typography style={textCenter}>{cf.pretty}</Typography>
    //                         </ResultCard>;
    //                     })}
    //                 </Row>
    //             </Card>
    //         </>,
    //     3:
    //         <>
    //             {/* Conditions */}
    //             <Card style={{ margin: '24px 0 0' }}>
    //                 <Row justify='center' style={{ margin: '24px 0 0' }} gutter={[10, 10]} >
    //                     <Col lg={12} sm={24}>
    //                         <Card>
    //                             <Row style={textCenter}><strong>Condition 1</strong></Row>
    //                             <Row justify='center'>
    //                                 <Col lg={12} sm={24}>
    //                                     <strong style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'right'
    //                                     }}>Collection Aperture</strong>
    //                                 </Col>
    //                                 <Col lg={12} sm={24}>
    //                                     <Typography style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'center'
    //                                     }}>{results.allConditions.condition1.aperture} mm</Typography>
    //                                 </Col>
    //                             </Row>
    //                             <Row justify='center'>
    //                                 <Col lg={12} sm={24}>
    //                                     <strong style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'right'
    //                                     }}>Distance</strong>
    //                                 </Col>
    //                                 <Col lg={12} sm={24}>
    //                                     <Typography style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'center'
    //                                     }}>{results.allConditions.condition1.distance} mm</Typography>
    //                                 </Col>
    //                             </Row>
    //                         </Card>
    //                     </Col>
    //                     <Col lg={12} sm={24}>
    //                         <Card>
    //                             <Row style={textCenter}><strong>Condition 3</strong></Row>
    //                             <Row justify='center'>
    //                                 <Col lg={12} sm={24}>
    //                                     <strong style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'right'
    //                                     }}>Collection Aperture</strong>
    //                                 </Col>
    //                                 <Col lg={12} sm={24}>
    //                                     <Typography style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'center'
    //                                     }}>{results.allConditions.condition3.aperture} mm</Typography>
    //                                 </Col>
    //                             </Row>
    //                             <Row justify='center'>
    //                                 <Col lg={12} sm={24}>
    //                                     <strong style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'right'
    //                                     }}>Distance</strong>
    //                                 </Col>
    //                                 <Col lg={12} sm={24}>
    //                                     <Typography style={{
    //                                         display: 'flex',
    //                                         justifyContent: 'center'
    //                                     }}>{results.allConditions.condition3.distance} mm</Typography>
    //                                 </Col>
    //                             </Row>
    //                         </Card>
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </>,
    // };

    const [activeTabKey2, setActiveTabKey2] = useState('1');
    const [downLoadPDF, setdownLoadPDF] = useState(null);
    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };
    useEffect(() => {
        const generatePDF = () => {
            const date = new Date().toLocaleString().split(',')[0]
            return <LookUpTablePDF results={results} date={date} />
       }
        setdownLoadPDF(generatePDF())
       
    }, [results])
   
    return (
        <Row justify='center' style={{
            margin: '24px 0 0'
        }}>
            <Card style={{ width: "100vw" }}
                // tabList={tabList}
                activeTabKey={activeTabKey2}
                onTabChange={onTab2Change}
                title="Lookup Results"
                extra={[
               
                    <Tooltip destroyTooltipOnHide placement='left' showArrow mouseEnterDelay={0.5} title="Click me to hide inputs." zIndex={1}>
                        <Button icon={isFullScreen  ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={toggleFullscreen}/> 
                    </Tooltip>
                ]}>
                {/* {contentList[activeTabKey2]} */}
                <>
                {/* MPE & AEL */}
             
                    {/* <Row justify='center'><strong>AEL & MPE</strong></Row> */}
                    <Divider>Accessible Emission Limit</Divider>
                    <Row justify='center'>

                        <ResultCard>
                            <strong style={textCenter}>Class 1 AEL</strong>
                            {results.ael.class1.joules !== null ? <>
                                <Typography style={textCenter}>{results.ael.class1.joulesPretty !== "nullJ" ? results.ael.class1.joulesPretty : results.ael.class1.joulesPerSqmPretty}</Typography>
                                <Typography style={textCenter}>{results.ael.class1.wattsPretty !== "nullW" ? results.ael.class1.wattsPretty : results.ael.class1.wattsPerSqmPretty}</Typography>
                            </> : <>
                                <Typography style={textCenter}>N/A</Typography>
                                <Typography style={textCenter}>N/A</Typography>
                            </>}
                        </ResultCard>

                        <ResultCard>
                            <strong style={textCenter}>Class 2 AEL</strong>
                            {results.ael.class2.joules !== null ? <>
                                <Typography style={textCenter}>{results.ael.class2.joulesPretty}</Typography>
                                <Typography style={textCenter}>{results.ael.class2.wattsPretty}</Typography>
                            </> : <>
                                <Typography style={textCenter}>N/A</Typography>
                                <Typography style={textCenter}>N/A</Typography>
                            </>}
                        </ResultCard>

                        <ResultCard>
                            <strong style={textCenter}>Class 3R AEL</strong>
                            {results.ael.class3r.joules !== null ? <>
                                <Typography style={textCenter}>{results.ael.class3r.joulesPretty !== "nullJ" ? results.ael.class3r.joulesPretty : results.ael.class3r.joulesPerSqmPretty}</Typography>
                                <Typography style={textCenter}>{results.ael.class3r.wattsPretty !== "nullW" ? results.ael.class3r.wattsPretty : results.ael.class3r.wattsPerSqmPretty}</Typography>
                            </> : <>
                                <Typography style={textCenter}>N/A</Typography>
                                <Typography style={textCenter}>N/A</Typography>
                            </>}
                        </ResultCard>

                        <ResultCard>
                            <strong style={textCenter}>Class 3B AEL</strong>
                            {results.ael.class3b.joules !== null ? <>
                                <Typography style={textCenter}>{results.ael.class3b.joulesPretty}</Typography>
                                <Typography style={textCenter}>{results.ael.class3b.wattsPretty}</Typography>
                            </> : <>
                                <Typography style={textCenter}>N/A</Typography>
                                <Typography style={textCenter}>N/A</Typography>
                            </>}
                        </ResultCard>
                    </Row>
              
                    <Row justify={"space-evenly"} >
                        <Col>
                        <Divider style={{width:"350px"}}>Maximum Permissible Emission</Divider>
                        <Row justify={"space-around"}>
                            <Card>
                                <strong style={textCenter}>Eye MPE</strong>
                                <Typography style={textCenter}>{results.mpe.eye.joulesPerSqmPretty.eng}J/m²</Typography>
                                <Typography style={textCenter}>{results.mpe.eye.wattsPerSqmPretty.eng}W/m²</Typography>
                            </Card>

                            <Card>
                                <strong style={textCenter}>Skin MPE</strong>
                                <Typography style={textCenter}>{results.mpe.skin.joulesPerSqmPretty.eng}J/m²</Typography>
                                <Typography style={textCenter}>{results.mpe.skin.wattsPerSqmPretty.eng}W/m²</Typography>
                            </Card>
                        </Row>  
                        </Col>

                        <Col>
                        <Divider style={{width:"300px"}}>Limiting Aperture</Divider>
                            <Card>
                                <Tooltip title={"The circular area over which irradiance and radiant exposure is averaged."}>
                                    <Row justify={"space-around"}>
                                    <Col>
                               <strong style={textCenter}>
                                   Eye
                                </strong>
                                    <Typography style={textCenter}>
                                        {results.limitingAperture.eye} mm
                                    </Typography>
                                    </Col>
                                    <Col>
                                    <strong style={textCenter}>
                                  Skin
                                </strong>
                                    <Typography style={textCenter}>
                                        {results.limitingAperture.skin} mm
                                    </Typography>
                                    </Col>
                                    </Row>
                                </Tooltip>
                            </Card>
                            {results.calcType === 'extended' ? (
                                <>
                                <Divider>Angular Subtense</Divider>
                                <Card>
                                    <strong style={textCenter}>Angular Subtense</strong>
                                    <Tooltip title='The visual angle subtended by the apparent source size of the laser.'>
                                        <Typography style={textCenter}>{results.angularSubtense.eng}rad</Typography>
                                    </Tooltip>
                                </Card>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                    
                {/* Correction Factors Card */}
               
                    <Divider>Correction Factors</Divider>
                    <Row justify='center'> 
                        {results.corectionFactors.map(cf => {
                            if (cf.name === "T1" || cf.name === "T2") {
                            return null; // Skip rendering for filtered items
                            }
                            return (
                            <ResultCard key={cf.name}>
                                <strong style={textCenter}>{cf.name}</strong>
                                <Typography style={textCenter}>{cf.pretty}</Typography>
                            </ResultCard>
                            )
                         })}
                    </Row>
                    <Row justify={"space-evenly"}>
                        <Col>
                            <Row justify={"space-evenly"}>
                                <Divider>Time Bases</Divider>
                                {results.corectionFactors.map(cf => {
                                    if (cf.name === "T1" || cf.name === "T2") {
                                    return(
                                        <ResultCard key={cf.name}>
                                            <strong style={textCenter}>{cf.name}</strong>
                                            <Typography style={textCenter}>{cf.pretty}</Typography>
                                        </ResultCard> )
                                    }
                                })}
                            </Row>
                        </Col>  
                        <Col >
                            <Divider style={{width:"300px"}}>Spectrum</Divider>
                            <Card >
                                <Row justify={"center"}>
                                    <div style={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: (() => {
                                            if (results.spectrum.range == "Infrared") {
                                                return "maroon"
                                            }
                                            else if (results.spectrum.range == "Ultraviolet") {
                                                return "purple"
                                            }
                                            return "rgb(" + results.spectrum.rgb + ")"
                                            })(),
                                        borderRadius: 100,
                                        ustifyContent: "center"
                                        }}>
                                    </div>
                                </Row>
                                <Row justify={"center"}>{results.spectrum.region}</Row>
                            </Card>
                        </Col>    
                    </Row>
                   <Divider>Measurement Aperture Diameters and Distances</Divider>

                    <Row justify='center' style={{ margin: '24px 0 0' }} gutter={[10, 10]} >
                        <Col lg={12} sm={24}>
                            <Card>
                                <Row style={textCenter}><strong>Condition 1</strong></Row>
                                <Row justify='center'>
                                    <Col lg={12} sm={24}>
                                        <strong style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}>Aperture Stop</strong>
                                    </Col>
                                    <Col lg={12} sm={24}>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>{results.conditions.condition1.aperture}</Typography>
                                    </Col>
                                </Row>
                                <Row justify='center'>
                                    <Col lg={12} sm={24}>
                                        <strong style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}>Distance</strong>
                                    </Col>
                                    <Col lg={12} sm={24}>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>{results.conditions.condition1.distance}</Typography>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={12} sm={24}>
                            <Card>
                                <Row style={textCenter}><strong>Condition 3</strong></Row>
                                <Row justify='center'>
                                    <Col lg={12} sm={24}>
                                        <strong style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}>Aperture Stop</strong>
                                    </Col>
                                    <Col lg={12} sm={24}>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>{results.conditions.condition3.aperture}</Typography>
                                    </Col>
                                </Row>
                                <Row justify='center'>
                                    <Col lg={12} sm={24}>
                                        <strong style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}>Distance</strong>
                                    </Col>
                                    <Col lg={12} sm={24}>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>{results.conditions.condition3.distance}</Typography>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </> 
                <Row justify={"end"} style={{ margin: "25px 5px 5px 0" }}>
                    <PDFDownloadLink document={downLoadPDF} fileName="LookUpTable-Results">
                        <Button icon={<DownloadOutlined />} type="primary" >Download Results</Button>
                    </PDFDownloadLink>
                        {/* <PDFViewer width="1000" height="600" document={downLoadPDF}>
                        <Button icon={<DownloadOutlined />} type="primary" >Download Results</Button>
                        </PDFViewer> */}
                </Row>
            </Card>
        </Row>
    )

}