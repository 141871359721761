import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography

const ExcessHandler = (props) => {
    const { hec, condition, laserClass } = props

    const val = hec?.[`condition${condition}`]?.class?.[`class${laserClass}`]?.excess?.val || 0
    const pretty = hec?.[`condition${condition}`]?.class?.[`class${laserClass}`]?.excess?.pretty || '-'

    return (
        <Text
            strong
            type={(() => {
                if (val !== null && val !== undefined) {
                    if (val === 0) return null
                    if (val > 1) return 'danger'
                    if (val < 1) return 'success'
                } else {
                    return null
                }
            })()}>
            {pretty}
        </Text>
    )
}

export default ExcessHandler